<template>
  <div class="flex flex-col items-start gap-1 self-stretch">
    <UITextXsMedium>{{ t("common.style.formLayoutType") }}</UITextXsMedium>
    <div class="flex items-start gap-3 self-stretch">
      <div class="layout-container" @click="layoutChange(1)">
        <div class="layout-card" :class="{ 'layout-card-active': currentLayout === 1 }">
          <div class="layout-card-icon">
            <OneColumnIcon></OneColumnIcon>
          </div>
        </div>
        <UITextXsRegular>{{ t("common.payment.product.singleColumn") }}</UITextXsRegular>
      </div>
      <div class="layout-container" @click="layoutChange(2)">
        <div class="layout-card" :class="{ 'layout-card-active': currentLayout === 2 }">
          <div class="layout-card-icon">
            <TwoColumnIcon></TwoColumnIcon>
          </div>
        </div>
        <UITextXsRegular>{{ t("common.style.twoColumn") }}</UITextXsRegular>
      </div>
      <div class="layout-container" @click="layoutChange(3)">
        <div class="layout-card" :class="{ 'layout-card-active': currentLayout === 3 }">
          <div class="layout-card-icon">
            <SingleLineIcon></SingleLineIcon>
          </div>
        </div>
        <UITextXsRegular>{{ t("common.style.singleLine") }}</UITextXsRegular>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import OneColumnIcon from "@/assets/icons/one-column.svg";
import SingleLineIcon from "@/assets/icons/single-line.svg";
import TwoColumnIcon from "@/assets/icons/two-group.svg";
import { useAppStore } from "@/store/app";
import { fieldFullWidth } from "@/util/methods";
import { UITextXsMedium, UITextXsRegular } from "@gohighlevel/ghl-ui";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { CONSTANTS } from "../../../util/constants";
const { t } = useI18n();
const store = useAppStore();

const currentLayout = computed(() => {
  if (
    [CONSTANTS.ONE_COLUMN, CONSTANTS.TWO_COLUMN, CONSTANTS.SINGLE_LINE].includes(
      store.builderConfig.layout
    )
  ) {
    return store.builderConfig.layout;
  }
  return 1;
});

const layoutChange = (layoutIndex: number) => {
  store.builderConfig.layout = layoutIndex;
  const validFieldsForWidth = store.elements.filter(
    (field) => !fieldFullWidth(field.type, true)
  ).length;
  store.elements.forEach((field) => {
    if (!fieldFullWidth(field.type, true)) {
      if (layoutIndex === CONSTANTS.ONE_COLUMN) {
        field.fieldWidthPercentage = 100;
      } else if (layoutIndex === CONSTANTS.TWO_COLUMN) {
        field.fieldWidthPercentage = 50;
      } else {
        field.fieldWidthPercentage = Math.max(
          parseFloat((100 / validFieldsForWidth).toFixed(2)),
          20
        );
        if (field.type === "submit") {
          field.fullwidth = false;
          field.padding.left = 10;
          field.padding.right = 10;
        }
      }
    }
  });
  store.addToHistory();
  store.anyUnsavedChanges = true;
};
</script>

<style scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  cursor: pointer;
}

.layout-card {
  display: flex;
  height: 100px;
  width: 96px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #ffffff;
  border: 2px solid #eaecf0;
}
.layout-card-active {
  border: 2px solid #155eef;
  background: #eff4ff;
}
.layout-card-icon {
  padding: 0px 16px;
}
</style>
