// import axios from "axios";
// import config from "@/config";
import { googleFontsStaticCollection, bunnyFontsStaticCollection } from "@/util/fontsConstants";
// const googleAPIKey = config.GOOGLE_API_KEY;
export const getAllGoogleFonts = async () => {
  // const googleFonts = await axios.get(
  //   `https://www.googleapis.com/webfonts/v1/webfonts?key=${googleAPIKey}`
  // );
  // return googleFonts.data.items.map((font) => {
  //   return {
  //     label: font.family,
  //     value: font.family,
  //   };
  // });
  return googleFontsStaticCollection.map((fontName) => ({ value: fontName, label: fontName }));
};
export const getAllBunnyFonts = async () => {
  // const bunnyFonts = await axios.get("https://fonts.bunny.net/list");
  // const fonts =  Object.keys(bunnyFonts.data).map((fontKey) => {
  //   return {
  //     label: bunnyFonts.data[fontKey].familyName,
  //     value: bunnyFonts.data[fontKey].familyName,
  //   };
  // });
  return bunnyFontsStaticCollection.map((fontName) => ({ value: fontName, label: fontName }));
};
