import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'

export const ENTITY_TYPE = {
  FORM: 'Form' as const,
  SURVEY: 'Survey' as const,
  QUIZ: 'Quiz' as const,
}

export const USER_ROLES = {
  ADMIN: 'admin',
  USER: 'user',
  AGENCY: 'agency',
  ACCOUNT: 'account',
}

export const standardFields = [
  'full_name',
  'first_name',
  'last_name',
  'phone',
  'address',
  'city',
  'postal_code',
  'organization',
  'state',
  'email',
  'website',
  'source',
  'date_of_birth',
]

export const defaultSelectedColumns = [
  'id',
  'contactId',
  'full_name',
  'name',
  'email',
]

export const deletedFields = [
  'contact_id',
  'formId',
  'location_id',
  'funneEventData',
  'submissionId',
  'signatureHash',
]

export const standardFieldsWithTags = [
  {
    tag: 'name',
  },
  {
    tag: 'full_name',
  },
  {
    tag: 'first_name',
  },
  {
    tag: 'last_name',
  },
  {
    tag: 'phone',
  },
  {
    tag: 'email',
  },
  {
    tag: 'address',
  },
  { tag: 'contactAddress' },
  {
    tag: 'city',
  },
  {
    tag: 'postal_code',
  },
  {
    tag: 'state',
  },
  {
    tag: 'country',
  },
  {
    tag: 'website',
  },
  {
    tag: 'date_of_birth',
  },
  {
    tag: 'source',
  },
  {
    tag: 'facebookLeadId',
  },
  {
    tag: 'message',
  },
]

export const shortcuts = () => {
  const { t } = useI18n()

  return {
    [t('submission.dateShortcuts.thisWeek')]: [
      dayjs().startOf('week').valueOf(),
      dayjs().endOf('week').valueOf(),
    ],
    [t('submission.dateShortcuts.lastWeek')]: [
      dayjs().subtract(1, 'week').startOf('week').valueOf(),
      dayjs().subtract(1, 'week').endOf('week').valueOf(),
    ],
    [t('submission.dateShortcuts.last7Days')]: [
      dayjs().subtract(6, 'day').startOf('day').valueOf(),
      dayjs().endOf('day').valueOf(),
    ],
    [t('submission.dateShortcuts.last30Days')]: [
      dayjs().subtract(29, 'day').startOf('day').valueOf(),
      dayjs().endOf('day').valueOf(),
    ],
    [t('submission.dateShortcuts.thisMonth')]: [
      dayjs().startOf('month').valueOf(),
      dayjs().endOf('month').valueOf(),
    ],
    [t('submission.dateShortcuts.lastMonth')]: [
      dayjs().subtract(1, 'month').startOf('month').valueOf(),
      dayjs().subtract(1, 'month').endOf('month').valueOf(),
    ],
    [t('submission.dateShortcuts.thisYear')]: [
      dayjs().startOf('year').valueOf(),
      dayjs().endOf('year').valueOf(),
    ],
    [t('submission.dateShortcuts.lastYear')]: [
      dayjs().subtract(1, 'year').startOf('year').valueOf(),
      dayjs().subtract(1, 'year').endOf('year').valueOf(),
    ],
  }
}

export const languageMapper = {
  en_US: 'en_US',
  fr_CA: 'fr_CA',
  fr_FR: 'fr',
  de: 'de_DE',
  it: 'it',
  pt_BR: 'pt_BR',
  pt_PT: 'pt',
  es: 'es',
}

export const dateLocaleMapper = (locale: string) => {
  let dateLocale = 'en'
  switch (locale) {
    case 'en-US': {
      dateLocale = 'en'
      break
    }
    case 'fr-FR': {
      dateLocale = 'fr'
      break
    }
    case 'pt-PT':
    case 'pt-BR': {
      dateLocale = 'pt'
      break
    }
    default: {
      dateLocale = locale.toLowerCase()
    }
  }
  return dateLocale
}

export const PRODUCT_TYPE = {
  FORM: 'form',
  QUIZ: 'quiz',
  SURVEY: 'survey',
}

export const getSubTexts = () => {
  const { t } = useI18n()

  return {
    Form: {
      [SUB_TEXT_KEYS.ALL]: t('submission.formSubmission.allForm'),
      [SUB_TEXT_KEYS.PLACEHOLDER_SELECT]: t(
        'submission.sideBar.placeholderSelectForm'
      ),
      [SUB_TEXT_KEYS.NO_TEXT]: t('submission.formSubmission.noFormsText'),
      [SUB_TEXT_KEYS.CREATE_TEXT]: t(
        'submission.formSubmission.createFormText'
      ),
      [SUB_TEXT_KEYS.DEL_MODAL_TITLE]: t(
        'submission.formSubmission.deleteFormSubmissionsConfirmModal.title'
      ),
      [SUB_TEXT_KEYS.DEL_MODAL_DESC]: t(
        'submission.formSubmission.deleteFormSubmissionsConfirmModal.description'
      ),
      [SUB_TEXT_KEYS.EXP_MODAL_TITLE]: t(
        'submission.formSubmission.exportFormSubmissionsConfirmModal.title'
      ),
      [SUB_TEXT_KEYS.EXP_MODAL_DESC]: t(
        'submission.formSubmission.exportFormSubmissionsConfirmModal.description'
      ),
      [SUB_TEXT_KEYS.SUBMISSION_DESC]: t(
        'submission.formSubmission.description'
      ),
    },
    Survey: {
      [SUB_TEXT_KEYS.ALL]: t('submission.surveySubmission.allSurvey'),
      [SUB_TEXT_KEYS.PLACEHOLDER_SELECT]: t(
        'submission.sideBar.placeholderSelectSurvey'
      ),
      [SUB_TEXT_KEYS.NO_TEXT]: t('submission.surveySubmission.noSurveysText'),
      [SUB_TEXT_KEYS.CREATE_TEXT]: t(
        'submission.surveySubmission.createSurveyText'
      ),
      [SUB_TEXT_KEYS.DEL_MODAL_TITLE]: t(
        'submission.surveySubmission.deleteSurveySubmissionsConfirmModal.title'
      ),
      [SUB_TEXT_KEYS.DEL_MODAL_DESC]: t(
        'submission.surveySubmission.deleteSurveySubmissionsConfirmModal.description'
      ),
      [SUB_TEXT_KEYS.EXP_MODAL_TITLE]: t(
        'submission.surveySubmission.exportSurveySubmissionsConfirmModal.title'
      ),
      [SUB_TEXT_KEYS.EXP_MODAL_DESC]: t(
        'submission.surveySubmission.exportSurveySubmissionsConfirmModal.description'
      ),
      [SUB_TEXT_KEYS.SUBMISSION_DESC]: t(
        'submission.surveySubmission.description'
      ),
    },
    Quiz: {
      [SUB_TEXT_KEYS.ALL]: t('submission.quizSubmission.allQuiz'),
      [SUB_TEXT_KEYS.PLACEHOLDER_SELECT]: t(
        'submission.sideBar.placeholderSelectQuiz'
      ),
      [SUB_TEXT_KEYS.NO_TEXT]: t('submission.quizSubmission.noQuizsText'),
      [SUB_TEXT_KEYS.CREATE_TEXT]: t(
        'submission.quizSubmission.createSurveyText'
      ),
      [SUB_TEXT_KEYS.DEL_MODAL_TITLE]: t(
        'submission.quizSubmission.deleteQuizSubmissionsConfirmModal.title'
      ),
      [SUB_TEXT_KEYS.DEL_MODAL_DESC]: t(
        'submission.quizSubmission.deleteQuizSubmissionsConfirmModal.description'
      ),
      [SUB_TEXT_KEYS.EXP_MODAL_TITLE]: t(
        'submission.quizSubmission.exportQuizSubmissionsConfirmModal.title'
      ),
      [SUB_TEXT_KEYS.EXP_MODAL_DESC]: t(
        'submission.quizSubmission.exportQuizSubmissionsConfirmModal.description'
      ),
      [SUB_TEXT_KEYS.SUBMISSION_DESC]: t(
        'submission.quizSubmission.description'
      ),
    },
  }
}

export const SUB_TEXT_KEYS = {
  ALL: 'ALL' as const,
  PLACEHOLDER_SELECT: 'SUB_PLACEHOLDER_SELECT' as const,
  NO_TEXT: 'NO_TEXT' as const,
  CREATE_TEXT: 'CREATE_TEXT' as const,
  DEL_MODAL_TITLE: 'DELETE_FORM_TITLE' as const,
  DEL_MODAL_DESC: 'DELETE_FORM_DESCRIPTION' as const,
  EXP_MODAL_TITLE: 'EXPORT_FORM_TITLE' as const,
  EXP_MODAL_DESC: 'EXPORT_FORM_DESCRIPTION' as const,
  SUBMISSION_DESC: 'SUBMISSION_DESC' as const,
}

export const ROUTES = {
  FORM_LIST_PAGE: 'form-builder-main-v2',
  QUIZ_LIST_PAGE: 'quiz-builder-main',
  FORM_SUBMISSION: 'form-builder-submissions-v2',
  QUIZ_SUBMISSION: 'quiz-builder-submissions',
}
