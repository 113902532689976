<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { showFieldLabel } from "@/util/methods";

const props = defineProps({
  element: {
    type: Object,
    default: () => ({}),
  },
  formLabelVisible: Boolean,
});

const store = useAppStore();

const getInputCSSList = (): Array<String> => {
  return store.builderConfig.inputStyleType === "line" ? ["line-form-style"] : [];
};
</script>

<template>
  <div v-if="showFieldLabel(formLabelVisible, element)" style="display: inline-flex">
    <label v-if="showFieldLabel(formLabelVisible, element)">
      {{ element.label }} <span v-if="element.required">*</span>
    </label>
    <div v-if="element.hidden">
      <small style="background: #e1e1e1; padding: 2px 5px; border-radius: 2px; margin-left: 5px">
        Hidden
      </small>
    </div>
  </div>

  <input
    :placeholder="element.placeholder"
    class="form-control"
    :class="getInputCSSList()"
    :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
    :readonly="true"
  />
  <span v-if="element.shortLabel" class="short-label">{{ element.shortLabel }}</span>
</template>
