<template>
  <section class="flex items-center gap-6 self-stretch" :class="alignment">
    <input
      placeholder="Enter coupon code"
      :style="{
        width: store.payment.coupon?.couponAlignment === 'fullWidth' ? '100%' : '50%',
      }"
      :class="store.builderConfig.inputStyleType === 'line' ? 'line-form-style' : ''"
      class="form-control w-[50%]"
      :readonly="true"
    />
    <button
      id="apply-coupon"
      class="preview-common-btn pointer-events-none"
      :style="{
        fontSize: store.fieldStyle.placeholderFontSize + 'px',
      }"
    >
      Apply
    </button>
  </section>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { computed } from "vue";

const store = useAppStore();
const alignment = computed(() => {
  if (store.payment.coupon?.couponAlignment === "left") {
    return "justify-start";
  }
  if (store.payment.coupon?.couponAlignment === "right") {
    return "justify-end";
  }
  if (store.payment.coupon?.couponAlignment === "fullWidth") {
    return "w-100";
  }
  return "justify-end";
});
</script>

<style scoped>
.preview-common-btn {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #d0d5dd !important;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 8px 20px;
  color: #344055;
  text-wrap: nowrap;
  white-space: nowrap;
  line-height: 1.5 !important;
}
</style>
