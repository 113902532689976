<template>
  <div class="grid grid-cols-2 py-2">
    <div class="my-auto text-sm font-normal text-gray-700">{{ label }}</div>
    <div class="color-picker">
      <UIColorPicker
        :value="defaultValue"
        :compact="true"
        @update:value="handleColorPickerInput"
        @on-complete="store.addToHistory"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UIColorPicker } from "@gohighlevel/ghl-ui";
import { ref, watch } from "vue";
const store = useAppStore();

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "FFFFFF",
  },
});
const emit = defineEmits(["update:color"]);
const defaultValue = ref("#" + props.color);

watch(
  () => props.color,
  (newColor) => (defaultValue.value = `#${newColor}`)
);

const handleColorPickerInput = (value) => {
  const color = value.replace("#", "");
  const isValidColor: boolean = /(^[0-9A-F]{8}$)|(^[0-9A-F]{6}$)|(^[0-9A-F]{3}$)/i.test(color);
  if (!isValidColor) {
    return;
  }
  defaultValue.value = value;
  emit("update:color", color);
};
</script>

<style scoped>
.color-picker {
  margin-left: 12px;
  display: flex;
}
</style>
