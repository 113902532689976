<template>
  <UICard
    :class="{
      'selected-version': active,
    }"
    class="cursor-pointer"
  >
    <div class="flex items-center pb-1">
      {{ t("common.version") }}
      <UITextSmNormal></UITextSmNormal>
      <UITextSmMedium class="ml-2">#{{ count - index }}</UITextSmMedium>
    </div>
    <div class="pb-1">
      <div class="m-0">
        <UITextXsMedium>{{ formatDateToMinutes(item.updatedAt) }}</UITextXsMedium>
      </div>
    </div>
    <div class="pb-1">
      <div class="m-0">
        <UITextXsRegular>{{ item.updatedByUser }}</UITextXsRegular>
      </div>
    </div>
    <UITag v-if="index === 0" class="z-hide" :type="'info'" :size="'small'">
      {{ t("common.currentVersion") }}</UITag
    >
  </UICard>
</template>

<script setup lang="ts">
import {
  UICard,
  UITag,
  UITextSmMedium,
  UITextSmNormal,
  UITextXsMedium,
  UITextXsRegular,
} from "@gohighlevel/ghl-ui";
import dayjs from "dayjs";
import { useI18n } from "vue-i18n";

const props = defineProps({
  item: { type: Object },
  index: { type: Number },
  count: { type: Number },
  active: { type: Boolean },
});

const formatDateToMinutes = (date: any) => dayjs(date).format("ddd MMM DD YYYY hh:mm A");
const { t } = useI18n();
</script>

<style scoped>
.version-detail-card:hover {
  background-color: rgba(191, 219, 254, 1);
}

.selected-version {
  background-color: #ddeefe;
}

.z-hide {
  z-index: 0 !important;
}
</style>
