<template>
  <section class="message-styling flex flex-col items-start justify-center gap-4 self-stretch p-3">
    <Switch v-model:value="messageSwitch" :label="t('common.messageStyling')" />
    <section v-if="messageSwitch" class="form_settings">
      <div class="grid grid-cols-2 gap-3">
        <ColorInput
          :label="t('common.background')"
          :compact="true"
          :color="formSubmitMessageStyle.bgColor"
          @update:color="handleThankYouMessageBackground"
        />
        <ColorInput
          :label="t('common.fontColor')"
          :compact="true"
          :color="formSubmitMessageStyle.color"
          @update:color="handleThankYouMessageColor"
        />
      </div>
      <FontFamilyInput
        :label="t('common.fontFamily')"
        :value="formSubmitMessageStyle.fontFamily"
        @update:value="handleThankYouFontFamily"
      />
      <div class="grid grid-cols-2 gap-3">
        <PixelNumber
          :label="t('common.size')"
          :value="formSubmitMessageStyle.fontSize"
          @update:value="handleLabelFontSize"
        />
        <PixelNumber
          :label="t('common.weight')"
          :value="formSubmitMessageStyle.fontWeight"
          :max="800"
          @update:value="handleLabelFontWeight"
        />
      </div>
    </section>
  </section>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import ColorInput from "../Elements/ColorInput.vue";
import FontFamilyInput from "../Elements/FontFamilyInput.vue";
import PixelNumber from "../Elements/PixelNumber.vue";
import Switch from "../Elements/Switch.vue";
const { t } = useI18n();
const store = useAppStore();

const messageSwitch = ref(false);

const formStoreSubmitMessageStyle = store.getFormSubmitMessageStyle;
const formSubmitMessageStyle = reactive(formStoreSubmitMessageStyle);

const handleThankYouMessageBackground = (val: string) => {
  store.submitMessageStyle.bgColor = val;
  store.anyUnsavedChanges = true;
};
const handleThankYouMessageColor = (val: string) => {
  store.submitMessageStyle.color = val;
  store.anyUnsavedChanges = true;
};
const handleThankYouFontFamily = (val: string) => {
  store.fontCollection.push(val);
  store.submitMessageStyle.fontFamily = val;
  store.anyUnsavedChanges = true;
};
const handleLabelFontSize = (val: string) => {
  store.submitMessageStyle.fontSize = parseFloat(val);
  store.anyUnsavedChanges = true;
};
const handleLabelFontWeight = (val: string) => {
  store.submitMessageStyle.fontWeight = parseFloat(val);
  store.anyUnsavedChanges = true;
};
</script>

<style>
.message-styling {
  border-radius: 12px;
  border: 1px solid #eaecf0;
  background: #ffffff;
}
</style>
