import {
  browserProfilingIntegration,
  browserTracingIntegration,
  captureMessage,
  init,
  metrics,
  moduleMetadataIntegration,
  setTags,
  setUser,
} from "@sentry/vue";

export const setupSentry = (Vue: any, router: any, environment: string) => {
  if (environment !== "production") {
    return;
  }
  init({
    Vue,
    dsn: "https://a4b820e23577a8736466697ec2a98798@o176457.ingest.us.sentry.io/4507654889472000",
    environment,
    integrations: [
      metrics.metricsAggregatorIntegration(),
      browserTracingIntegration({
        router,
        enableInp: true,
        shouldCreateSpanForRequest: (url: string) => {
          return url.includes("leadconnectorhq.com") || url.includes("msgsndr.com");
        },
      }),
      browserProfilingIntegration(),
      moduleMetadataIntegration(),
    ],
    allowUrls: [/https?:\/\/((static|appcdn)\.)?leadconnectorhq\.com/],
    sampleRate: 1, // samples are picked randomly
    tracesSampleRate: 0.1, // picked randomly, starting with bare minimum
    logErrors: true,
    trackComponents: true,
    tracePropagationTargets: [/https?:\/\/(.*)?leadconnectorhq\.com/],
  });
};

export interface SentryEvent {
  locationId: string;
  locale: string;
  email?: string;
  userId?: string;
  companyId?: string;
}

export function setSentryMeta(event: SentryEvent) {
  try {
    setTags({
      locationId: event.locationId,
      locale: event.locale,
      companyId: event.companyId,
    });

    if (event?.email && event?.userId) {
      setUser({ id: event.userId, email: event.email });
    } else {
      setUser({});
    }
  } catch (err) {
    captureMessage(`Issue while setting sentry meta '${err}', please change to use hyphen '-'.`);
  }
}
