<template>
  <div class="flex flex-col">
    <div :style="styleObject">
      <div
        v-for="(pickerOption, index) in picklistOptions"
        :key="index"
        :style="minimumWidth"
        class="option"
      >
        <div class="align-together">
          <input
            v-if="type === 'input'"
            type="checkbox"
            :readonly="readonly"
            :value="pickerOption"
          />
          <label>{{ pickerOption }}</label>
        </div>
      </div>
    </div>
    <span v-if="field.shortLabel" class="short-label">{{ field.shortLabel }}</span>
  </div>
</template>

<script setup lang="ts">
import { CONSTANTS } from "@/util/constants";
import { computed } from "vue";

const props = defineProps({
  type: {
    type: String,
    default: "input",
  },
  placeholder: { type: String, default: "" },
  picklistOptions: {
    type: Array,
    default() {
      return [];
    },
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  layout: {
    type: Number,
    default: 1,
  },
  field: {
    type: Object,
    default() {
      return {};
    },
  },
  styleObject: {
    type: Object,
    default() {
      return {};
    },
  },
});

const getWidth = () => {
  let width = 100;
  if (props.layout === CONSTANTS.TWO_COLUMN) {
    width = Math.max(width / props.field.columnsNumber, 50);
  } else {
    width = Math.max(width / props.field.columnsNumber, 20);
  }
  if (props.field.spreadColumns) {
    return width + "%";
  }
  return "100%";
};
const minimumWidth = computed(() => {
  const width = getWidth();
  return {
    width: width,
  };
});
</script>

<style scoped>
.option {
  position: relative;
  display: inline-block;
  widows: 100%;
}
.option label {
  line-height: 30px;
  display: block;
  cursor: pointer;
  margin-left: 10px;
}

.align-together {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}
</style>
