<template>
  <UIModal :show="show" @update:show="cancelCallback">
    <template #header>
      <UIModalHeader id="link-header" type="primary" title="Insert Link" @close="cancelCallback">
      </UIModalHeader>
    </template>
    <UIModalContent>
      <UIForm id="tnc-link-url" ref="root" :model="inputValue" :rules="rules">
        <UIFormItem label="URL" path="url">
          <UIInput id="url" v-model="inputValue.url" placeholder="https://www.example.com" />
        </UIFormItem>
      </UIForm>
    </UIModalContent>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        positive-text="Confirm"
        negative-text="Cancel"
        type="primary"
        @positive-click="submitCallback"
        @negative-click="cancelCallback"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  UIModal,
  UIModalHeader,
  UIModalFooter,
  UIModalContent,
  UIForm,
  UIFormItem,
  UIInput,
} from "@gohighlevel/ghl-ui";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  url: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:cancel", "update:success"]);

const inputValue = ref({
  url: "",
});

watch(
  () => props.show,
  (val) => {
    if (val) {
      inputValue.value.url = props.url;
    }
  }
);
const rules = {
  url: [
    {
      required: true,
      validator(rule: any, value: string) {
        const urlRegex =
          /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$|{{(.*?)}}/;
        if (!urlRegex.test(value)) {
          return new Error("URL is invalid");
        }
        return true;
      },
      trigger: ["blur"],
    },
  ],
};
const root = ref();

const cancelCallback = () => emit("update:cancel", false);

const submitCallback = async () => {
  try {
    await root.value.getForm().validate();
    const editedLink = await inputValue.value.url;
    emit("update:success", editedLink);
  } catch (error) {
    console.error("error in link modal: ", error);
  }
};
</script>
