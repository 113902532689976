<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { PlusIcon, DotsGridIcon, ChevronDownIcon } from "@gohighlevel/ghl-icons/24/outline";
import {
  UIButton,
  UICollapse,
  UICollapseItem,
  UIInput,
  UIInputGroup,
  UISelect,
} from "@gohighlevel/ghl-ui";
import { truncate } from "lodash";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import { useI18n } from "vue-i18n";

const store = useAppStore();

const { t } = useI18n();
const { getCustomFieldsFromStore, getCustomFieldFoldersFromStore } = storeToRefs(store);
const searchTerm = ref("");

const customFieldsOptions = [
  { label: t("common.contact"), value: "contact" },
  { label: t("common.opportunity"), value: "opportunity" },
];
const customFieldsType = ref("contact");

const filterByType = (fields) =>
  fields.filter((customField) => customField.model === customFieldsType.value);
const customFieldsCollection = ref(filterByType(getCustomFieldsFromStore.value));
const customFoldersCollection = ref(filterByType(getCustomFieldFoldersFromStore.value));
const groupedCustomFields = ref([]);

const openCustomFieldModal = () => {
  store.openCustomFieldsModel();
};

const updateCustomFieldsOptions = () => {
  customFieldsCollection.value = filterByType(getCustomFieldsFromStore.value);
  customFoldersCollection.value = filterByType(getCustomFieldFoldersFromStore.value);

  groupedCustomFields.value = customFoldersCollection.value
    .map((folder) => ({
      name: folder.name,
      fields: customFieldsCollection.value.filter((field) => field.parentId === folder._id),
      showCount: 10,
    }))
    .map((group) => ({
      ...group,
      fields: group.fields.filter((field) =>
        field.name.toLowerCase().includes(searchTerm.value.toLowerCase())
      ),
    }))
    .filter((group) => group.fields.length > 0);
};
const showMore = (group) => {
  const index = groupedCustomFields.value.indexOf(group);
  if (index !== -1) {
    groupedCustomFields.value[index].showCount += 10;
  }
};
updateCustomFieldsOptions();

watch(
  () => store.customFields,
  () => {
    updateCustomFieldsOptions();
  }
);
</script>

<template>
  <div class="custom-list-container px-2 pb-3">
    <div id="add-custom-fields-btn">
      <div class="mb-1 flex items-center gap-x-4 p-1">
        <UISelect
          id="custom-field-type"
          v-model:value="customFieldsType"
          :options="customFieldsOptions"
          @update:value="updateCustomFieldsOptions"
        />
        <UIButton id="add-custom-field" type="tertiary" @click="openCustomFieldModal">
          <PlusIcon class="mr-2 h-4 w-4" /> {{ t("common.add") }}
        </UIButton>
      </div>
      <UIInputGroup class="p-1">
        <UIInput
          id="search-custom-field"
          v-model="searchTerm"
          type="text"
          :placeholder="t('common.customFieldSearch')"
          :clearable="true"
          @update:model-value="updateCustomFieldsOptions"
        />
      </UIInputGroup>
    </div>
    <div class="mt-28">
      <UICollapse :accordion="true" :arrow-placement="'left'">
        <UICollapseItem
          v-for="group in groupedCustomFields"
          :id="group.name"
          :key="group.name"
          :title="`${group.name} (${group.fields.length})`"
          :name="group.name"
        >
          <VueDraggableNext
            :list="group.fields.slice(0, group.showCount)"
            :sort="false"
            ghost-class="sortable-ghost"
            :group="{ name: 'elements', pull: 'clone', put: false }"
          >
            <div
              v-for="(item, index) in group.fields.slice(0, group.showCount)"
              :key="index"
              class="p-1"
            >
              <div class="custom-item">
                <span>{{ truncate(item.name, { length: 30 }) }}</span>
                <div class="icon-container">
                  <DotsGridIcon class="h-4 w-4" />
                </div>
              </div>
            </div>
          </VueDraggableNext>
          <div class="flex justify-center">
            <UIButton
              v-if="group.fields.length > group.showCount"
              id="show-more"
              text
              class="mt-2.5 text-sm"
              @click="showMore(group)"
            >
              {{ t("common.more") }} {{ group.fields.length - group.showCount }}
              <ChevronDownIcon class="ml-1 h-4 w-4" />
            </UIButton>
          </div>
        </UICollapseItem>
      </UICollapse>
    </div>
  </div>
</template>

<style scoped>
.custom-list-container {
  height: 81vh;
  overflow-y: auto;
}

.custom-item {
  background-color: white;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  cursor: all-scroll;
  border: 1px solid #d0d5dd;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  border-radius: 5px;
}

.custom-item:hover {
  box-shadow: 0 4px 8px -2px #1018281a, 0 2px 4px -2px #1018280f;
}

.icon-container {
  width: 15px;
  height: 15px;
  margin: auto 0;
  padding: 0;
}

#custom-fields {
  display: flex;
  justify-content: center;
}

#custom-preview {
  max-width: 300px;
  width: 100%;
  background-color: #e7f3fe;
  height: 80vh;
  right: 0;
  display: flex;
  align-items: center;
}

#custom-elments {
  background-color: #e7f3fe;
  height: 80vh;
}

#add-custom-fields-btn {
  position: absolute;
  top: 85px;
  width: 92%;
  z-index: 9;
  background-color: #fff;
}
</style>
<style>
.n-collapse-item__header-main {
  color: #667085 !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600 !important;
}
</style>
