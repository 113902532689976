<template>
  <div class="flex flex-col">
    <div v-if="picklistOptions !== undefined" :style="styleObject">
      <div
        v-for="(pickerOption, index) in picklistOptions"
        :key="index"
        :style="minimumWidthRadio"
        class="option-radio"
      >
        <div v-if="pickerOption != ''" class="align-together">
          <input
            v-if="type === 'input'"
            :id="field.id + '_' + index"
            type="radio"
            :name="field.id"
            :disabled="disabled"
            :value="pickerOption"
            @keyup="(e) => $emit('keyup', e)"
            @change="(e) => $emit('change', e)"
          />
          <label :for="field.id + '_' + index"> {{ pickerOption }} </label>
        </div>
      </div>
    </div>
    <div
      v-if="picklistOptionsImage !== undefined"
      :class="[picklistOptions === undefined && disabled ? 'row container-group-image' : '']"
      :style="styleObject"
    >
      <div
        v-for="(pickerOption, index) in picklistOptionsImage"
        :key="index"
        :style="minimumWidthRadioImage"
        :class="[
          picklistOptions === undefined && disabled
            ? 'option-radio col-md-3'
            : 'option-radio col-md-12',
        ]"
      >
        <div
          v-if="pickerOption != ''"
          :class="disabled ? 'radio-image-group radio-image-option-set' : 'radio-field-group'"
        >
          <input
            v-if="type === 'input'"
            :id="field.id + '_' + index"
            type="radio"
            :disabled="disabled"
          />

          <!-- For Image as radio button : If its disable mode (For Drag and Drop) -->
          <div v-if="disabled" class="opt-container-img-label">
            <label :for="field.id + '_' + index">
              <img class="radio-image-option-set-image" :src="pickerOption.src" />
            </label>
            <div class="img-opt-title">{{ pickerOption.label }}</div>
          </div>

          <!-- For Image as radio button : If its not disable mode (For contact section) -->
          <label v-else-if="pickerOption.src && !disabled" :for="field.id + '_' + index">
            {{ pickerOption.label }}
          </label>
        </div>
      </div>
    </div>
    <span v-if="field.shortLabel" class="short-label">{{ field.shortLabel }}</span>
  </div>
</template>

<script setup lang="ts">
import { CONSTANTS } from "@/util/constants";
import { computed } from "vue";
const props = defineProps({
  type: {
    type: String,
    default: "input",
  },
  name: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  currentLocationId: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  customClass: {
    type: String,
    default: "",
  },
  picklistOptions: Array,
  picklistOptionsImage: Array,
  disabled: {
    type: Boolean,
    default: false,
  },
  field: {} as any,
  layout: {
    type: Number,
    default: 1,
  },
  styleObject: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const getWidth = () => {
  let width = 100;
  if (props.layout === CONSTANTS.TWO_COLUMN) {
    width = Math.max(width / props.field.columnsNumber, 50);
  } else {
    width = Math.max(width / props.field.columnsNumber, 20);
  }
  if (props.field.spreadColumns) {
    return width + "%";
  }
  return "100%";
};

const minimumWidthRadio = computed(() => {
  const width = getWidth();
  return {
    position: "relative",
    display: "inline-block !important",
    width: width,
  };
});
const minimumWidthRadioImage = computed(() => {
  const width = getWidth();
  return {
    width: props.field.spreadColumns ? width : undefined,
  };
});
</script>
<style>
[type="radio"]:checked + img {
  outline: 1px solid #f00;
  box-shadow: 0px 1px 8px 2px #000;
}
.container-group-image .radio-image-option-set label:before {
  opacity: 0;
}
.container-group-image .radio-image-option-set label {
  padding: 2px 8px;
}
.container-group-image .radio-image-option-set {
  margin-bottom: 8px;
  border: 0.15rem solid #787f8221;
  padding: 0;
  border-radius: 6px;
}
.container-group-image .radio-image-option-set .opt-container-img-label {
  width: 100%;
}
.container-group-image .radio-image-option-set .opt-container-img-label label {
  min-width: 100px;
  min-height: 95px;
}
.container-group-image .radio-image-option-set label img {
  width: 100%;
  margin: 0 auto;
  display: block;
}
.container-group-image .img-opt-title {
  background: #444444;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  font-weight: 800;
  border-radius: 0 0 4px 4px;
}
.menu-field-wrap.col-sm-6 .option-radio.col-md-3 {
  max-width: 50%;
}

.tw-bottom-input,
.tw-bottom-input:focus {
  --tw-ring-color: none;
  --tw-ring-shadow: none;
  border: none;
  border-bottom: 1px solid rgba(209, 213, 219, var(--tw-border-opacity));
}

/*Radio Button*/
.option-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.option-radio label {
  line-height: 30px;
  display: block;
  padding-left: 25px;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.option-radio label:before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  border: 2px solid #e6edf2;
  background: #fff;
  position: absolute;
  top: 5px;
  left: 0;
}

.option-radio label:after {
  font-family: "Magicons";
  color: #fff;
  content: "\e96c";
  font-size: 0.625rem;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #00d26d;
  background-color: #00d26d;
  background-size: 10px 7px;
  position: absolute;
  top: 10px;
  left: 5px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  opacity: 0;
}

.option-radio input[type="radio"] {
  margin: 0;
  display: none;
  visibility: hidden;
}

.option-radio input[type="radio"]:checked + label {
  color: #2a3135;
}

.option-radio input[type="radio"]:checked + label:after {
  width: 15px;
  height: 15px;
  opacity: 1;
  top: 5px;
  left: 0;
  border-radius: 10px;
}

.option-radio input[type="radio"] + label:before,
.option-radio input[type="radio"] + label:after {
  border-radius: 50%;
}

.option-radio label {
  width: 100%;
}
.option-radio label input.add-custom-opt {
  background: #fff;
  border: none;
  width: 50%;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 3px;
}

.align-together {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}
</style>
