<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { PRODUCT_TYPE } from "@/util/constants";
import { Brush01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton, UICollapse, UICollapseItem } from "@gohighlevel/ghl-ui";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import Switch from "../Elements/Switch.vue";
import ColorsAndBackground from "../StyleSettings/ColorsAndBackground.vue";
import FooterStyles from "../StyleSettings/FooterStyles.vue";
import LayoutStyle from "../StyleSettings/LayoutStyle.vue";

const { t } = useI18n();

defineProps({
  product: {
    type: String,
    default: PRODUCT_TYPE.FORM,
  },
});

const emits = defineEmits(["open:advance"]);

const store = useAppStore();

const handleACbranding = (val: boolean) => {
  store.formStyle.acBranding = val;
  store.anyUnsavedChanges = true;
};

const advanceSettingEnable = ref(false);
const openAdvanceSettings = () => {
  emits("open:advance");
};
</script>
<template>
  <section id="style-container" class="styles-container">
    <section v-if="!advanceSettingEnable" class="basic-settings">
      <UICollapse :accordion="true" :arrow-placement="'left'" :default-expanded-names="['layout']">
        <UICollapseItem id="layout" name="layout">
          <template #header
            ><div>{{ t("common.style.layout") }}</div></template
          >
          <div class="collapse-content-container">
            <div class="collapse-content">
              <LayoutStyle :product="product"></LayoutStyle>
            </div>
          </div>
        </UICollapseItem>
        <UICollapseItem
          v-if="[PRODUCT_TYPE.SURVEY, PRODUCT_TYPE.QUIZ].includes(product)"
          id="footer"
          name="footer"
        >
          <template #header
            ><div>{{ t("survey.footer.footer") }}</div></template
          >
          <div class="collapse-content-container">
            <div class="collapse-content">
              <FooterStyles></FooterStyles>
            </div>
          </div>
        </UICollapseItem>
        <UICollapseItem id="colorsAndBackground" name="colorsAndBackground">
          <template #header
            ><div>{{ t("common.style.colorsAndBackground") }}</div></template
          >
          <div class="collapse-content-container">
            <div class="collapse-content">
              <ColorsAndBackground></ColorsAndBackground>
            </div>
          </div>
        </UICollapseItem>
        <UICollapseItem id="miscellaneous" name="miscellaneous">
          <template #header
            ><div>{{ t("common.miscellaneous") }}</div></template
          >
          <div class="collapse-content-container">
            <div class="collapse-content">
              <Switch
                :label="t('common.agencyBranding')"
                :value="store.formStyle.acBranding"
                @update:value="handleACbranding"
              />
            </div>
          </div>
        </UICollapseItem>
      </UICollapse>
      <div class="advance-settings">
        <UIButton id="advanceSettings" type="primary" @click="openAdvanceSettings">
          <div class="flex gap-2">
            <Brush01Icon class="h-4 w-4"></Brush01Icon>
            {{ t("common.style.advanceSettings") }}
          </div>
        </UIButton>
      </div>
    </section>
  </section>
</template>

<style>
.styles-container {
  height: 85vh;
  padding-bottom: 50px;
  overflow-y: auto;
}
#background-image-card .n-upload-dragger svg {
  height: 1.25rem;
  width: 1.25rem;
}
#header-image-card .n-upload-dragger svg {
  height: 1.25rem;
  width: 1.25rem;
}

#style-container .n-collapse-item__header {
  display: flex;
  align-items: start;
  padding: 16px;
  gap: 20px;
  border-bottom: 1px solid v #eaecf0;
  background: #f9fafb;
}
#style-container .n-collapse .n-collapse-item {
  margin-top: 0;
}

.collapse-content-container {
  display: flex;
  padding: 0px 16px 20px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-bottom: 1px solid #eaecf0;
}
.collapse-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.advance-settings {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  justify-items: center;
  align-self: stretch;
}
</style>
