<template>
  <ColorInput
    :label="t('common.style.placeholderColor')"
    :color="store?.fieldStyle?.placeholderColor"
    @update:color="handlePlaceholderColor"
  />
  <div class="flex items-start gap-3">
    <FontFamilyInput
      :label="t('common.fontFamily')"
      select-class="w-[122px]"
      :value="store?.fieldStyle?.placeholderFontFamily"
      @update:value="handlePlaceholderFontFamily"
    />
    <PixelNumber
      :label="t('common.style.fontSize')"
      :value="parseFloat(store?.fieldStyle?.placeholderFontSize)"
      @update:value="handlePlaceholderFontSize"
    />
    <PixelNumber
      :label="t('common.style.fontWeight')"
      :value="parseFloat(store?.fieldStyle?.placeholderFontWeight)"
      :max="1800"
      @update:value="handlePlaceholderFontWeight"
    />
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { useI18n } from "vue-i18n";
import ColorInput from "../Elements/ColorInput.vue";
import FontFamilyInput from "../Elements/FontFamilyInput.vue";
import PixelNumber from "../Elements/PixelNumber.vue";

const { t } = useI18n();
const store = useAppStore();

//placeholder
const handlePlaceholderColor = (val: string) => {
  store.fieldStyle.placeholderColor = val;
  store.anyUnsavedChanges = true;
};
const handlePlaceholderFontFamily = (val: string) => {
  if (!store.fontCollection.includes(val)) {
    store.fontCollection.push(val);
  }
  store.fieldStyle.placeholderFontFamily = val;
  store.anyUnsavedChanges = true;
};
const handlePlaceholderFontSize = (val: number) => {
  store.fieldStyle.placeholderFontSize = val;
  store.anyUnsavedChanges = true;
};
const handlePlaceholderFontWeight = (val: number) => {
  store.fieldStyle.placeholderFontWeight = val;
  store.anyUnsavedChanges = true;
};
</script>
