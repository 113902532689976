import config from "@/config";
import { requests } from ".";

const FORM_SERVER_URL = config?.FORM_SERVER_URL;
const LOCATION_SERVER_URL = config?.LOCATION_SERVER_URL;
interface GetForms {
  limit: number;
  skip: number;
  type: string;
  locationId: string;
  query: string;
  parentId?: string;
}
interface CreateForm {
  locationId: string;
  parentId?: string;
}

interface UpdateFolder {
  name: string;
  id: string;
}
interface DeleteForm {
  formId: string;
}

interface DuplicateForm {
  formId: string;
  name: string;
}
interface CreateFolder {
  name: string;
  locationId: string;
}

interface GetFolder {
  folderId: string;
}

interface ListFolders {
  locationId: string;
}
interface ShareForm {
  formId: string;
  locationId: string;
}

interface MoveToFolder {
  formId: string;
  folderId: string;
}

interface UpdateFormById {
  name: string;
  formData: {
    form: {
      company: {
        domain: string;
        logoUrl: string;
        name: string;
      };
      customStyle: string;
      fieldCSS: string;
      fbPixelId: string;
      fieldStyle: string;
      fields: Array<{}>;
      formAction: {
        actionType: string;
        redirect_url: string;
        thankyouText: string;
      };
      formLabelVisible: boolean;
      formSubmissionEvent: string;
      height: number;
      layout: boolean;
      pageViewEvent: string;
      stickyContact: boolean;
      style: {
        acBranding: boolean;
        background: string;
        border: object;
        padding: object;
        shadow: object;
      };
      submitMessageStyle: object;
      width: number;
    };
  };
}

export const FormServices = {
  getForms: (params: GetForms) => requests(FORM_SERVER_URL).get("/forms/", { params }),
  getFormById: (id: string) => requests(FORM_SERVER_URL).get(`/forms/${id}`),
  createForm: (params: CreateForm) => requests(FORM_SERVER_URL).post("/forms/", params),
  deleteForm: (params: DeleteForm) => requests(FORM_SERVER_URL).delete(`/forms/${params.formId}`),
  duplicateForm: (params: DuplicateForm) =>
    requests(FORM_SERVER_URL).post(`/forms/duplicate/${params.formId}`, {
      name: params.name,
    }),
  createFolder: (params: CreateFolder) => requests(FORM_SERVER_URL).post(`/forms/folder/`, params),
  getFolder: (params: GetFolder) =>
    requests(FORM_SERVER_URL).get(`/forms/folder/${params.folderId}`),
  listFolders: (params: ListFolders) => requests(FORM_SERVER_URL).get(`/forms/folder`, { params }),
  moveToFolder: (params: MoveToFolder) =>
    requests(FORM_SERVER_URL).post(`/forms/move-to-folder`, params),
  updateFolder: (params: UpdateFolder) =>
    requests(FORM_SERVER_URL).post(`/forms/folder/${params.id}`, {
      name: params.name,
    }),
  shareForm: (params: ShareForm) => {
    const { formId, locationId } = params;
    return requests(FORM_SERVER_URL).post(`/forms/share/${formId}`, {
      locationId,
    });
  },
  imageUpload: (body) =>
    requests(FORM_SERVER_URL).post("/forms/image", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  updateFormById: (id: String, body: any) => requests(FORM_SERVER_URL).post(`/forms/${id}`, body),
  getThemes: () => requests(FORM_SERVER_URL).get("/forms/themes"),
  getThemeStyles: (themeId: string) =>
    requests(FORM_SERVER_URL).get(`/forms/theme-style/${themeId}`),
  restoreFormVersion: (body: any) => requests(FORM_SERVER_URL).post(`/forms/restore-version`, body),
};
