<template>
  <div>
    <UIModal id="model" v-model:show="showModalNative" :width="950">
      <template #header>
        <UIModalHeader id="model-header" title="Integrate Form" @close="closeEditor">
        </UIModalHeader>
      </template>
      <UIModalContent>
        <div class="">
          <div class="flex">
            <div class="layout-container pr-4">
              <p>
                <strong>{{ t("form.embedlayoutType") }}</strong>
              </p>
              <span class="text-xs">{{ t("form.embedlayoutTypeSubMessage") }}</span>
              <div class="mt-3 flex justify-between">
                <div v-for="card in cards" :key="card.id" class="relative">
                  <div
                    :class="[
                      { 'card-layout-active border border-blue-500': isActiveLayout(card) },
                      { 'border border-red-400': showError.layout },
                      'card-layout-container flex border border-gray-400',
                    ]"
                    @click="onLayoutClick(card)"
                    @mouseover="showHoverText = { ...showHoverText, [card.id]: true }"
                    @mouseleave="showHoverText = { ...showHoverText, [card.id]: false }"
                  >
                    <img :src="card.image" :alt="card.name" height="76" width="94" />
                    <p class="mt-4">{{ card.name }}</p>
                  </div>
                  <div
                    v-if="showHoverText[card.id] && !isActiveLayout(card)"
                    class="card-description absolute mt-4 text-xs"
                  >
                    {{ card?.hoverDescription }}
                  </div>
                  <div
                    v-if="card.description && isActiveLayout(card)"
                    class="card-description absolute mt-4 text-xs"
                  >
                    <div
                      v-if="Object.keys(card).includes('allowMinimize')"
                      class="flex items-center items-baseline justify-around"
                    >
                      <UICheckbox
                        :id="card.id"
                        v-model:checked="selectedLayout.allowMinimize"
                        class="h-4 w-4"
                      />
                      <span>{{ t("form.allowMinimize") }}</span>
                    </div>
                    <UIInput
                      v-if="Object.keys(card).includes('allowMinimize')"
                      v-model="selectedLayout.minimizedTitle"
                      size="small"
                      class="mb-3 mt-3 py-1"
                      :placeholder="t('form.minimizedTitlePlaceholder')"
                      name="title"
                      :disabled="!selectedLayout.allowMinimize"
                    />
                    <div
                      v-if="Object.keys(card).includes('allowMinimize')"
                      class="divider-h mb-3"
                    ></div>
                    <div
                      v-if="Object.keys(card).includes('allowMinimize')"
                      class="mb-3 flex justify-center"
                    >
                      <span>
                        <img
                          v-if="selectedLayout.isLeftAligned"
                          src="./../../assets/img/layout-left-selected.png"
                          alt="left-align"
                          class="mr-2 h-6 w-6 cursor-pointer"
                        />
                        <img
                          v-else
                          src="./../../assets/img/layout-left.png"
                          alt="left-align"
                          class="mr-2 h-6 w-6 cursor-pointer"
                          width="25"
                          @click="
                            () => {
                              selectedLayout.image =
                                selectedLayout.id === STICKY_SIDEBAR
                                  ? FORM_LAYOUT_URLS.STICKY_SIDEBAR
                                  : FORM_LAYOUT_URLS.POLITE_SLIDE_IN_LEFT;
                              selectedLayout.isLeftAligned = true;
                              selectedLayout.isRightAligned = false;
                            }
                          "
                        />
                      </span>
                      <span>
                        <img
                          v-if="selectedLayout.isRightAligned"
                          src="./../../assets/img/layout-right-selected.png"
                          alt="left-align"
                          class="ml-2 h-6 w-6 cursor-pointer"
                          height="25"
                          width="25"
                        />
                        <img
                          v-else
                          src="./../../assets/img/layout-right.png"
                          alt="left-align"
                          class="ml-2 h-6 w-6 cursor-pointer"
                          height="25"
                          width="25"
                          @click="
                            () => {
                              selectedLayout.image =
                                selectedLayout.id === STICKY_SIDEBAR
                                  ? FORM_LAYOUT_URLS.STICKY_SIDEBAR_RIGHT
                                  : FORM_LAYOUT_URLS.POLITE_SLIDE_IN;
                              selectedLayout.isLeftAligned = false;
                              selectedLayout.isRightAligned = true;
                            }
                          "
                        />
                      </span>
                    </div>
                    <span>
                      {{ card.description }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="divider-v"></div>
            <div class="actions-container pl-4">
              <p class="mb-2 text-black">
                <strong>{{ t("form.triggerType") }}</strong>
              </p>
              <div v-for="trigger in TRIGGERS" :key="trigger.id" class="mb-2 flex">
                <UIRadio
                  :id="trigger.id"
                  :checked="selectedTriggerType === trigger.id"
                  :label="trigger.name"
                  :value="selectedTriggerType"
                  @change="onRadioButtonClick(trigger.id, 'trigger')"
                />
                <div v-if="trigger.showInput" class="flex items-center">
                  <UIInputNumber
                    :id="trigger.id"
                    v-model="fields[trigger.id]"
                    :show-button="false"
                    :name="trigger.name"
                    :min="1"
                    size="small"
                    placeholder=""
                    :disabled="selectedTriggerType !== trigger.id"
                    type="number"
                    :class="[
                      showError.trigger && selectedTriggerType === trigger.id
                        ? 'border-red-500'
                        : '',
                      'mx-2 h-7 !w-12',
                    ]"
                    @update:model-value="validateInput(fields[trigger.id], 'trigger')"
                  /><span>{{ trigger.id === "showOnScrolling" ? "%" : "seconds" }}</span>
                </div>
              </div>

              <p class="mb-2 mt-4 text-black">
                <strong>{{ t("form.activationOptions") }}</strong>
              </p>
              <div v-for="activation in ACTIVATION_OPTIONS" :key="activation.id" class="mb-2 flex">
                <UIRadio
                  :id="activation.id"
                  :checked="selectedActivation === activation.id"
                  :label="activation.name"
                  :value="activation.id"
                  @change="onRadioButtonClick(activation.id, 'activation')"
                />
                <div v-if="activation.showInput" class="flex items-center">
                  <UIInputNumber
                    :id="activation.id"
                    v-model="fields[activation.id]"
                    :show-button="false"
                    :name="activation.name"
                    :min="1"
                    size="small"
                    placeholder=""
                    type="number"
                    :class="[
                      showError.activation && selectedActivation === activation.id
                        ? 'border-red-500'
                        : '',
                      'mx-2 h-7 w-12',
                    ]"
                    @update:model-value="validateInput(fields[activation.id], 'activation')"
                  />
                  <span>{{ t("form.visit") }}</span>
                </div>
              </div>
              <p class="mb-2 mt-4 text-black">
                <strong>{{ t("form.deactivationOptions") }}</strong>
              </p>
              <div
                v-for="activation in DEACTIVATION_OPTIONS"
                :key="activation.id"
                class="mb-2 flex"
              >
                <UIRadio
                  :id="activation.id"
                  :checked="selectedDeactivation === activation.id"
                  :label="activation.name"
                  :value="activation.id"
                  @change="onRadioButtonClick(activation.id, 'deactivation')"
                />
                <div v-if="activation.showInput" class="flex items-center">
                  <UIInputNumber
                    :id="activation.id"
                    v-model="fields[activation.id]"
                    :name="activation.name"
                    size="small"
                    :show-button="false"
                    :min="1"
                    placeholder=""
                    type="number"
                    :class="[
                      showError.deactivation && selectedDeactivation === activation.id
                        ? 'border-red-500'
                        : '',
                      'mx-2 h-7 w-12',
                    ]"
                    @update:model-value="validateInput(fields[activation.id], 'deactivation')"
                  />
                  <span>{{ t("form.times") }}</span>
                </div>
              </div>
              <UIButton
                id="copyEmbedCode"
                class="mt-4 flex h-10 items-center rounded border-blue-500 px-3"
                :disabled="disableCopyEmbedBtn"
                @click="copyEmbedCode"
              >
                <Copy03Icon class="h-4 w-5 text-blue-500" />
                <span class="ml-2 text-base text-blue-500"> {{ t("form.copyEmbedCode") }} </span>
              </UIButton>
              <div class="flex">
                <UIButton
                  id="copyLink"
                  class="mt-3 flex h-10 items-center rounded border-blue-500 px-3"
                  @click="copyLink"
                >
                  <Link03Icon class="h-4 w-5 text-blue-500" />
                  <span class="ml-2 text-base text-blue-500"> {{ t("form.copyFormLink") }} </span>
                </UIButton>
                <a :href="embedUrl" target="_blank" class="ml-3 mt-3 flex items-center">
                  <span class="mr-1 text-blue-500">{{ t("form.openFormLink") }}</span>
                  <LinkExternal02Icon class="h-4 w-5 text-blue-500" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </UIModalContent>
      <template #footer>
        <div class="integrate-form-actions flex p-3">
          <span
            v-if="Object.values(showError).includes(true)"
            class="mr-3 flex items-center text-red-500"
            >{{ errorMessage }}</span
          >
          <UIButton id="integrateDone" type="primary" @click="submitCallback">{{
            t("common.done")
          }}</UIButton>
        </div>
      </template>
    </UIModal>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue";
import {
  UIModal,
  UIModalHeader,
  UIModalFooter,
  UIModalContent,
  UICheckbox,
  UIInput,
  UIInputNumber,
  UIRadio,
  UIButton,
} from "@gohighlevel/ghl-ui";
import { Copy03Icon, Link03Icon, LinkExternal02Icon } from "@gohighlevel/ghl-icons/24/outline";
import {
  FORM_LAYOUT_URLS,
  CARD_LAYOUTS,
  TRIGGERS,
  ACTIVATION_OPTIONS,
  DEACTIVATION_OPTIONS,
  STICKY_SIDEBAR,
  POLITE_SLIDE_IN,
  POPUP,
  INLINE,
} from "./../../util/constants";
import config from "./../../config/index";
import { useAppStore } from "@/store/app";
import { emitPendoEvent } from "./../../util/pendoConfig";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const store = useAppStore();

const props = defineProps({
  formName: {
    type: String,
    default: "",
  },
  formStyle: Object,
  modelActive: {
    type: Boolean,
    default: false,
  },
  isSurvey: {
    type: Boolean,
  },
  formId: String,
  formLayoutSettings: Object,
  isWhiteLabelled: {
    type: Boolean,
    default: false,
  },
  baseUrl: {
    type: String,
    default: "",
  },
});

const baseUrl = ref(props.baseUrl);
const embedUrl = ref("");
const iframeUrl = ref("");
const surveyId = ref("");
const iFrameId = ref("");
const cards = ref(CARD_LAYOUTS);
const selectedLayout = ref(CARD_LAYOUTS[3]);
const fields = reactive({
  showOnScrolling: "",
  showAfter: "",
  activateOnVisit: "",
  deactivateAfter: "",
});
const showError = ref({
  layout: false,
  trigger: false,
  activation: false,
  deactivation: false,
});
const showHoverText = ref({});
const errorMessage = ref("");
const disableCopyEmbedBtn = ref(false);
const selectedTriggerType = ref("alwaysShow");
const selectedActivation = ref("alwaysActivated");
const selectedDeactivation = ref("neverDeactivate");
const showModalNative = ref(props.modelActive);
const formLayoutSettings = reactive(props.formLayoutSettings || {});

const widgetName = computed(() => {
  return props.isSurvey ? "survey" : "form";
});
const widgetId = computed(() => {
  return props.isSurvey ? surveyId.value : props.formId;
});

setupLayout();

watch(
  () => showModalNative.value,
  (newValue) => {
    emit("update:showModel", newValue);
  }
);

const emit = defineEmits(["update:showModel", "update:submit", "update:cancel", "update:close"]);

const closeEditor = () => {
  saveFormLayoutSettings();
  showModalNative.value = false;
  emit("update:close", true);
};
const submitCallback = () => {
  saveFormLayoutSettings();
  showModalNative.value = false;
  emit("update:submit", true);
};

function setupLayout() {
  if (process.env.NODE_ENV === "development") {
    embedUrl.value = `http://localhost:3344/widget/${widgetName.value}/${widgetId.value}`;
  } else {
    embedUrl.value = baseUrl.value + `/widget/${widgetName.value}/${widgetId.value}`;
  }

  if (!isObjectEmpty(formLayoutSettings)) {
    fillForm();
  } else {
    resetForm();
  }
}

function fillForm() {
  selectedLayout.value = formLayoutSettings.selectedLayout;
  const fieldFilling = {
    showOnScrolling:
      formLayoutSettings.selectedTriggerType === "showOnScrolling"
        ? formLayoutSettings.fields.showOnScrolling
        : "",
    showAfter:
      formLayoutSettings.selectedTriggerType === "showAfter"
        ? formLayoutSettings.fields.showAfter
        : "",
    activateOnVisit:
      formLayoutSettings.selectedActivation === "activateOnVisit"
        ? formLayoutSettings.fields.activateOnVisit
        : "",
    deactivateAfter:
      formLayoutSettings.selectedDeactivation === "deactivateAfter"
        ? formLayoutSettings.fields.deactivateAfter
        : "",
  };
  Object.assign(fields, fieldFilling);

  selectedTriggerType.value = formLayoutSettings.selectedTriggerType;
  selectedActivation.value = formLayoutSettings.selectedActivation;
  selectedDeactivation.value = formLayoutSettings.selectedDeactivation;
  resetCards();
}
function resetForm() {
  selectedLayout.value = CARD_LAYOUTS[3];
  fields.showOnScrolling = "";
  fields.showAfter = "";
  fields.activateOnVisit = "";
  fields.deactivateAfter = "";

  showError.value = { layout: false, trigger: false, activation: false, deactivation: false };
  showHoverText.value = {};
  errorMessage.value = undefined;
  selectedTriggerType.value = "alwaysShow";
  selectedActivation.value = "alwaysActivated";
  selectedDeactivation.value = "neverDeactivate";

  resetCards();
}

function resetCards() {
  cards.value = cards.value.map((card) => {
    if (
      formLayoutSettings?.selectedLayout?.id !== card.id &&
      card.id !== INLINE &&
      card.id !== POPUP
    ) {
      card.allowMinimize = false;
      card.minimizedTitle = "";
    }
    return card;
  });
}
const onRadioButtonClick = (e, type) => {
  if (type === "trigger") {
    selectedTriggerType.value = e;
    if (fields[selectedTriggerType.value] !== "") {
      validateInput(fields[selectedTriggerType.value], type);
    } else {
      showError.value.trigger = false;
    }
  }
  if (type === "activation") {
    selectedActivation.value = e;
    if (fields[selectedActivation.value] !== "") {
      validateInput(fields[selectedActivation.value], type);
    } else {
      showError.value.activation = false;
    }
  }
  if (type === "deactivation") {
    selectedDeactivation.value = e;
    if (fields[selectedDeactivation.value] !== "") {
      validateInput(fields[selectedDeactivation.value], type);
    } else {
      showError.value.deactivation = false;
    }
  }
  enableOrDisableCopyEmbedBtn();
};

function validateInput(value, type) {
  if (
    type === "trigger" &&
    selectedTriggerType.value === "showOnScrolling" &&
    (Number(value) <= 0 || Number(value) > 100)
  ) {
    showError.value.trigger = true;
  } else if (Number(value) <= 0) {
    showError.value[type] = true;
  } else {
    showError.value[type] = false;
  }

  enableOrDisableCopyEmbedBtn();
}
const enableOrDisableCopyEmbedBtn = () => {
  if (Object.values(showError.value).includes(true)) {
    errorMessage.value = "Invalid input!";
    disableCopyEmbedBtn.value = true;
  } else {
    disableCopyEmbedBtn.value = false;
  }
};
const isActiveLayout = (card: any) => {
  return selectedLayout.value?.id === card.id;
};
const onLayoutClick = (card: any) => {
  if (card.id === formLayoutSettings?.selectedLayout?.id) {
    selectedLayout.value = formLayoutSettings.selectedLayout;
  } else {
    selectedLayout.value = card;
  }
  showError.value.layout = false;
};

const copyEmbedCode = () => {
  if (!disableCopyEmbedBtn.value) {
    validate();
    if (Object.values(showError.value).includes(true)) {
      return (errorMessage.value = "Please fill all the required fields!");
    }
  }
  errorMessage.value = undefined;
  createIframeUrl();
  saveFormLayoutSettings();
  const eventProperties = {
    layoutId: formLayoutSettings.selectedLayout.id,
    layoutName: formLayoutSettings.selectedLayout.name,
    allowMinimize: formLayoutSettings.selectedLayout.allowMinimize,
    minimizedTitle: formLayoutSettings.selectedLayout.minimizedTitle,
    isLeftAligned: formLayoutSettings.selectedLayout.isLeftAligned,
    isRightAligned: formLayoutSettings.selectedLayout.isRightAligned,
    selectedTriggerType: selectedTriggerType.value,
    selectedActivation: selectedActivation.value,
    selectedDeactivation: selectedDeactivation.value,
    showOnScrolling: fields[selectedTriggerType.value] ? fields[selectedTriggerType.value] : "",
    showAfter: fields[selectedTriggerType.value] ? fields[selectedTriggerType.value] : "",
    activateOnVisit: fields[selectedActivation.value] ? fields[selectedActivation.value] : "",
    deactivateAfter: fields[selectedDeactivation.value] ? fields[selectedDeactivation.value] : "",
  };
  emitPendoEvent("Copy-form-embed-code", eventProperties);
  store.copyToClipBoard(iframeUrl.value);
};

const validate = () => {
  showError.value.layout = !selectedLayout.value?.id ? true : false;
  if (!showError.value.trigger) {
    showError.value.trigger =
      !fields[selectedTriggerType.value] && selectedTriggerType.value !== "alwaysShow"
        ? true
        : false;
  }
  if (!showError.value.activation) {
    showError.value.activation =
      !fields[selectedActivation.value] && selectedActivation.value !== "alwaysActivated"
        ? true
        : false;
  }
  if (!showError.value.deactivation) {
    showError.value.deactivation =
      !fields[selectedDeactivation.value] && selectedDeactivation.value === "deactivateAfter"
        ? true
        : false;
  }
};
const createIframeUrl = () => (iframeUrl.value = getContainer());

const getContainer = () => {
  let displayStyle = "display:none;";
  if (selectedLayout.value.id === POPUP) {
    iFrameId.value = "popup";
  } else if (selectedLayout.value.id === INLINE) {
    iFrameId.value = "inline";
    if (
      selectedTriggerType.value === "alwaysShow" &&
      selectedActivation.value === "alwaysActivated" &&
      selectedDeactivation.value === "neverDeactivate"
    ) {
      displayStyle = "";
    }
  } else if (selectedLayout.value.id === POLITE_SLIDE_IN) {
    iFrameId.value = selectedLayout.value.isLeftAligned
      ? "polite-slide-in-left"
      : "polite-slide-in-right";
  } else if (selectedLayout.value.id === STICKY_SIDEBAR) {
    iFrameId.value = selectedLayout.value.isLeftAligned
      ? "sticky-sidebar-left"
      : "sticky-sidebar-right";
  }
  return `<iframe
  src="${embedUrl.value}"
  style="${displayStyle}width:100%;height:100%;border:none;border-radius:${
    props.formStyle?.border?.radius || 0
  }px"
  id="${iFrameId.value}-${widgetId.value}" ${getIframeDataAttributes()}>
</iframe>
<script src="${createEmbedScriptUrl()}/form_embed.js"><\/script>`;
};
const getIframeDataAttributes = () => {
  const layout = {
    id: selectedLayout.value.id,
    minimizedTitle: selectedLayout.value.minimizedTitle,
    isLeftAligned: selectedLayout.value.isLeftAligned,
    isRightAligned: selectedLayout.value.isRightAligned,
    allowMinimize: selectedLayout.value.allowMinimize,
  };
  let iFrameHeight;
  if (props.isSurvey) {
    iFrameHeight = document.getElementById("survey-builder-container")?.offsetHeight;
    // 60 is footer element height
    iFrameHeight = iFrameHeight + 60;
    // 126 is agency branding element height
    if (props.formStyle.acBranding) {
      iFrameHeight = iFrameHeight + 126;
    }
  }
  if (!props.isSurvey && selectedLayout.value.id === INLINE) {
    iFrameHeight = document.getElementById("the-form-builder")?.offsetHeight;
  } else {
    iFrameHeight = document.getElementById("form-builder-container")?.offsetHeight;
    if (props.formStyle.acBranding) {
      iFrameHeight = iFrameHeight + 126;
    }
  }

  const layoutString = JSON.stringify(layout) || "";
  const layoutFormatted = layoutString.replace(/"/g, "'");

  return `
  data-layout="${layoutFormatted}"
  data-trigger-type="${selectedTriggerType.value}"
  data-trigger-value="${fields[selectedTriggerType.value] ? fields[selectedTriggerType.value] : ""}"
  data-activation-type="${selectedActivation.value}"
  data-activation-value="${
    fields[selectedActivation.value] ? fields[selectedActivation.value] : ""
  }"
  data-deactivation-type="${selectedDeactivation.value}"
  data-deactivation-value="${
    fields[selectedDeactivation.value] ? fields[selectedDeactivation.value] : ""
  }"
  data-form-name="${props.formName}"
  data-height="${iFrameHeight}"
  data-layout-iframe-id="${iFrameId.value}-${widgetId.value}"
  data-form-id="${widgetId.value}"
  title="${props.formName}"
      `;
};

const createEmbedScriptUrl = () => {
  if (props.isWhiteLabelled && process.env.NODE_ENV !== "beta") {
    return `${baseUrl.value}/js`;
  }
  return config.FORM_EMBED_URL;
};
const saveFormLayoutSettings = () => {
  const formLayoutSettingsModified = {
    selectedLayout: selectedLayout.value,
    selectedTriggerType: selectedTriggerType.value,
    selectedActivation: selectedActivation.value,
    selectedDeactivation: selectedDeactivation.value,
    fields: {
      showOnScrolling: fields[selectedTriggerType.value],
      showAfter: fields[selectedTriggerType.value],
      activateOnVisit: fields[selectedActivation.value],
      deactivateAfter: fields[selectedDeactivation.value],
    },
  };
  Object.assign(formLayoutSettings, formLayoutSettingsModified);
  store.saveToLocalStorage(`embedCode${props.formId}`, JSON.stringify(formLayoutSettings));
};

const copyLink = () => {
  store.copyToClipBoard(embedUrl.value);
};

function isObjectEmpty(objectName) {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
}
</script>
<style scoped>
.card-layout-container {
  height: 146px;
  border-radius: 5px;
  width: 117px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-color: #f5f5f5;
}
.card-layout-active {
  background-color: #ffffff;
  box-shadow: 0px 3px 17px rgba(0, 0, 0, 0.11);
}
.card-description {
  text-align: center;
}
.layout-container {
  width: 63%;
}
.actions-container {
  width: 39%;
}
.divider-v {
  border-right: 1px solid #e5e7eb;
  height: 483px;
}
.divider-h {
  border-bottom: 1px solid #e5e7eb;
  height: 2px;
  width: 100%;
}
.trigger-input-radio {
  margin-right: 20px;
}
.trigger-input {
  height: 28px;
}
.integrate-form-actions {
  display: flex;
  justify-content: flex-end;
}
.cancel-btn {
  width: 83px;
  height: 42px;
  background: #ffffff;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
}
.save-btn {
  width: 83px;
  height: 42px;
  color: #ffffff;
  background: #3b82f6;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
}

input {
  padding: 0 3px;
}
input[type="text"] {
  padding: 0 3px;
}
.modal .modal-dialog {
  max-width: 900px;
}

.modalBody {
  padding: 0;
}

a {
  text-decoration: none;
}
.hl-modal-content {
  border-top: 1px solid #e5e7eb;
}
</style>
