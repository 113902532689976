<template>
  <div class="sub-settings-header">
    <UITextMdMedium>{{ t("common.payment.paymentFields") }}</UITextMdMedium>
  </div>
  <div class="flex flex-col items-start gap-3 self-stretch">
    <div class="flex items-start gap-4 self-stretch">
      <UISwitch :value="store.payment.suggestionOn" @update:value="suggestionUpdate"></UISwitch>
      <div>{{ t("common.payment.suggestAnAmount") }}</div>
    </div>
    <div class="flex flex-col items-start justify-between gap-2 self-stretch">
      <div class="flex space-x-3">
        <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">
          {{ t("common.payment.selectCurrency") }}</span
        >
      </div>
      <UISelect
        id="font-picker"
        size="large"
        class="standard-input-ghl"
        :value="store.payment.currency.code"
        :options="store.currencyOptions"
        :filterable="true"
        @change="handleSelect"
        @blur="store.addToHistory()"
      ></UISelect>
    </div>

    <div v-if="store.payment.suggestionOn">
      <div class="flex flex-[1_0_0] flex-wrap content-center items-start gap-[10px]">
        <UIDynamicTags
          :id="'dynamicTags'"
          :type="'default'"
          :value="suggestedAmount"
          :max="15"
          :disabled="false"
          :closable="true"
          :round="true"
          @on-update="handleAddAmountTag"
        ></UIDynamicTags>
        <UICheckbox
          id="includeOtherAmount"
          v-model:checked="store.payment.enableOtherAmount"
          @update:checked="store.anyUnsavedChanges = true"
          @blur="store.addToHistory()"
          >{{ t("common.payment.includeOtherAmount") }}</UICheckbox
        >
      </div>
    </div>
    <div class="flex flex-col items-start justify-between self-stretch">
      <div class="flex space-x-3">
        <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
          t("common.placeholder")
        }}</span>
      </div>
      <UIInput
        id="amount-placeholder"
        v-model="store.payment.amountPlaceHolder"
        class="standard-input-ghl border-r-0 border-none"
        @update:model-value="store.anyUnsavedChanges = true"
        @blur="store.addToHistory()"
      >
      </UIInput>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { UICheckbox, UIDynamicTags, UIInput, UISelect, UISwitch } from "@gohighlevel/ghl-ui";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/store/app";
import { convertToNumbers, convertToStrings } from "@/util/methods";

const { t } = useI18n();
const store = useAppStore();

const suggestedAmount = computed(() => {
  return convertToStrings(store.payment.suggestedAmount);
});

const handleSelect = (currency) => {
  store.payment.currency = { code: currency, symbol: store.currencyList[currency].symbol };
  store.anyUnsavedChanges = true;
};

const suggestionUpdate = (isSuggested) => {
  store.payment.suggestionOn = isSuggested;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};

const handleAddAmountTag = (amount) => {
  const parsedNumbers = convertToNumbers(amount);
  store.payment.suggestedAmount = parsedNumbers;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};
</script>
