import { useNotification } from "@gohighlevel/ghl-ui";

export function useNotify() {
  const notification = useNotification();

  interface NotificationParam {
    title: string;
    description?: string;
    type?: "default" | "error" | "info" | "success" | "warning";
  }

  function sendNotification({ title, description, type }: NotificationParam) {
    notification.create({
      title: title,
      description: description,
      duration: 5000,
      type: type || "default",
    });
  }
  return {
    sendNotification,
  };
}
