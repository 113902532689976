<template>
  <div class="flex flex-col items-start gap-3 self-stretch">
    <UITextMdMedium class="sub-settings-header">
      {{ t("common.payment.product.layoutSettings") }}</UITextMdMedium
    >
    <div class="flex items-start gap-3 self-stretch">
      <div class="layout-container" @click="layoutChange(1)">
        <div class="layout-card" :class="{ 'layout-card-active': currentLayout === 1 }">
          <div class="layout-card-icon">
            <OneGroupIcon></OneGroupIcon>
          </div>
        </div>
        <UITextXsMedium>{{ t("common.payment.product.singleColumn") }}</UITextXsMedium>
      </div>
      <div class="layout-container" @click="layoutChange(2)">
        <div class="layout-card" :class="{ 'layout-card-active': currentLayout === 2 }">
          <div class="layout-card-icon">
            <TwoGroupIcon></TwoGroupIcon>
          </div>
        </div>
        <UITextXsMedium>{{ t("common.payment.product.twoColumn") }}</UITextXsMedium>
      </div>
      <div class="layout-container" @click="layoutChange(3)">
        <div class="layout-card" :class="{ 'layout-card-active': currentLayout === 3 }">
          <div class="layout-card-icon">
            <ThreeGroupIcon></ThreeGroupIcon>
          </div>
        </div>
        <UITextXsMedium>{{ t("common.payment.product.threeColumn") }}</UITextXsMedium>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import OneGroupIcon from "@/assets/icons/one-group.svg";
import ThreeGroupIcon from "@/assets/icons/three-group.svg";
import TwoGroupIcon from "@/assets/icons/two-group.svg";
import { useAppStore } from "@/store/app";
import { UITextXsMedium } from "@gohighlevel/ghl-ui";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useAppStore();

const currentLayout = computed(() => {
  return store.payment.layout || 1;
});

const layoutChange = (layoutIndex: number) => {
  store.payment.layout = layoutIndex;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};
</script>

<style scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  cursor: pointer;
}

.layout-card {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #eff4ff;
}
.layout-card-active {
  border: 2px solid #155eef;
}
.layout-card-icon {
  padding: 0px 16px;
}
</style>
