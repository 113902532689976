<script setup lang="ts">
import { UIButton, UISpace, UITabPane, UITabs } from "@gohighlevel/ghl-ui";
import { useAppStore } from "@/store/app";
import SidebarCustomFields from "./SidebarCustomFields.vue";
import SidebarStandardFields from "./SidebarStandardFields.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const store = useAppStore();
const quickAdd = `${t("common.quickAdd")}`;
const customFields = `${t("common.customFields")}`;
</script>
<template>
  <UITabs justify-content="center" default-value="standard" type="segment">
    <UITabPane name="standard" :tab="quickAdd">
      <SidebarStandardFields />
    </UITabPane>
    <UITabPane name="custom" :tab="customFields">
      <SidebarCustomFields :custom-fields="store.getCustomFieldsFromStore" />
    </UITabPane>
  </UITabs>
</template>
