<template>
  <div id="borderStyle" class="flex flex-col items-start gap-2 self-stretch">
    <UITextSmMedium>{{ label }} </UITextSmMedium>
    <ColorInput
      :label="t('common.color')"
      :color="shadow.color"
      @update:color="(value) => emits('update:color', value)"
    />
    <div class="grid grid-cols-2 gap-4">
      <PixelNumber
        :label="t('common.horizontal')"
        :value="shadow.horizontal"
        @update:value="(value) => emits('update:horizontal', value)"
      ></PixelNumber>
      <PixelNumber
        :label="t('common.vertical')"
        :value="shadow.vertical"
        @update:value="(value) => emits('update:vertical', value)"
      ></PixelNumber>
      <PixelNumber
        :label="t('common.blur')"
        :value="shadow.blur"
        @update:value="(value) => emits('update:blur', value)"
      ></PixelNumber>
      <PixelNumber
        :label="t('common.spread')"
        :value="shadow.spread"
        @update:value="(value) => emits('update:spread', value)"
      ></PixelNumber>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UITextSmMedium } from "@gohighlevel/ghl-ui";
import { PropType } from "vue";
import { useI18n } from "vue-i18n";
import ColorInput from "./ColorInput.vue";
import PixelNumber from "./PixelNumber.vue";

const { t } = useI18n();

interface Shadow {
  horizontal: number;
  vertical: number;
  blur: number;
  spread: number;
  color: string;
}

defineProps({
  label: {
    type: String,
    default: "",
  },
  shadow: {
    type: Object as PropType<Shadow>,
    required: true,
  },
});

const emits = defineEmits([
  "update:horizontal",
  "update:vertical",
  "update:blur",
  "update:spread",
  "update:color",
]);
</script>
