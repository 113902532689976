<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { InfoCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UISelect, UITextXsMedium, UITooltip } from "@gohighlevel/ghl-ui";
import { VNode, h, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
const store = useAppStore();

const { t } = useI18n();
const props = defineProps({
  label: {
    type: String,
    default: "Font Family",
  },
  value: {
    type: String,
    default: "Roboto",
  },
  selectClass: {
    type: String,
    default: "",
  },
});
const standardFonts = [
  { label: "Arial", value: "arial" },
  { label: "Helvetica", value: "helvetica" },
  { label: "Times", value: "times" },
  { label: "Palatino", value: "palatino" },
  { label: "Garamond", value: "garamond" },
];

const popularFonts = [
  { label: "Roboto", value: "Roboto" },
  { label: "Open Sans", value: "Open Sans" },
  { label: "Noto Sans JP", value: "Noto Sans JP" },
  { label: "Montserrat", value: "Montserrat" },
  { label: "Lato", value: "Lato" },
  { label: "Poppins", value: "Poppins" },
  { label: "Roboto Condensed", value: "Roboto Condensed" },
  { label: "Inter", value: "Inter" },
  { label: "Roboto Mono", value: "Roboto Mono" },
  { label: "Oswald", value: "Oswald" },
  { label: "Raleway", value: "Raleway" },
  { label: "Noto Sans", value: "Noto Sans" },
  { label: "Nunito Sans", value: "Nunito Sans" },
  { label: "Roboto Slab", value: "Roboto Slab" },
  { label: "Ubuntu", value: "Ubuntu" },
  { label: "Nunito", value: "Nunito" },
  { label: "Playfair Display", value: "Playfair Display" },
  { label: "Merriweather", value: "Merriweather" },
  { label: "Rubik", value: "Rubik" },
  { label: "PT Sans", value: "PT Sans" },
  { label: "Kanit", value: "Kanit" },
];
const popularAndStandardFonts = [...popularFonts, ...standardFonts];
const options = ref(popularAndStandardFonts);
const searchableOptions = ref([]);

watch(
  () => store.builderConfig.isGDPRCompliant,
  (newType, oldType) => {
    fontSelector(newType);
  }
);
const emit = defineEmits(["update:value"]);
const handleSelect = (val: string) => {
  emit("update:value", val);
  store.addToHistory();
  options.value = popularAndStandardFonts;
};
const handleSearch = (val: string) => {
  if (val.trim() === "") {
    options.value = popularAndStandardFonts;
    return;
  }
  const searchFonts = [...searchableOptions.value, ...standardFonts];
  options.value = searchFonts.filter((item) => {
    const regex = new RegExp(val, "gi");
    return item.label.match(regex);
  });
};
const renderOptions = ({ node, option }: { node: VNode; option }) =>
  h(
    UITooltip,
    { placement: "left-start" },
    {
      trigger: () => node,
      default: () => option.label,
    }
  );
onMounted(() => {
  fontSelector(store.builderConfig.isGDPRCompliant);
});

const fontSelector = (isGDPRCompliant: boolean) => {
  searchableOptions.value = isGDPRCompliant ? store.bunnyFonts : store.googleFonts;
};
</script>

<template>
  <div class="flex flex-col items-start gap-2 self-stretch">
    <UITextXsMedium class="flex"
      >{{ label }}
      <UITooltip :style="{ maxWidth: '300px' }" trigger="hover" placement="top">
        <template #trigger>
          <InfoCircleIcon class="ml-1 h-4 w-4 text-gray-400"></InfoCircleIcon>
        </template>
        {{ t("common.fontFamilyTooltip") }}
      </UITooltip></UITextXsMedium
    >
    <UISelect
      id="font-picker"
      class="font-family-picker"
      :class="selectClass"
      size="large"
      :value="value"
      :options="options"
      :render-option="renderOptions"
      :filterable="true"
      @change="handleSelect"
      @search="handleSearch"
    />
  </div>
</template>

<style scoped></style>
