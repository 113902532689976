<script setup lang="ts">
// import { useAppStore } from "@/store/app";
import { UIBuilderSpace, UITextSmMedium } from "@gohighlevel/ghl-ui";
import { PropType, computed } from "vue";
import { useI18n } from "vue-i18n";
// const store = useAppStore();

interface Padding {
  top: number;
  bottom: number;
  right: number;
  left: number;
}
interface Props {
  label: string;
  padding: Padding;
}
const { t } = useI18n();
const props = defineProps({
  label: {
    type: String,
    default: "Form Padding",
  },
  padding: {
    type: Object as PropType<Padding>,
    default: () => {
      return { top: 0, right: 0, bottom: 0, left: 0 };
    },
  },
});

const emits = defineEmits(["updatePadding"]);

const updatedValue = (value) => {
  if (value.changed.padding.right) {
    return emits("updatePadding", "right", parseFloat(value.numericValue));
  }
  if (value.changed.padding.left) {
    return emits("updatePadding", "left", parseFloat(value.numericValue));
  }
  if (value.changed.padding.top) {
    return emits("updatePadding", "top", parseFloat(value.numericValue));
  }
  if (value.changed.padding.bottom) {
    return emits("updatePadding", "bottom", parseFloat(value.numericValue));
  }
};
const paaddingPixel = computed(() => {
  return {
    top: (props.padding.top || 0) + "px",
    right: (props.padding.right || 0) + "px",
    bottom: (props.padding.bottom || 0) + "px",
    left: (props.padding.left || 0) + "px",
  };
});
</script>

<template>
  <div class="flex flex-col items-start gap-2 self-stretch">
    <UITextSmMedium>{{ label }} </UITextSmMedium>
    <div class="flex justify-center self-stretch">
      <UIBuilderSpace
        :margin-readonly="true"
        :margin="{ top: 'auto', bottom: 'auto', right: 'auto', left: 'auto' }"
        :padding="paaddingPixel"
        :padding-units-config="['px']"
        @update:value="updatedValue"
      />
    </div>
  </div>
</template>

<style scoped></style>
