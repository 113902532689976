<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { THEMES } from "@/util/constants";
const props = defineProps({
  themeSelected: {
    type: String,
    default: "steps",
  },
});

const store = useAppStore();
const emits = defineEmits(["updateTheme"]);

const isSelected = (theme: string) => props.themeSelected === theme;
const updateTheme = (theme: string) => {
  emits("updateTheme", theme);
  store.addToHistory();
};
const formatThemeName = (theme: string) => theme.replace(/([A-Z])/g, " $1").trim();
</script>

<template>
  <div class="flex flex-wrap">
    <div
      v-for="themeOption in THEMES"
      :key="themeOption.theme"
      class="flex-column flex h-[100px] w-1/2 items-center justify-center"
    >
      <div class="mb-1">
        <div
          v-if="isSelected(themeOption.theme)"
          class="bg-base-white rounded-lg border border-gray-200 px-3"
          :class="[isSelected(themeOption.theme) ? 'active' : '']"
        >
          <img
            :src="themeOption.selectedImage"
            :alt="themeOption.theme"
            class="cursor-pointer"
            height="96"
            width="96"
          />
        </div>
        <div
          v-else
          class="bg-base-white rounded-lg border border-gray-200 px-3"
          :class="[isSelected(themeOption.theme) ? 'active' : '']"
        >
          <img
            :src="themeOption.unselectedImage"
            :alt="themeOption.theme"
            class="cursor-pointer"
            height="96"
            width="96"
            @click="updateTheme(themeOption.theme)"
          />
        </div>
      </div>
      <p class="text-xs capitalize text-gray-500">{{ formatThemeName(themeOption.theme) }}</p>
    </div>
  </div>
</template>

<style scoped>
.active {
  border: 2px solid #155eef !important;
  background: #eff4ff;
}
</style>
