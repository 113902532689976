<template>
  <div class="sub-settings-header">
    <UITextMdMedium class="font-medium">{{ t("common.payment.coupons.coupons") }}</UITextMdMedium>
  </div>
  <Switch
    :label="t('common.payment.coupons.enableCoupon')"
    class="w-100"
    :value="store.payment.coupon?.enableCoupon"
    @update:value="(value) => handleEnableCoupon(value)"
  />
  <div v-if="store.payment.coupon?.enableCoupon" id="coupon-alignment" class="form-group">
    <div class="flex space-x-3">
      <span class="switch-handle mb-2 block text-xs font-medium text-gray-700">
        {{ t("common.payment.coupons.couponAlignment") }}
        <UITooltip width="300px" trigger="hover" placement="top">
          <template #trigger>
            <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
          </template>
          {{ t("common.payment.coupons.tooltipMessage") }}
        </UITooltip>
      </span>
    </div>
    <UIRadioGroup
      :value="selectedAlignment"
      name="couponAlignment"
      @update:value="updateCouponAlignment"
    >
      <UISpace size="small" horizontal>
        <UIRadioGroupItem
          v-for="aligntment in couponAlignment"
          :key="aligntment.key"
          :value="aligntment.key"
          class="w-[100px] text-xs font-medium"
          :disabled="false"
          :compact="true"
        >
          <template #content>
            <div class="flex justify-center">
              {{ aligntment.name }}
            </div>
          </template>
        </UIRadioGroupItem>
      </UISpace>
    </UIRadioGroup>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { UIRadioGroup, UIRadioGroupItem, UISpace, UITooltip } from "@gohighlevel/ghl-ui";
import { InfoCircleIcon } from "@gohighlevel/ghl-icons/24/outline";

import Switch from "../Switch.vue";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/store/app";
import { couponAlignment } from "@/util/constants";

const { t } = useI18n();
const store = useAppStore();

const selectedAlignment = ref(store.payment.coupon?.couponAlignment || "right");
const handleEnableCoupon = (value: boolean) => {
  if (!store.payment.coupon) {
    store.payment.coupon = {
      enableCoupon: false,
      couponAlignment: "right",
    };
  }
  store.payment.coupon.enableCoupon = value;
  store.anyUnsavedChanges = true;
};

const updateCouponAlignment = (alignment: string) => {
  selectedAlignment.value = alignment;
  store.payment.coupon.couponAlignment = alignment;
  store.anyUnsavedChanges = true;
};
</script>

<style>
#coupon-alignment
  .hl-radio-group-item.n-radio-button
  .hl-radio-group-item-container
  .hl-radio-group-item-content {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  align-items: center;
}
</style>
