<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { InfoCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UICheckbox, UIInput, UITooltip } from "@gohighlevel/ghl-ui";
import { snakeCase } from "lodash";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const store = useAppStore();
const { t } = useI18n();

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
});

const uniqueKeyPlaceholder = ref(snakeCase(store.addressSettings?.children?.[props.index]?.label));

const isHiddenFieldDisable = computed(() => {
  if (store.addressSettings?.children?.[props.index]?.required) {
    return true;
  }
  return false;
});

const isRequiredFieldDisable = computed(() => {
  if (store.addressSettings?.children?.[props.index]?.hidden) {
    return true;
  }
  return false;
});

const getLabel = computed(() => {
  return store.addressSettings?.children?.[props.index]?.label;
});

const getTypeLabel = computed(() => {
  return store.addressSettings?.children?.[props.index]?.typeLabel;
});

const getPlaceholder = computed(() => {
  return store.addressSettings?.children?.[props.index]?.placeholder;
});

const getShortLabel = computed(() => {
  return store.addressSettings?.children?.[props.index]?.shortLabel;
});

const getHiddenQuery = computed(() => {
  return store.addressSettings?.children?.[props.index]?.hiddenFieldQueryKey;
});

const getUniqueKey = computed(() => {
  return store.addressSettings?.children?.[props.index]?.fieldKey;
});

const getRequired = computed(() => {
  return store.addressSettings?.children?.[props.index]?.required;
});

const getHidden = computed(() => {
  return store.addressSettings?.children?.[props.index]?.hidden;
});

const getHiddenValue = computed(() => {
  return store.addressSettings?.children?.[props.index]?.hiddenFieldValue;
});

const updateRequired = (value: boolean) => {
  store.addressSettings.children[props.index].required = value;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};
const updateHidden = (value: boolean) => {
  store.addressSettings.children[props.index].hidden = value;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};

const updateHiddenValue = (value: string) => {
  store.addressSettings.children[props.index].hiddenFieldValue = value;
  store.anyUnsavedChanges = true;
};

const updateLabel = (value: string) => {
  store.addressSettings.children[props.index].label = value;
  uniqueKeyPlaceholder.value = snakeCase(value);
  updateUniqueKey(uniqueKeyPlaceholder.value);
  store.anyUnsavedChanges = true;
};

const updateUniqueKey = (value: string) => {
  if (value.trim() !== "") {
    store.addressSettings.children[props.index].fieldKey = value;
    store.anyUnsavedChanges = true;
  }
};

const updatePlaceholder = (value: string) => {
  store.addressSettings.children[props.index].placeholder = value;
  store.anyUnsavedChanges = true;
};
const updateShortLabel = (value: string) => {
  store.addressSettings.children[props.index].shortLabel = value;
  store.anyUnsavedChanges = true;
};
const updateHiddenQuery = (value: string) => {
  store.addressSettings.children[props.index].hiddenFieldQueryKey = value;
  store.anyUnsavedChanges = true;
};
</script>
<template>
  <div class="form-group">
    <div class="flex space-x-3">
      <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
        t("common.label")
      }}</span>
    </div>
    <UIInput
      id="label"
      class="standard-input-ghl"
      :model-value="getLabel"
      @update:model-value="updateLabel"
      @blur="store.addToHistory()"
    ></UIInput>
  </div>
  <div
    v-if="
      !['TEXTBOX_LIST', 'SIGNATURE'].includes(store.addressSettings?.children?.[index]?.dataType)
    "
    class="form-group"
  >
    <div class="flex space-x-3">
      <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
        t("common.placeholder")
      }}</span>
    </div>
    <UIInput
      id="fieldPlaceholder"
      class="standard-input-ghl"
      :model-value="getPlaceholder"
      @update:model-value="updatePlaceholder"
      @blur="store.addToHistory()"
    ></UIInput>
  </div>
  <div
    v-if="
      !['image', 'header', 'button', 'terms_and_conditions'].includes(
        store.addressSettings?.children?.[index]?.tag
      )
    "
    class="form-group"
  >
    <div class="flex space-x-3">
      <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
        t("common.shortLabel")
      }}</span>
    </div>
    <UIInput
      id="shortLabel"
      class="standard-input-ghl"
      :model-value="getShortLabel"
      @update:model-value="updateShortLabel"
      @blur="store.addToHistory()"
    >
    </UIInput>
  </div>
  <div
    v-if="
      !['TEXTBOX_LIST', 'MULTIPLE_OPTIONS', 'FILE_UPLOAD', 'SIGNATURE'].includes(
        store.addressSettings?.children?.[index]?.dataType
      )
    "
    class="form-group"
  >
    <div class="flex space-x-3">
      <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
        {{ t("common.queryKey") }}
        <UITooltip trigger="hover" placement="top">
          <template #trigger>
            <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
          </template>
          {{ t("common.querykeyTooltipMessage") }}
        </UITooltip>
      </div>
    </div>
    <UIInput
      id="fieldQueryKey"
      class="standard-input-ghl"
      :model-value="getHiddenQuery"
      @update:model-value="updateHiddenQuery"
      @blur="store.addToHistory()"
    ></UIInput>
  </div>

  <div class="form-check-req-hid">
    <UICheckbox
      id="fieldRequired"
      :disabled="isRequiredFieldDisable"
      :checked="getRequired"
      @update:checked="updateRequired"
      >{{ t("common.required") }}</UICheckbox
    >
    <UICheckbox
      v-if="
        ![
          'TEXTBOX_LIST',
          'SINGLE_OPTIONS',
          'MULTIPLE_OPTIONS',
          'FILE_UPLOAD',
          'SIGNATURE',
        ].includes(store.addressSettings?.children?.[index]?.dataType)
      "
      id="fieldCheckbox"
      :disabled="isHiddenFieldDisable"
      :checked="getHidden"
      @update:checked="updateHidden"
      >{{ t("common.hidden") }}</UICheckbox
    >
  </div>
  <div v-if="store.addressSettings?.children?.[index]?.hidden" class="form-group hidden-field">
    <div class="flex space-x-3">
      <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
        t("common.hiddenValue")
      }}</span>
    </div>
    <UIInput
      id="fieldHiddenvalue"
      class="standard-input-ghl"
      :model-value="getHiddenValue"
      @update:model-value="updateHiddenValue"
      @blur="store.addToHistory()"
    ></UIInput>
  </div>
</template>
