<script setup lang="ts">
import { useEmitter } from "@/composition/useEmitter";
import { useAppStore } from "@/store/app";
import { Settings01Icon } from "@gohighlevel/ghl-icons/24/outline";
import {
  UIInputNumber,
  UISelect,
  UITextSmMedium,
  UITextSmRegular,
  UITextXsMedium,
} from "@gohighlevel/ghl-ui";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import ManageCategoryModal from "../ManageCategoryModal.vue";

const store = useAppStore();
const emitter = useEmitter();

const { t } = useI18n();
const props = defineProps({
  index: {
    type: Number,
    default: 0,
  },
  selectedCategory: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const emit = defineEmits(["update:value"]);

const optionCategory = ref(
  store.category.map((cat) => {
    return { label: cat.label, value: cat.id };
  })
);
const score = ref(props.selectedCategory.score || 0);

const isAddNewEnabled = ref(false);

const value = ref(store.category.find((cat) => cat.id === props.selectedCategory.categoryId)?.id);

const handleSelect = (val: string) => {
  const category = store.category.find((cat) => cat.id === val);
  emit("update:value", {
    category: category.value,
    index: props.index,
    score: score.value,
    categoryId: category.id,
  });
  value.value = val;
  store.addToHistory();
};

const addNew = () => {
  isAddNewEnabled.value = true;
};

const updateScore = (newScore) => {
  score.value = newScore;
  const category = store.category.find((cat) => cat.id === value.value);
  emit("update:value", {
    categoryId: value.value,
    index: props.index,
    score: score.value,
    category: category.value,
  });
};
</script>

<template>
  <div class="flex items-start gap-2 self-stretch">
    <div class="w-full">
      <UITextSmMedium class="flex">Category</UITextSmMedium>
      <UISelect
        id="category-picker"
        size="large"
        :value="value"
        :options="optionCategory"
        :filterable="true"
        @change="handleSelect"
      >
        <template #action>
          <div
            v-if="!isAddNewEnabled"
            class="flex cursor-pointer items-center justify-center gap-1"
            @click="addNew()"
          >
            <Settings01Icon class="h-4 w-4" />
            <UITextSmRegular>Add / Manage</UITextSmRegular>
          </div>
          <ManageCategoryModal
            :show="isAddNewEnabled"
            @update:close-modal="isAddNewEnabled = false"
          ></ManageCategoryModal>
        </template>
      </UISelect>
    </div>
    <div class="w-[100px]">
      <UITextXsMedium class="flex">Score</UITextXsMedium>
      <UIInputNumber
        id="score"
        :model-value="score"
        :min="0"
        size="large"
        placeholder="10"
        :disabled="!value"
        :show-button="false"
        @update:model-value="updateScore"
      ></UIInputNumber>
    </div>
  </div>
</template>
