<template>
  <ColorInput
    :label="t('common.style.labelColor')"
    :color="store?.fieldStyle?.labelColor"
    @update:color="handleLabelColor"
  />
  <div class="flex items-start gap-3">
    <FontFamilyInput
      :label="t('common.fontFamily')"
      :value="store?.fieldStyle?.labelFontFamily"
      select-class="w-[122px]"
      @update:value="handleLabelFontFamily"
    />
    <PixelNumber
      :label="t('common.style.fontSize')"
      :value="parseFloat(store?.fieldStyle?.labelFontSize)"
      @update:value="handleLabelFontSize"
    />
    <PixelNumber
      :label="t('common.style.fontWeight')"
      :value="parseFloat(store?.fieldStyle?.labelFontWeight)"
      :max="1800"
      @update:value="handleLabelFontWeight"
    />
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { useI18n } from "vue-i18n";
import ColorInput from "../Elements/ColorInput.vue";
import FontFamilyInput from "../Elements/FontFamilyInput.vue";
import PixelNumber from "../Elements/PixelNumber.vue";

const { t } = useI18n();
const store = useAppStore();

const handleLabelColor = (val: string) => {
  store.fieldStyle.labelColor = val;
  store.anyUnsavedChanges = true;
};
const handleLabelFontFamily = (val: string) => {
  if (!store.fontCollection.includes(val)) {
    store.fontCollection.push(val);
  }
  store.fieldStyle.labelFontFamily = val;
  store.anyUnsavedChanges = true;
};
const handleLabelFontSize = (val: number) => {
  store.fieldStyle.labelFontSize = val;
  store.anyUnsavedChanges = true;
};
const handleLabelFontWeight = (val: number) => {
  store.fieldStyle.labelFontWeight = val;
  store.anyUnsavedChanges = true;
};
</script>
