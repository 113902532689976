<script setup lang="ts">
import { useEmitter } from "@/composition/useEmitter";
import { useAppStore } from "@/store/app";
import {
  Bell03Icon,
  ClockRewindIcon,
  Dataflow03Icon,
  Grid01Icon,
  PlusIcon,
  ReverseLeftIcon,
  ReverseRightIcon,
  Settings04Icon,
} from "@gohighlevel/ghl-icons/24/outline";
import { UIButton, UIToolbar, UIToolbarGroup, UITooltip } from "@gohighlevel/ghl-ui";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import ManageCategoryModal from "./../quizzes/ManageCategoryModal.vue";
import ConditionalLogicModal from "./ConditionalLogicModal.vue";

const showConditionalLogicModal = ref(false);
const showManageCategories = ref(false);
const store = useAppStore();
const emitter = useEmitter();
const { t } = useI18n();

const goToVersions = () => (store.showVersions = true);
</script>

<template>
  <UIToolbar class="secondary-header-toolbar mt-[50px] border-b bg-white p-0">
    <UIToolbarGroup class="w-full min-w-[230px]">
      <div class="flex justify-between">
        <div class="border-right flex justify-center">
          <div class="flex gap-x-5 px-4">
            <UITooltip trigger="hover" placement="top">
              <template #trigger>
                <UIButton
                  id="open-left-bar"
                  text
                  size="small"
                  class="text-gray-800"
                  @click="emitter.emit('toggle-left-side-bar')"
                >
                  <PlusIcon class="h-4 w-4" />
                </UIButton>
              </template>
              {{
                store.formId
                  ? t("form.addFormElement")
                  : store.surveyId
                  ? t("survey.addSurveyElement")
                  : t("quiz.addQuizElement")
              }}
            </UITooltip>
            <UITooltip v-if="store.formId" trigger="hover" placement="top">
              <template #trigger>
                <UIButton
                  id="open-conditional-logic"
                  text
                  size="small"
                  class="text-gray-800"
                  @click="showConditionalLogicModal = true"
                >
                  <Dataflow03Icon class="h-4 w-4" />
                </UIButton>
              </template>
              {{ t("form.addConditionalLogic") }}
            </UITooltip>
            <UITooltip trigger="hover" placement="top">
              <template #trigger>
                <UIButton
                  id="open-email-settings"
                  text
                  size="small"
                  class="text-gray-800"
                  @click="emitter.emit('toggle-email-side-bar')"
                >
                  <Bell03Icon class="h-4 w-4" />
                </UIButton>
              </template>
              {{ t("common.showNotificationsSettings") }}</UITooltip
            >
            <UITooltip v-if="store.quizId" trigger="hover" placement="top">
              <template #trigger>
                <UIButton
                  id="open-email-settings"
                  text
                  size="small"
                  class="text-gray-800"
                  @click="showManageCategories = true"
                >
                  <Grid01Icon class="h-4 w-4" />
                </UIButton>
              </template>
              {{ t("quiz.catgory.manageCategories") }}</UITooltip
            >
          </div>
        </div>
        <div class="flex items-center justify-center">
          <div class="border-right">
            <UITooltip placement="top">
              <template #trigger>
                <UIButton
                  :id="`builder__btn--version`"
                  class="py-4 text-gray-800"
                  quaternary
                  :disabled="!store.builder?.versionHistory?.length"
                  @click="goToVersions"
                >
                  <ClockRewindIcon class="h-5 w-5"
                /></UIButton>
              </template>
              <span>Versions</span>
            </UITooltip>
          </div>

          <div class="flex items-center justify-around">
            <UITooltip trigger="hover" placement="top">
              <template #trigger>
                <UIButton
                  id="btn--undo"
                  text
                  size="small"
                  class="pl-4 pr-2 text-gray-800"
                  :disabled="!(store.historyIndex > 0)"
                  @click="store.undo"
                >
                  <ReverseLeftIcon class="h-5 w-5" />
                </UIButton>
              </template>
              Undo
            </UITooltip>
            <UITooltip trigger="hover" placement="top">
              <template #trigger>
                <UIButton
                  id="btn--redo"
                  text
                  class="pl-2 pr-4 text-gray-800"
                  :disabled="!(store.historyIndex < store.history.length - 1)"
                  @click="store.redo"
                >
                  <ReverseRightIcon class="h-5 w-5" />
                </UIButton>
              </template>
              Redo
            </UITooltip>
          </div>
          <UITooltip trigger="hover" placement="top">
            <template #trigger>
              <UIButton
                id="open-right-bar"
                text
                size="small"
                class="border-left p-3 text-gray-800"
                @click="emitter.emit('toggle-right-side-bar')"
              >
                <Settings04Icon class="h-4 w-4" />
              </UIButton>
            </template>
            {{ t("common.styles") }} & {{ t("common.options") }}
          </UITooltip>
        </div>
      </div>
    </UIToolbarGroup>
  </UIToolbar>
  <ConditionalLogicModal
    :show="showConditionalLogicModal"
    @update:close-modal="showConditionalLogicModal = false"
  />
  <ManageCategoryModal
    v-if="showManageCategories"
    :show="showManageCategories"
    @update:close-modal="showManageCategories = false"
  ></ManageCategoryModal>
</template>

<style scoped>
.secondary-header-toolbar {
  border-color: #e2e2e2 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.hl-toolbar-group {
  padding: 0px !important;
}
</style>
