<script setup lang="ts">
import config from "@/config/index";
import { useAppStore } from "@/store/app";
import { ClipboardIcon, LinkExternal02Icon } from "@gohighlevel/ghl-icons/24/outline";
import {
  UIInput,
  UIModal,
  UIModalContent,
  UIModalHeader,
  UITabPane,
  UITabs,
} from "@gohighlevel/ghl-ui";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const store = useAppStore();

const props = defineProps({
  quizName: {
    type: String,
    default: "",
  },
  modelActive: {
    type: Boolean,
    default: false,
  },
  quizId: String,
  isWhiteLabelled: {
    type: Boolean,
    default: false,
  },
  baseUrl: {
    type: String,
    default: "",
  },
});
const showModalNative = ref(props.modelActive);

watch(
  () => showModalNative.value,
  (newValue) => {
    emit("update:showModel", newValue);
  }
);

const embedUrl = ref("");
const iframeUrl = ref("");
const locationId = ref("");

const emit = defineEmits(["update:showModel", "update:submit", "update:cancel", "update:close"]);

const closeEditor = () => {
  showModalNative.value = false;
  emit("update:close", true);
};
createEmbedUrl();

const copyToClipBoard = () => {
  store.copyToClipBoard(iframeUrl.value);
};
function createEmbedScriptUrl() {
  if (props.isWhiteLabelled && process.env.NODE_ENV !== "beta") {
    return `${props.baseUrl}/js`;
  }
  return config.FORM_EMBED_URL;
}
function createEmbedUrl() {
  if (process.env.NODE_ENV === "development") {
    embedUrl.value = `http://localhost:3344/widget/quiz/${props.quizId}`;
  } else {
    embedUrl.value = props.baseUrl + `/widget/quiz/${props.quizId}`;
  }

  iframeUrl.value = `<iframe src="${
    embedUrl.value
  }" style="border:none;width:100%;" scrolling="no" id="${props.quizId}" title="${
    props.quizName
  }"></iframe>\n<script src="${createEmbedScriptUrl()}/form_embed.js"><\/script>`;
}

const openLinkInNewTab = () => {
  const win = window.open(embedUrl.value, "_blank");
  win.focus();
};
</script>

<template>
  <div>
    <UIModal id="model" v-model:show="showModalNative" :width="680">
      <template #header>
        <UIModalHeader id="modal-header" :title="t('quiz.integrateHeader')" @close="closeEditor">
        </UIModalHeader>
      </template>
      <UIModalContent>
        <div class="tab-wrapper">
          <p class="sub-heading ml-3">
            {{ t("quiz.integrateSubHeader") }}
          </p>

          <section id="tabs" class="mt-3">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                  <UITabs type="segment">
                    <UITabPane name="embed" :tab="t('common.embed')">
                      <div class="form-group">
                        <label for="embed" class="d-flex align-items-center">{{
                          t("common.iframeEmbed")
                        }}</label>
                        <UIInput
                          id="iFrameurl"
                          :model-value="iframeUrl"
                          type="textarea"
                          :rows="5"
                          readonly
                        >
                          <template #suffix
                            ><ClipboardIcon
                              class="copy_clipboard_icon h-5 w-6"
                              @click="copyToClipBoard" /></template
                        ></UIInput>
                      </div>
                    </UITabPane>
                    <UITabPane name="link" :tab="t('common.link')">
                      <div class="form-group">
                        <label for="link" class="d-flex align-items-center"
                          >{{ t("quiz.integrateCopyLinkMessage") }}
                          <OpenLink @click="openLinkInNewTab" />
                        </label>
                        <UIInput id="embedUrl" :model-value="embedUrl" readonly>
                          <template #suffix
                            ><LinkExternal02Icon
                              class="h-5 w-6 cursor-pointer"
                              @click="openLinkInNewTab" /></template
                        ></UIInput>
                      </div>
                    </UITabPane>
                  </UITabs>
                </div>
              </div>
            </div>
          </section>
        </div>
      </UIModalContent>
    </UIModal>
  </div>
</template>

<style scoped>
.copy_clipboard_icon {
  bottom: 2px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}
</style>
