<template>
  <div class="flex flex-col items-start gap-5 self-stretch">
    <div class="flex items-center gap-4 self-stretch">
      <UIButton id="backButton" class="pl-0" :quaternary="true" :text="false" @click="onBack">
        <ArrowLeftIcon class="h-5 w-5"></ArrowLeftIcon>
        {{ t("common.back") }}
      </UIButton>
      <UITextMdMedium>{{ t("common.payment.product.productSettings") }}</UITextMdMedium>
    </div>
    <div class="flex flex-col items-start justify-between gap-6 self-stretch">
      <div class="flex flex-col items-start justify-between gap-2 self-stretch">
        <div class="flex space-x-3">
          <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
            t("common.payment.product.selectProduct")
          }}</span>
        </div>
        <UISelect
          id="select-id"
          :value="product?.name"
          filterable
          :options="productList"
          :placeholder="t('common.payment.product.productSelectPlaceholder')"
          :reset-menu-on-options-change="false"
          :loading="isLoading"
          :disabled="!!currentProduct"
          clearable
          remote
          size="medium"
          @scroll="handleScroll"
          @update:value="updateProduct"
          @search="handleSearch"
        />
        <UITextSmRegular
          >{{
            currentProduct
              ? t("common.payment.product.editThisProduct")
              : t("common.payment.product.createProduct")
          }}
          <a class="cursor-pointer" @click="onProductEdit">{{
            t("common.payment.product.clickHere")
          }}</a>
        </UITextSmRegular>
      </div>

      <div class="flex items-center gap-4 self-stretch">
        <div class="flex flex-col items-start justify-between gap-2 self-stretch">
          <div class="flex space-x-3">
            <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
              t("common.payment.product.show")
            }}</span>
          </div>
          <UICheckbox
            id="showDescription"
            :checked="product?.showDescription"
            @update:checked="onDescriptionUpdate"
            >{{ t("common.payment.product.description") }}</UICheckbox
          >
          <UICheckbox
            id="showImage"
            :checked="product?.showImage"
            @update:checked="onImageUpdate"
            >{{ t("common.payment.product.image") }}</UICheckbox
          >
          <UICheckbox
            id="showQuantity"
            :checked="product?.showQuantity"
            @update:checked="onQuantityUpdate"
            >{{ t("common.payment.product.quantity") }}</UICheckbox
          >
        </div>
      </div>
      <div class="flex items-start self-stretch">
        <UIButton
          v-if="!currentProduct"
          id="addProduct"
          type="primary"
          class="w-[152px]"
          size="medium"
          @click="addproduct"
          >{{ t("common.payment.product.addProduct") }}</UIButton
        >
        <UIButton
          id="productCancel"
          :class="currentProduct ? 'w-[100%]' : 'w-[152px]'"
          size="medium"
          @click="onBack"
          >{{ t("common.cancel") }}</UIButton
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useNotify } from "@/composition/notification";
import config from "@/config";
import { PaymentServices } from "@/service/PaymentServices";
import { useAppStore } from "@/store/app";
import { ArrowLeftIcon } from "@gohighlevel/ghl-icons/24/outline";
import {
  UIButton,
  UICheckbox,
  UISelect,
  UITextMdMedium,
  UITextSmRegular,
} from "@gohighlevel/ghl-ui";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { sendNotification } = useNotify();
const { t } = useI18n();
const store = useAppStore();
const props = defineProps({
  currentProduct: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["onBack"]);
const product = ref(props.currentProduct);
const skip = ref(0);
const productList = ref(store.productList);
const isLoading = ref(false);
const totalProduct = ref(0);

const handleScroll = async (e) => {
  isLoading.value = true;
  if (hasNextPage.value) {
    const currentTarget = e.currentTarget as HTMLElement;
    if (currentTarget.scrollTop + currentTarget.offsetHeight + 20 >= currentTarget.scrollHeight) {
      skip.value += 10;
      await handlePaginationAPICall("", skip.value, 10);
    }
  }

  isLoading.value = false;
};

const handleSearch = async (search) => {
  await handlePaginationAPICall(search, 0, 10, "replace");
};

const handlePaginationAPICall = async (search = "", skip, limit, type = "append") => {
  const response = await PaymentServices.getProductList(store.locationId, search, skip, limit);
  if (type === "append") {
    response.data.products.forEach((el) => {
      const obj = {
        productId: el._id,
        amount: el.prices[0].amount,
        currency: el.prices[0].currency,
        showDescription: true,
        showImage: true,
        showQuantity: true,
        ...el,
      };
      productList.value.push({ label: obj.name, value: obj });
    });
  } else {
    productList.value = response.data.products.map((el) => {
      const obj = {
        productId: el._id,
        amount: el.prices[0].amount,
        currency: el.prices[0].currency,
        showDescription: true,
        showImage: true,
        showQuantity: true,
        ...el,
      };
      return { label: obj.name, value: obj };
    });
  }
};

const onProductEdit = () => {
  if (props.currentProduct) {
    window
      .open(
        `https://${store.company.domain ? store.company.domain : config.GHL_DOMAIN}/v2/location/${
          store.locationId
        }/payments/products/edit/${product.value.productId}`,
        "_blank"
      )
      .focus();
  } else {
    window
      .open(
        `https://${store.company.domain ? store.company.domain : config.GHL_DOMAIN}/v2/location/${
          store.locationId
        }/payments/products`,
        "_blank"
      )
      .focus();
  }
};
const hasNextPage = computed(() => {
  return skip.value < totalProduct.value;
});

onMounted(async () => {
  totalProduct.value = store.totalProduct;
});

const updateProduct = async (value) => {
  product.value = value;
  store.anyUnsavedChanges = true;

  if (!value) {
    await handlePaginationAPICall("", 0, 10);
  }
};

const addproduct = () => {
  const productAlreayAdded = store.addedProductList.filter((item) => {
    return item.productId === product.value.productId;
  });
  if (!props.currentProduct && productAlreayAdded.length > 0) {
    sendNotification({
      title: "Product already added",
      type: "error",
    });
  }
  if (!props.currentProduct && productAlreayAdded.length <= 0) {
    if (store.addedProductList) {
      store.addedProductList.push(product.value);
    } else {
      store.addedProductList = product.value as Array<any>;
    }
  }
  if (props.currentProduct && productAlreayAdded.length === 1) {
    store.addedProductList.forEach((storeProduct) => {
      if (storeProduct.productId === productAlreayAdded[0].productId) {
        storeProduct = product.value;
      }
    });
  }
  store.anyUnsavedChanges = true;
  emit("onBack");
};

const onDescriptionUpdate = (isDescriptionEnabled: boolean) => {
  product.value.showDescription = isDescriptionEnabled;
  store.anyUnsavedChanges = true;
};

const onImageUpdate = (isImageEnabled: boolean) => {
  product.value.showImage = isImageEnabled;
  store.anyUnsavedChanges = true;
};
const onQuantityUpdate = (isQuantityEnabled: boolean) => {
  product.value.showQuantity = isQuantityEnabled;
  store.anyUnsavedChanges = true;
};

const onBack = () => {
  emit("onBack");
};
</script>

<style scoped>
a {
  text-decoration: underline !important;
  color: rgb(13, 110, 253) !important;
}
</style>
