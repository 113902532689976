<template>
  <div class="mt-2.5">
    <div class="mb-2.5 flex items-center text-sm font-medium text-gray-700">File Types</div>
    <UICheckboxGroup
      :value="options"
      class="flex items-center space-x-2 text-sm"
      :disabled="false"
      @update:value="handleUpdate"
    >
      <UICheckbox id="all" class="w-1/4" value="all" label="All" />
      <UICheckbox id="pdf" class="w-1/4" value=".pdf" label="PDF" />
      <UICheckbox id="doc" class="w-1/2" value=".doc" label="DOCX/DOC" />
      <UICheckbox id="doc" class="w-1/4" value=".png" label="PNG" />
      <UICheckbox id="doc" class="w-1/4" value=".gif" label="GIF" />
      <UICheckbox id="doc" class="w-1/2" value=".jpg" label="JPG/JPEG" />
      <UICheckbox id="xls" class="w-1/2" value=".xls" label="XLS/CSV" />
    </UICheckboxGroup>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { UICheckbox, UICheckboxGroup } from "@gohighlevel/ghl-ui";

const props = defineProps({
  options: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(["update:options"]);

const options = ref(props.options?.length ? props.options : ["all"]);

const handleUpdate = (newValue) => {
  if (newValue[newValue?.length - 1] === "all") {
    options.value = ["all"];
  } else if (newValue.includes("all") && newValue?.length > 1) {
    options.value = newValue.filter((option) => option !== "all");
  } else {
    const acceptedFormat = [...newValue];

    if (acceptedFormat.includes(".jpg")) {
      !acceptedFormat.includes(".jpeg") ? acceptedFormat.push(".jpeg") : null;
    } else {
      acceptedFormat.includes(".jpeg")
        ? acceptedFormat.splice(acceptedFormat.indexOf(".jpeg"), 1)
        : null;
    }

    if (acceptedFormat.includes(".doc")) {
      !acceptedFormat.includes(".docx") ? acceptedFormat.push(".docx") : null;
    } else {
      acceptedFormat.includes(".docx")
        ? acceptedFormat.splice(acceptedFormat.indexOf(".docx"), 1)
        : null;
    }

    if (acceptedFormat.includes(".xls")) {
      !acceptedFormat.includes(".csv") ? acceptedFormat.push(".csv") : null;
      !acceptedFormat.includes(".xlsx") ? acceptedFormat.push(".xlsx") : null;
    } else {
      acceptedFormat.includes(".csv")
        ? acceptedFormat.splice(acceptedFormat.indexOf(".csv"), 1)
        : null;
      acceptedFormat.includes(".xlsx")
        ? acceptedFormat.splice(acceptedFormat.indexOf(".xlsx"), 1)
        : null;
    }

    options.value = acceptedFormat;
  }

  emit("update:options", options.value);
};
</script>
