<template>
  <div
    class="flex cursor-default select-none items-center gap-x-4 rounded-lg border p-3"
    :class="disabled ? 'cursor-not-allowed  opacity-50' : `${hoverClass} opacity-100`"
    @click="handleClick"
  >
    <div class="flex h-12 w-12 items-center justify-center rounded-full" :class="iconBgClass">
      <slot></slot>
    </div>
    <span class="w-36 text-base font-medium">{{ buttonText }}</span>
  </div>
</template>

<script setup lang="ts">
import { colorBgVariants, colorBgHoverVariants } from "@/util/constants";

const props = defineProps({
  color: {
    type: String,
    default: "gray",
  },
  buttonText: {
    type: String,
    default: "Button Text",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["onActionButtonClick"]);
const iconBgClass = colorBgVariants[props.color];
const hoverClass = colorBgHoverVariants[props.color];

const handleClick = () => {
  if (!props.disabled) {
    emit("onActionButtonClick");
  }
};
</script>
