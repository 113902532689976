<script setup lang="ts">
import { FormServices } from "@/service/FormServices";
import { SurveyServices } from "@/service/SurveyServices";
import { useAppStore } from "@/store/app";
import { InfoCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UITextSmRegular, UITooltip, UIUpload } from "@gohighlevel/ghl-ui";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps({
  field: {
    type: String,
    default: "bgImage",
  },
  header: {
    type: String,
    default: "Background Image",
  },
});

const fileList = ref([]);
const showLoader = ref(false);
const store = useAppStore();

const fieldValue = computed({
  get() {
    return props.field === "bgImage" ? store.formStyle.bgImage : store.formAction.headerImageSrc;
  },
  set(value) {
    if (props.field === "bgImage") {
      store.formStyle.bgImage = value;
    } else {
      store.formAction.headerImageSrc = value;
    }
    store.anyUnsavedChanges = true;
  },
});

const tooltipValue = computed(() =>
  props.field === "bgImage"
    ? `${t("common.backgroundImageTooltipMessage")}`
    : `${t("common.headerImageTooltipMessage")}`
);

const onFileChange = async (e: any) => {
  showLoader.value = true;
  const newFileList = e.fileList;

  if (newFileList.length === 0) {
    fileList.value = newFileList;
    fieldValue.value = "";
    showLoader.value = false;
    return;
  }

  const file = newFileList[0].file;

  newFileList[0].status = "uploading";

  try {
    const bodyFormData = new FormData();
    bodyFormData.append("locationId", store.locationId);
    bodyFormData.append("file", file);
    bodyFormData.append("type", props.field === "bgImage" ? "background" : "header");
    let image;
    if (store.formId) {
      bodyFormData.append("formId", store.formId);
      image = await FormServices.imageUpload(bodyFormData);
    } else {
      bodyFormData.append("surveyId", store.surveyId);
      image = await SurveyServices.imageUpload(bodyFormData);
    }

    fieldValue.value = `https://images.leadconnectorhq.com/image/f_webp/q_100/r_1000/u_${image.data.imageUrl}`;

    newFileList[0].status = "finished";
    fileList.value = newFileList;
  } catch (error) {
    newFileList[0].status = "error";
    fileList.value = newFileList;
  }

  showLoader.value = false;
};

onMounted(() => {
  if (fieldValue.value) {
    fileList.value = [
      {
        url: fieldValue.value,
        name: "Uploaded File",
        status: "finished",
      },
    ];
  }
});
</script>

<template>
  <div class="flex flex-col items-start gap-2 self-stretch">
    <UITextXsMedium
      >{{ header }}
      <UITooltip :style="{ maxWidth: '300px' }" trigger="hover" placement="top">
        <template #trigger>
          <InfoCircleIcon class="ml-1 h-4 w-4 text-gray-400"></InfoCircleIcon>
        </template>
        {{ tooltipValue }}
      </UITooltip></UITextXsMedium
    >
    <UIUpload
      class="image-upload"
      :file-list="fileList"
      :multiple="false"
      accept="image/*"
      :max="1"
      @change="onFileChange"
    >
      <template #extra>
        <UITextSmRegular> {{ t("common.imageUploadMessage") }} </UITextSmRegular>
      </template>
    </UIUpload>
  </div>
  <!-- <Card class="card-ghl" :header="header" :show-loader="showLoader" :tooltip="tooltipValue">
    <div class="option-card-body">
      <div class="form-group">
        <UIUpload
          class="image-upload"
          :file-list="fileList"
          :multiple="false"
          accept="image/*"
          :max="1"
          @change="onFileChange"
        >
          <template #extra>
            <UITextSmRegular> {{ t("common.imageUploadMessage") }} </UITextSmRegular>
          </template>
        </UIUpload>
      </div>
    </div>
  </Card> -->
</template>

<style>
.image-upload .n-upload-dragger svg {
  height: 1.25rem;
  width: 1.25rem;
  padding: 0;
}
</style>
