{
  "common": {
    "addCustomFields": "Lägg till anpassade fält",
    "addElement": "Lägg till element",
    "addUrlLike": "Lägg till giltiga webbadresser som",
    "address": "Adress",
    "agencyBranding": "Agency Branding",
    "alert": "Varna",
    "alignment": "Inriktning",
    "altText": "Alt text",
    "back": "Tillbaka",
    "background": "Bakgrund",
    "backgroundImage": "Bakgrundsbild",
    "backgroundImageTooltipMessage": "Bakgrundsbilden är avsedd att täcka hela sidan, vilket ger en visuellt tilltalande bakgrund för formuläret. \nFör att se bakgrundsbilden, klicka på alternativet Förhandsgranska.",
    "blur": "Fläck",
    "border": "Gräns",
    "borderType": "Kanttyp",
    "bottom": "Botten",
    "buttonStyle": "Knappstil",
    "cancel": "Annullera",
    "cancelKeep": "Avbryt, behåll",
    "color": "Färg",
    "conditionalLogicAlertOnSubmit": "Villkorlig logik kommer att ha företräde framför meddelandet/omdirigeringen här",
    "contactInfo": "Kontaktinformation",
    "cornerRadius": "Hörnradie",
    "customCSS": "Anpassad CSS",
    "customCSSTooltip": "Anpassad CSS har företräde framför formstil",
    "customFieldSearch": "Sök efter namn",
    "customFields": "Anpassade fält",
    "customized": "Anpassat",
    "delete": "Radera",
    "doNotAddRelativePath": "Lägg inte till relativa vägar som",
    "doNotShowMessage": "Visa inte igen",
    "done": "Gjort",
    "editHTML": "Redigera HTML",
    "embed": "Bädda in",
    "enableCountryPicker": "Aktivera Landsväljaren",
    "enterSourceValue": "Ange källvärde",
    "facebookPixelEvents": "Facebook Pixel Events",
    "facebookPixelID": "Facebook Pixel ID",
    "facebookPixelIDMessage": "Ignorera det här fältet om du planerar att använda det här formuläret/enkäten i en tratt",
    "fieldDescription": "Fältbeskrivning",
    "fieldStyle": "Fältstil",
    "fieldTitle": "Fälttitel",
    "fontColor": "Fontfärg",
    "fontFamily": "Typsnittsfamilj",
    "fontFamilyTooltip": "Builder stödjer alla typer av typsnitt om du inte kan se det i listan. \nVänligen sök i rullgardinsmenyn",
    "fullWidth": "Full bredd",
    "headerImage": "Header Bild",
    "headerImageTooltipMessage": "Rubrikbilden är utformad för att täcka hela formulärets bredd och förblir placerad överst i formuläret. \nFör att se huvudbilden, klicka på alternativet Förhandsgranska.",
    "hidden": "Dold",
    "hiddenValue": "Dolt värde",
    "horizontal": "Horisontell",
    "htmlEditorBodyMessage": "Lägg till din kod här",
    "htmlEditorHeader": "ANPASSAD JAVASCRIPT / HTML",
    "htmlMessage": "Den anpassade HTML-koden går här",
    "iframeEmbed": "Ifrmae Bädda in",
    "imageHeight": "Bildhöjd",
    "imageUploadMessage": "Klicka eller dra en bildfil till det här området för att ladda upp",
    "imageWidth": "Bildbredd",
    "inputStyle": "Inmatningsstil",
    "integrate": "Integrera",
    "label": "Märka",
    "layout": "Layout",
    "left": "Vänster",
    "link": "Länk",
    "logic": "Logik",
    "miscellaneous": "Diverse",
    "onSubmit": "På Skicka",
    "onpageView": "På sidvy",
    "options": "alternativ",
    "or": "eller",
    "padding": "Stoppning",
    "personalInfo": "Personlig information",
    "placeholder": "Platshållare",
    "placeholderFacebookPixelIdInput": "Ange Facebook Pixel ID",
    "pleaseInput": "Vänligen ange",
    "preview": "Förhandsvisning",
    "proceed": "Fortsätt",
    "queryKey": "Fråga nyckel",
    "querykeyTooltipMessage": "Frågenyckeln som kan användas som en URL-parameter för att fylla i detta fält",
    "quickAdd": "Snabb tillägg",
    "required": "Nödvändig",
    "right": "Höger",
    "save": "Spara",
    "saving": "Sparande",
    "shadow": "Skugga",
    "shortLabel": "Kort etikett",
    "showLabel": "Visa etikett",
    "size": "Storlek",
    "spread": "Sprida",
    "stickyContact": "Klibbig kontakt",
    "stickyContactMessage": "Sticky contact kommer att autofylla kontaktinformation som tidigare angetts så att du inte behöver ange den två gånger.",
    "styles": "Stilar",
    "subText": "Undertext",
    "submit": "Skicka in",
    "text": "Text",
    "textStyle": "Textstil",
    "themes": {
      "alertThemeMessage": "Att byta teman kommer att resultera i att alla nuvarande stiländringar går förlorade.",
      "removeTheme": "Ta bort tema",
      "themes": "teman",
      "useTheme": "Använd tema"
    },
    "top": "Topp",
    "useGDPRCompliantFont": "Använd GDPR-kompatibelt teckensnitt",
    "vertical": "Vertikal",
    "weight": "Vikt",
    "width": "Bredd",
    "yesSave": "Ja, spara",
    "box": "Låda",
    "line": "Linje",
    "message": "Meddelande",
    "openUrl": "Öppna URL",
    "borderTypeList": {
      "dotted": "Prickad",
      "none": "Ingen",
      "solid": "Fast",
      "dashed": "Streckad"
    },
    "button": "Knapp",
    "enableTimezone": "Aktivera tidszon",
    "emailNotification": "E-postmeddelande",
    "emailNotificationSubheader": "Få ett e-postmeddelande när någon fyller i ditt formulär",
    "subject": "Ämne",
    "emailTo": "E-posta (till)",
    "senderName": "Avsändarens namn",
    "senderNameWarning": "Ditt (underkontonamn) kommer att läggas till om det lämnas tomt",
    "emailTooltip": "Den första e-postadressen är avsedd för \"Till\"-fältet. \nEventuella efterföljande e-postadresser kommer att inkluderas i \"Cc\"-fältet.",
    "emailError": "Minst en e-postadress krävs",
    "clear": "Klar",
    "showNotificationsSettings": "Visa meddelandeinställningar",
    "emailServicePaid": "Den här funktionen är en betaltjänst och kommer att medföra avgifter baserat på din användning.",
    "integration": "Integration",
    "payment": {
      "connect": "Ansluta",
      "connected": "Ansluten",
      "footerInfo": "Sidfot info",
      "includeOtherAmount": "Inkludera annat belopp",
      "live": "leva",
      "paymentFields": "Betalningsfält",
      "selectCurrency": "Välj valuta",
      "status": "Status",
      "suggestAnAmount": "Föreslå ett belopp",
      "test": "Testa",
      "liveModeOn": "Live-läge",
      "customAmount": "Anpassat belopp",
      "paymentType": "Betalnings typ",
      "product": {
        "addProduct": "Lägg till produkt",
        "clickHere": "Klicka här",
        "description": "Beskrivning",
        "editThisProduct": "För att redigera denna produkt",
        "image": "Bild",
        "layoutSettings": "Layout inställningar",
        "maxProductError": "Max 20 produkter är tillåtna i ett formulär",
        "productSelectPlaceholder": "Välj en produkt",
        "productSettings": "Produktinställningar",
        "selectProduct": "Välj Produkt",
        "show": "Show",
        "singleColumn": "Enkel kolumn",
        "threeColumn": "Tre kolumner",
        "twoColumn": "Två kolumner",
        "orderConfirmationWarning": "En orderbekräftelsesida kommer att visas när betalningen har lyckats",
        "orderConfirmation": "Orderbekräftelse",
        "createProduct": "Att skapa en produkt",
        "quantity": "Kvantitet"
      },
      "sellProducts": "Sälj produkter",
      "generalSettings": "Allmänna fält",
      "tagActiveBGColor": "Active Tag Color",
      "collectPayment": "Samla betalning",
      "payments": "Betalningar",
      "coupons": {
        "couponAlignment": "Kupongjustering",
        "coupons": "Kuponger",
        "enableCoupon": "Aktivera kupong",
        "fullWidth": "Full bredd",
        "left": "Vänster",
        "right": "Höger",
        "tooltipMessage": "Ställ in positionen för kuponginmatning från nedanstående typer"
      }
    },
    "autoResponder": "Autosvarare",
    "autoResponderSubheader": "Skicka ett automatiskt e-postmeddelande till personen som skickar in formuläret (e-postmeddelandet innehåller en kopia av informationen som de angett i formuläret)",
    "replyToEmail": "Svara på e-post",
    "open": "Öppen",
    "won": "Vann",
    "lost": "Förlorat",
    "abandon": "Överge",
    "selectAPipeline": "Välj en pipeline",
    "stage": "Skede",
    "status": "Status",
    "opportunityValue": "Möjlighetsvärde",
    "createNewPipeline": "Skapa ny pipeline",
    "editOpportunitySettings": "Redigera inställningar för möjlighet",
    "contact": "Kontakt",
    "opportunity": "Möjlighet",
    "add": "Lägg till",
    "more": "Mer",
    "opportunityHeading": "Var vill du att din möjlighet ska bli uppdaterad?",
    "date": {
      "dateFormat": "Datumformat",
      "dateFormatTooltipMessage": "Välj ett datumformat. \nD står för dag, M för månader och Y för år",
      "dateSeparator": "Datumavskiljare",
      "dateSeparatorTooltipMessage": "Välj en avgränsare som ska separera dag, månad och år i formatet som valts i Datumformat",
      "dateSettings": "Datuminställningar",
      "disablePicker": "Inaktivera väljaren"
    },
    "restoreVersion": "Återställ version",
    "versionHistory": "Versionshistorik",
    "errorMessage": "Fel, försök igen",
    "version": "Version:",
    "currentVersion": "Aktuell version",
    "conditionalLogicAlertOnShowHide": "Villkorlig logik kommer att ha företräde framför de dolda inställningarna här",
    "validateEmailHeader": "Validera email",
    "validateEmailDescription": "Knappen blir synlig när användaren anger sin e-post. \nDen här funktionen är utformad för att hjälpa dig generera potentiella potentiella kunder.",
    "validatePhoneHeader": "Validera telefon",
    "validatePhoneDescription": "Knappen blir synlig när användaren går in i sin telefon. \nDen här funktionen är utformad för att hjälpa dig generera potentiella potentiella kunder.",
    "validatePhoneWarning": "För att aktivera SMS OTP-verifiering, se till att ditt telefonnummer är inställt i dina platsinställningar",
    "style": {
      "formLayoutType": "Formtyp",
      "oneColumn": "En kolumn",
      "singleLine": "En kö",
      "twoColumn": "Två kolumner",
      "fieldWidth": "Fältbredd",
      "fieldSpacing": "Fältavstånd",
      "advanceSettings": "Avancerade inställningar",
      "borderColor": "Gräns ​​färg",
      "borderStyle": "Gränsstil",
      "borderWidth": "Gränsbredd",
      "color": "Färg",
      "colorsAndBackground": "Färger",
      "fontColor": "Fontfärg",
      "fontSize": "Textstorlek",
      "fontWeight": "Teckensnittsvikt",
      "inputBackground": "Ingångsbakgrund",
      "inputField": "Inmatningsområde",
      "labelColor": "Etikettfärg",
      "layout": "Layout",
      "placeholderColor": "Platshållarfärg",
      "shortLabelColor": "Kort etikettfärg",
      "labelAlignment": {
        "labelAlignment": "Etikettjustering",
        "labelWidth": "Etikettbredd"
      }
    },
    "replyToTooltip": "Anpassa standardsvars-till-e-postadressen genom att använda {'{'}{'{'}contact.email{'}'}{'}'} för att aktivera svar till kontakten som skickar formuläret.",
    "content": "Innehåll",
    "calculations": "Beräkningar",
    "uploadOptions": "Uppladdningsalternativ",
    "messageStyling": "Message Styling",
    "opportunitySettingsWarning": "Vänligen ställ in möjlighetsinställningar genom att klicka på knappen Redigera möjlighetsinställningar.",
    "createContactOnPartialSubmission": "Skapa kontakt vid partiell inlämning",
    "howItWorks": "Hur fungerar det?",
    "createContactOnPartialSubmissionDescription": "Avsluta bilden och klicka på \"Nästa\". \nEn ny kontakt skapas och lagras automatiskt.",
    "swapWarningMessageSurvey": "Redigerings- eller bytesalternativ påverkar detta anpassade fält på alla platser där fältet används. \nDen kan också återställa villkorlig logik.",
    "swapWarningMessageForm": "Redigerings- eller bytesalternativ påverkar detta anpassade fält på alla platser där fältet används.",
    "addOption": "Lägg till alternativ",
    "calculationValue": "Beräkningsvärde",
    "enableCalculations": "Aktivera beräkningar",
    "optionLabel": "Alternativetikett",
    "addressSettings": {
      "addressfields": "Adressfält",
      "autoComplete": "Autofullständig adress",
      "autoCompleteTooltip": "Ger möjlighet att söka och automatiskt fylla i adress. \nDenna funktion är avgiftsbelagd.",
      "placeholderSearch": "Sök efter en adress"
    }
  },
  "form": {
    "activateOn": "Aktivera på",
    "activationOptions": "Aktiveringsalternativ",
    "addConditionalLogic": "Lägg till villkorlig logik",
    "addFormElement": "Lägg till formulärelement",
    "allowMinimize": "Tillåt minimera",
    "alwaysActivated": "Alltid aktiverad",
    "alwaysShow": "Visa alltid",
    "conditonalLogic": {
      "addNewCondition": "Lägg till nytt villkor",
      "alertConditionDelete": "Är du säker på att du vill ta bort det här villkoret?",
      "conditionExecutedMessage": "Villkor kommer att utföras från top-down order.",
      "conditions": "Betingelser",
      "deleteCondition": "Ta bort villkor",
      "displayCustomMessage": "Visa anpassat meddelande",
      "disqualifyLead": "Diskvalificera ledningen",
      "freeRoamMessage": "Du är fri att roama utan reglerna - åtminstone för nu",
      "noExistingCondition": "Inga befintliga villkor ännu",
      "openBelowUrl": "Öppna nedan URL",
      "redirectToUrl": "Omdirigera till URL",
      "showCustomMessage": "Visa anpassat meddelande",
      "showHideFields": "Visa/dölj fält",
      "allOperationOptions": {
        "after": "Efter",
        "before": "Innan",
        "contains": "Innehåller",
        "endsWith": "Slutar med",
        "greaterThan": "Större än",
        "isEmpty": "Är tom",
        "isEqualTo": "Är lika med",
        "isFilled": "Är fylld",
        "isNotEqualTo": "Är inte lika med",
        "lessThan": "Mindre än",
        "startsWith": "Börjar med"
      },
      "logic": {
        "and": "Och",
        "if": "Om",
        "or": "Eller",
        "then": "Sedan"
      },
      "phoneAlertMessage": "För att söka efter telefonnummer med en landskod måste du uttryckligen inkludera symbolen '+' i din sökfråga.",
      "deleteAlertMessage": "Är du säker på att du vill ta bort det här fältet? \nVillkoret för detta fält kommer också att tas bort.",
      "deleteField": "Ta bort fält"
    },
    "minimizedTitlePlaceholder": "Minimerad titel",
    "copyEmbedCode": "Kopiera inbäddningskod",
    "deactivateAfterShowing": "Inaktivera efter visning",
    "deactivationOptions": "Inaktiveringsalternativ",
    "deactiveOnceLeadCollected": "Inaktivera när bly har samlats in",
    "embedlayoutType": "Bädda in layouttyp",
    "embedlayoutTypeSubMessage": "Välj en layout att bädda in i en tratt eller en extern webbplats",
    "formElement": "Formelement",
    "formName": "Form Namn",
    "formSettings": "Formulärinställningar",
    "formStyle": "Formstil",
    "fullScreenMode": "Fullskärmsläge",
    "fullScreenModetooltip": "Om du slår på det kommer formuläret att expandera över hela bredden av dess behållare, sträcker sig till kanten av inbäddningsområdet samtidigt som det hålls fast vid formulärets bredd.",
    "inline": "I kö",
    "inlineDescription": "Ett infogat formulär visas tillsammans med sidans innehåll som ett inbyggt element. \nDen ligger inte över och kan inte stängas eller minimeras.",
    "inlineForm": "Inline-formulär",
    "inlineHoverDescription": "Ett infogat formulär visas tillsammans med sidans innehåll som ett inbyggt element. \nDen ligger inte över och kan inte stängas eller minimeras.",
    "integrateForm": "Integrera formulär",
    "neverDeactivate": "Avaktivera aldrig",
    "onFormSubmission": "Vid inlämning av formulär",
    "politeSlideIn": "Artig glid-in",
    "politeSlideInDescription": "En artig slide-in visas längst ner på skärmen och försvinner när den stängs",
    "politeSlideInHoverDescription": "Visas på skärmens undersida och försvinner när den stängs",
    "popup": "Dyka upp",
    "popupDescription": "Ett popup-fönster visar formen som en överlagring inuti en ljuslåda. \nDen kan inte minimeras utan stängas.",
    "popupHoverDescription": "Ett popup-fönster visar formen som en överlagring inuti en ljuslåda. \nDen kan inte minimeras utan stängas.",
    "seconds": "sekunder",
    "showAfter": "Visa efter",
    "showOnScrolling": "Visa vid rullning",
    "stickySidebar": "Klibbig sidofält",
    "stickySidebarDescription": "Ett Sticky sidofält visas på sidan av skärmen och kan minimeras till en flik som fastnar vid sidan av fönstret",
    "stickySidebarHoverDescription": "Visas på sidan av skärmen och kan minimeras till en flik som fastnar på sidan av fönstret",
    "thirdPartyScriptAlertMessage": "Tredjepartsskript inuti HTML-blocket kan ge kraftfull funktionalitet, men de medför också risker för integritet, säkerhet, prestanda och sidbeteende. \nDärför rekommenderar vi att du granskar innehållet i det här skriptet innan du sparar det i formuläret.",
    "times": "gånger",
    "triggerType": "Triggertyp",
    "typeOfBuilder": "Form",
    "visit": "besök",
    "dragYourElementFromLeft": "Dra dina element hit från vänster",
    "enableTimezoneForm": "Aktivering av detta alternativ kommer att fånga tidszonsinformationen för den person som skickar in formuläret",
    "formNotification": "Formuläravisering",
    "copyFormLink": "Kopiera formulärlänken",
    "openFormLink": "Öppna formulärlänken",
    "unsavedAlertMessage": "Formuläret har osparat arbete. \nÄr du säker på att du vill lämna och kassera allt osparat arbete?",
    "style": {
      "formPadding": "Formvaddering"
    }
  },
  "survey": {
    "addSlide": "Lägg till bild",
    "addSurveyElement": "Lägg till undersökningselement",
    "backButton": "Tillbakaknapp",
    "backButtonTooltipMessage": "Tillbaka-knappen hjälper användaren att flytta föregående bild",
    "delete": "Radera",
    "disableAnimation": "Inaktivera animering",
    "disableAnimationTooltipMessage": "Om du aktiverar detta inaktiveras bildövergångsanimeringen",
    "disableAutoNavigation": "Inaktivera automatisk navigering",
    "disableAutoNavigationTooltipMessage": "Om den är aktiverad kommer undersökningen inte automatiskt att gå till nästa bild",
    "disqualifyAfterSubmit": "Diskvalificera efter inlämning",
    "disqualifyImmediately": "Diskvalificera omedelbart",
    "editFooter": "Redigera sidfot",
    "footer": {
      "backgroundFill": "Bakgrundsfyllning",
      "buttonType": "Knapptyp",
      "buttons": "Knappar",
      "enableNewFooter": "Aktivera ny sidfot",
      "enableProgressBar": "Aktivera förloppsindikator",
      "fillComplete": "Fyll komplett",
      "fillInactive": "Fyll inaktiv",
      "fillNext": "Fyll (nästa)",
      "fillPrevious": "Fyll (föregående)",
      "fillSubmit": "Fyll (skicka)",
      "footer": "Sidfot",
      "footerHeight": "Sidfotshöjd",
      "nextText": "Nästa (text)",
      "position": "Placera",
      "previousText": "Föregående (text)",
      "stickToSurvey": "Håll dig till Survey",
      "submitText": "Skicka (text)",
      "textColor": "Text färg",
      "footerType": "Sidfotstyp",
      "stickToCard": "Håll dig till kortet",
      "stickToPage": "Håll dig till sidan"
    },
    "footerHTML": "Sidfot HTML",
    "footerHTMLMessage": "Du kan skriva anpassad CSS (med stiltagg) och HTML i samma ruta",
    "integrateCopyLinkMessage": "Kopiera länken nedan och dela den enkelt var du vill.",
    "integrateHeader": "Integrera undersökning",
    "integrateSubHeader": "Använd följande alternativ för att integrera din anpassade undersökning på din webbplats",
    "jumpTo": "Hoppa till",
    "onSurveySubmission": "Om enkätinlämning",
    "oneQuestionAtTime": "En fråga i taget",
    "progressBar": "Framstegsindikator",
    "progressBarTooltipMessage": "Om du stänger av detta kommer förloppsindikatorn att tas bort från undersökningen",
    "scrollToTop": "Scrolla till toppen",
    "scrollToTopTooltipMessage": "Om den är aktiverad kommer undersökningen att rulla till toppen på nästa bild",
    "settings": "inställningar",
    "slideConflictAlertMessage": "Den här bilden innehåller flera frågor som har en logik inställd på dem. \nDetta kan orsaka oavsiktligt beteende",
    "slideDeleteAlertMessage": "Är du säker på att du vill ta bort den här bilden?",
    "slideDeleteBetweenAlertMessage": "Detta kommer att ordna om de andra bilderna, är du säker på att du vill ta bort den här bilden?",
    "slideName": "Bildens namn",
    "slidePosition": "Glidläge",
    "surveyElement": "Enkätelement",
    "surveyName": "Undersökningens namn",
    "surveySettings": "Enkätinställningar",
    "survyeStyle": "Undersökningsstil",
    "thirdPartyScriptAlertMessage": "Tredjepartsskript inuti HTML-blocket kan ge kraftfull funktionalitet, men de medför också risker för integritet, säkerhet, prestanda och sidbeteende. \nDärför rekommenderar vi att du granskar innehållet i det här skriptet innan du sparar det i undersökningen.",
    "typeOfBuilder": "Undersökning",
    "enableTimezoneSurvey": "Aktivering av detta alternativ kommer att fånga tidszonsinformationen för individen som skickar in undersökningen",
    "surveyNotfication": "Undersökningsmeddelande",
    "unsavedAlertMessage": "Undersökningen har osparat arbete. \nÄr du säker på att du vill lämna och kassera allt osparat arbete?",
    "style": {
      "surveyPadding": "Undersökningsstoppning"
    }
  }
}
