<template>
  <div class="sub-settings-header">
    <UITextMdMedium>{{ t("common.payment.generalSettings") }}</UITextMdMedium>
  </div>
  <div class="flex flex-col items-start gap-3 self-stretch">
    <div class="flex flex-col items-start justify-between self-stretch">
      <div class="flex space-x-3">
        <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
          t("common.label")
        }}</span>
      </div>
      <UIInput
        id="label"
        v-model="store.elements[props.selectedIndex].label"
        size="medium"
        @update:model-value="store.anyUnsavedChanges = true"
        @blur="store.addToHistory()"
      />
    </div>
    <div
      v-if="store.payment.paymentType === 'donation'"
      class="flex flex-col items-start justify-between self-stretch"
    >
      <div class="flex space-x-3">
        <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
          t("common.shortLabel")
        }}</span>
      </div>
      <UIInput
        id="shortLabel"
        v-model="store.elements[props.selectedIndex].shortLabel"
        size="medium"
        class="standard-input-ghl"
        @update:model-value="store.anyUnsavedChanges = true"
        @blur="store.addToHistory()"
      ></UIInput>
    </div>
  </div>
  <UICheckbox
    id="required"
    :checked="store.payment.required"
    @update:checked="onRequiredChange"
    @blur="store.addToHistory()"
    >{{ t("common.required") }}</UICheckbox
  >
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UICheckbox, UIInput, UITextMdMedium } from "@gohighlevel/ghl-ui";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useAppStore();

const props = defineProps({
  selectedIndex: {
    type: Number,
    required: true,
  },
});

const onRequiredChange = (isRequired: boolean) => {
  store.payment.required = isRequired;
  store.elements[props.selectedIndex].required = isRequired;
  store.anyUnsavedChanges = true;
};
</script>
