<script setup lang="ts">
import FormBuilderEmailSidebar from "@/components/common/BuilderEmailSidebar.vue";
import FormBuilderLeftSidebar from "@/components/common/BuilderLeftSidebar.vue";
import HeaderVue from "@/components/common/Header.vue";
import SecondaryHeader from "@/components/common/SecondaryHeader.vue";
import FormbuilderSidebar from "@/components/common/Sidebar/RightSidebar.vue";
import VersionHistory from "@/components/common/VersionHistory.vue";
import FormbuilderPreview from "@/components/forms/FormbuilderPreview.vue";
import { useAppStore } from "@/store/app";
import { PRODUCT_TYPE } from "@/util/constants";
import { UISpin } from "@gohighlevel/ghl-ui";
import { onBeforeMount, onBeforeUnmount } from "vue";

const store = useAppStore();

// const preventNav = (event) => {
//   if (!store.anyUnsavedChanges) {
//     return;
//   }
//   event.preventDefault();
//   event.returnValue = "";
// };
// onBeforeMount(() => {
//   window.addEventListener("beforeunload", preventNav);
// });

// onBeforeUnmount(() => {
//   window.removeEventListener("beforeunload", preventNav);
// });
</script>

<template>
  <div v-if="!store.showVersions">
    <section class="builder-container">
      <HeaderVue id="builder-header" />
      <SecondaryHeader id="secondary-builder-header" />
      <div v-if="store.processing" class="loader-container">
        <UISpin />
      </div>
      <div v-else id="builder-inner-container" class="h-full">
        <FormBuilderLeftSidebar :builder-type="PRODUCT_TYPE.FORM" />
        <FormBuilderEmailSidebar :builder-type="PRODUCT_TYPE.FORM" />
        <FormbuilderPreview />
        <FormbuilderSidebar />
      </div>
    </section>
  </div>
  <div v-else><VersionHistory /></div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.builder-container {
  height: 100vh;
  overflow: hidden;
}
#builder-inner-container {
  position: relative;
  top: 0 !important;
  height: inherit;
}
#builder-header {
  position: fixed;
  width: 100%;
  z-index: 1000;
}
.sidebar-right {
  position: fixed;
  right: 0;
  top: 52px;
  width: 100%;
  max-width: 300px;
  background-color: #ffffff;
  border: 1px solid #dee2e6 !important;
  height: 100%;
}
.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
}
body {
  min-height: 100%;
  height: 100%;
  background-color: #f3f4f6 !important;
}

.label-maker {
  font-size: 0.8rem;
}

.text-gray-700 {
  --tw-text-opacity: 0.8;
}
.line-form-style {
  background: #ffffff !important;
  border: none !important;
  border-bottom: 1px solid #000000;
  border-radius: 0 !important;
}
</style>
