<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { addressFields } from "@/util/methods";
import { UICollapse, UICollapseItem } from "@gohighlevel/ghl-ui";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import AddressAutoComplete from "./AddressAutoComplete.vue";
import AddressChildCardSettings from "./AddressChildCardSettings.vue";
import ChildFieldCheckbox from "./ChildFieldCheckbox.vue";

const store = useAppStore();
const { t } = useI18n();

const props = defineProps({
  address: {
    type: Object,
    required: true,
  },
});

const childrensOrder = ["address", "city", "state", "country", "postal_code"];

// Sort the children based on the order defined in childrensOrder
const sortedChildren = computed(() => {
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  return store.addressSettings.children.sort(
    (a, b) => childrensOrder.indexOf(a.tag) - childrensOrder.indexOf(b.tag)
  );
});
</script>

<template>
  <div class="font-inter text-xs font-medium leading-5 text-gray-700">
    {{ t("common.addressSettings.addressfields") }}
  </div>
  <div class="mb-8 mt-2 flex flex-wrap gap-2">
    <template v-for="element in addressFields" :key="element.tag">
      <ChildFieldCheckbox :element="element" />
    </template>
  </div>
  <UICollapse
    id="custom-field-settings"
    :arrow-placement="'left'"
    :default-expanded-names="['General Settings', 'Address', 'Auto Complete Address']"
  >
    <UICollapseItem
      v-for="(eachElement, index) in sortedChildren"
      :id="eachElement.tag"
      :key="eachElement.tag"
      :title="eachElement.title"
      :name="eachElement.title"
    >
      <AddressChildCardSettings :key="eachElement.tag" :index="index" />
    </UICollapseItem>
    <UICollapseItem id="address-auto-complete" name="Auto Complete Address">
      <template #header>
        <div class="font-inter flex text-sm font-medium leading-5">
          <div>Auto Complete Address</div>
          <div class="ml-1 flex items-center rounded-full bg-blue-100 px-2.5"><div>New</div></div>
        </div>
      </template>
      <AddressAutoComplete />
    </UICollapseItem>
  </UICollapse>
</template>
