<template>
  <div id="borderStyle" class="flex flex-col items-start gap-2 self-stretch">
    <UITextXsMedium>{{ label }} </UITextXsMedium>
    <div class="flex items-start justify-center gap-2 self-stretch">
      <div
        v-for="border in BORDER_STYLES"
        :key="border.key"
        class="border-icon-container cursor-pointer"
        :class="currentBorderStyle === border.key ? 'active-border-style' : ''"
        @click="changeBorderStyle(border.key)"
      >
        <UITooltip trigger="hover" placement="top">
          <template #trigger>
            <component :is="border.icon" class="h-4 w-4"></component>
          </template>
          {{ border.name }}
        </UITooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { BORDER_STYLES } from "@/util/constants";
import { UITextXsMedium, UITooltip } from "@gohighlevel/ghl-ui";
import { ref } from "vue";

const store = useAppStore();

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  borderType: {
    type: String,
    default: "none",
  },
});

const currentBorderStyle = ref(props.borderType);

const emits = defineEmits(["update:border-type"]);
const changeBorderStyle = (type: string) => {
  currentBorderStyle.value = type;
  emits("update:border-type", type);
};
</script>

<style scoped>
.border-icon-container {
  width: 74px;
  display: flex;
  padding: 9px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.active-border-style {
  border: 2px solid #155eef !important;
  background: #eff4ff !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
</style>
