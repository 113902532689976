<template>
  <div class="grid grid-cols-2 gap-3">
    <ColorInput
      :label="t('common.fontColor')"
      :color="store?.fieldStyle?.fontColor"
      @update:color="handleFieldFontColor"
    />
    <ColorInput
      :label="t('common.payment.tagActiveBGColor')"
      :color="store?.fieldStyle?.activeTagBgColor"
      @update:color="handleActiveTagBGColor"
    />
    <PixelNumber
      :label="t('common.style.borderWidth')"
      :value="parseFloat(store?.fieldStyle?.border?.border)"
      @update:value="handleFieldBorder"
    />
    <ColorInput
      :label="t('common.style.borderColor')"
      :color="store?.fieldStyle?.border?.color"
      @update:color="handleFieldBorderColor"
    />
    <PixelNumber
      :label="t('common.cornerRadius')"
      :value="parseFloat(store?.fieldStyle?.border?.radius)"
      @update:value="handleFieldCornerRdius"
    />
    <PixelNumber
      :label="t('common.width')"
      :value="parseFloat(store?.fieldStyle?.width) || ''"
      @update:value="handleFieldWidth"
    />
  </div>

  <BorderStyle
    :label="t('common.style.borderStyle')"
    :border-type="store?.fieldStyle?.border?.type"
    @update:border-type="handleFieldBorderType"
  ></BorderStyle>
  <PaddingInput
    :label="t('common.padding')"
    :padding="store.fieldStyle.padding"
    @update-padding="handleFieldPadding"
  ></PaddingInput>
  <Shadow
    :label="t('common.shadow')"
    :shadow="store?.fieldStyle?.shadow"
    @update:color="handleFieldShadowColor"
    @update:horizontal="handleFieldShadowHorizontal"
    @update:vertical="handleFieldShadowVertical"
    @update:blur="handleFieldShadowBlur"
    @update:spread="handleFieldShadowSpread"
  ></Shadow>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { useI18n } from "vue-i18n";
import BorderStyle from "../Elements/BorderStyle.vue";
import ColorInput from "../Elements/ColorInput.vue";
import PaddingInput from "../Elements/PaddingInput.vue";
import PixelNumber from "../Elements/PixelNumber.vue";
import Shadow from "../Elements/Shadow.vue";

const { t } = useI18n();
const store = useAppStore();

const handleFieldFontColor = (val: string) => {
  store.fieldStyle.fontColor = val;
  store.anyUnsavedChanges = true;
};
const handleActiveTagBGColor = (val: string) => {
  store.fieldStyle.activeTagBgColor = val;
  store.anyUnsavedChanges = true;
};

const handleFieldBorder = (val: string) => {
  store.fieldStyle.border.border = val;
  store.anyUnsavedChanges = true;
};
const handleFieldBorderColor = (val: string) => {
  store.fieldStyle.border.color = val;
  store.anyUnsavedChanges = true;
};
const handleFieldBorderType = (val: string) => {
  store.fieldStyle.border.type = val;
  store.anyUnsavedChanges = true;
};
const handleFieldCornerRdius = (val: number) => {
  store.fieldStyle.border.radius = val;
  store.anyUnsavedChanges = true;
};
const handleFieldWidth = (val: number) => {
  store.fieldStyle.width = val;
  store.anyUnsavedChanges = true;
};

//Field Shadow
const handleFieldShadowHorizontal = (val: number) => {
  store.fieldStyle.shadow.horizontal = val;
  store.anyUnsavedChanges = true;
};
const handleFieldShadowVertical = (val: number) => {
  store.fieldStyle.shadow.vertical = val;
  store.anyUnsavedChanges = true;
};
const handleFieldShadowBlur = (val: number) => {
  store.fieldStyle.shadow.blur = val;
  store.anyUnsavedChanges = true;
};
const handleFieldShadowSpread = (val: number) => {
  store.fieldStyle.shadow.spread = val;
  store.anyUnsavedChanges = true;
};
const handleFieldShadowColor = (val: string) => {
  store.fieldStyle.shadow.color = val;
  store.anyUnsavedChanges = true;
};
const handleFieldPadding = (side: string, value: number) => {
  store.fieldStyle.padding[side] = value;
  store.anyUnsavedChanges = true;
};
</script>
