const countryCurrency = {
  US: "$",
  CA: "C$",
  DE: "€",
  AU: "A$",
  GB: "£",
  KE: "Sh",
  IE: "€",
  PH: "₱",
  IT: "€",
  HK: "$",
  AF: "Af",
  AX: "€",
  AL: "L",
  DZ: "د.ج",
  AS: "$",
  AD: "€",
  AO: "Kz",
  AI: "$",
  AQ: "$",
  AG: "$",
  AR: "$",
  AM: "Դ",
  AW: "ƒ",
  AT: "€",
  AZ: "ман",
  BS: "$",
  BH: "ب.د",
  BD: "৳",
  BB: "$",
  BY: "Br",
  BE: "€",
  BZ: "$",
  BJ: "₣",
  BM: "$",
  BT: "₹",
  BO: "Bs.",
  BA: "КМ",
  BW: "P",
  BV: "$",
  BR: "R$",
  IO: "£",
  BN: "$",
  BG: "лв",
  BF: "₣",
  BI: "₣",
  KH: "៛",
  CM: "₣",
  CV: "$",
  KY: "$",
  CF: "₣",
  TD: "₣",
  CL: "$",
  CN: "¥",
  CX: "$",
  CC: "$",
  CO: "$",
  KM: "$",
  CG: "₣",
  CD: "₣",
  CK: "$",
  CR: "₡",
  CI: "₣",
  HR: "€",
  CU: "¢",
  CY: "€",
  CZ: "Kč",
  DK: "kr",
  DJ: "₣",
  DM: "$",
  DO: "$",
  EC: "$",
  EG: "£",
  SV: "$",
  GQ: "₣",
  ER: "Nfk",
  EE: "€",
  ET: "$",
  FK: "£",
  FO: "$",
  FJ: "$",
  FI: "€",
  FR: "€",
  GF: "₣",
  PF: "₣",
  TF: "$",
  GA: "₣",
  GM: "D",
  GE: "ლ",
  GH: "₵",
  GI: "£",
  GR: "€",
  GL: "kr",
  GD: "$",
  GP: "$",
  GU: "$",
  GT: "Q",
  GG: "$",
  GN: "₣",
  GW: "₣",
  GY: "$",
  HT: "$",
  HM: "$",
  VA: "€",
  HN: "L",
  HU: "Ft",
  IS: "Kr",
  IN: "₹",
  ID: "Rp",
  IR: "﷼",
  IQ: "ع.د",
  IM: "$",
  IL: "₪",
  JM: "$",
  JP: "¥",
  JE: "£",
  JO: "د.ا",
  KZ: "〒",
  KI: "$",
  KP: "₩",
  KR: "₩",
  KW: "د.ك",
  KG: "Som",
  LA: "$",
  LV: "€",
  LB: "ل.ل",
  LS: "L",
  LR: "$",
  LY: "ل.د",
  LI: "$",
  LT: "€",
  LU: "€",
  MO: "P",
  MK: "ден",
  MG: "$",
  MW: "MK",
  MY: "RM",
  MV: "ރ.",
  ML: "₣",
  MT: "€",
  MH: "$",
  MQ: "$",
  MR: "UM",
  MU: "₨",
  YT: "$",
  MX: "$",
  FM: "$",
  MD: "L",
  MC: "€",
  MN: "₮",
  ME: "€",
  MS: "$",
  MA: "د.م.",
  MZ: "MTn",
  MM: "K",
  NA: "$",
  NR: "$",
  NP: "Rs",
  NL: "€",
  AN: "€",
  NC: "₣",
  NZ: "$",
  NI: "C$",
  NE: "₣",
  NG: "₦",
  NU: "$",
  NF: "$",
  MP: "$",
  NO: "kr",
  OM: "ر.ع.",
  PK: "Rs",
  PW: "$",
  PS: "₪",
  PA: "B/.",
  PG: "K",
  PY: "₲",
  PE: "S/.",
  PN: "$",
  PL: "zł",
  PT: "€",
  PR: "$",
  QA: "ر.ق",
  RE: "$",
  RO: "L",
  RU: "р.",
  RW: "₣",
  SH: "£",
  KN: "$",
  LC: "$",
  PM: "$",
  VC: "$",
  WS: "$",
  SM: "€",
  ST: "Db",
  SA: "ر.س",
  SN: "₣",
  RS: "din",
  SC: "Rs",
  SL: "Le",
  SG: "$",
  SK: "€",
  SI: "€",
  SB: "$",
  SO: "Sh",
  ZA: "R",
  GS: "£",
  ES: "€",
  LK: "Rs",
  SD: "£",
  SR: "$",
  SJ: "$",
  SZ: "L",
  SE: "kr",
  CH: "CHF",
  SY: "ل.س",
  TW: "$",
  TJ: "ЅМ",
  TZ: "Sh",
  TH: "฿",
  TL: "$",
  TG: "₣",
  TK: "$",
  TO: "T$",
  TT: "$",
  TN: "د.ت",
  TR: "₺",
  TM: "m",
  TC: "$",
  TV: "$",
  UG: "Sh",
  UA: "₴",
  AE: "د.إ",
  UM: "$",
  UY: "$",
  UZ: "Sum",
  VU: "Vt",
  VE: "Bs F",
  VN: "₫",
  VG: "$",
  VI: "$",
  WF: "₣",
  EH: "$",
  YE: "﷼",
  ZM: "ZK",
  ZW: "Z$",
  XK: "€",
};

export default countryCurrency;
