<template>
  <div class="flex flex-col items-start gap-7 self-stretch">
    <ProductPreview v-if="store.payment.paymentType === 'product'"></ProductPreview>
    <div class="flex flex-col items-start gap-8 self-stretch">
      <DonationPreview
        v-if="store.payment.paymentType !== 'product'"
        :payment="payment"
        :input-class="inputClass"
      ></DonationPreview>
      <CouponPreview
        v-if="store.payment.paymentType === 'product' && store.payment?.coupon?.enableCoupon"
      ></CouponPreview>
      <PaymentCardPreview></PaymentCardPreview>
    </div>
    <div v-if="!store.payment.footer.hidden" class="self-stretch text-center text-xs text-gray-400">
      {{ store.payment.footer.message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import ProductPreview from "../Products/ProductPreview.vue";
import DonationPreview from "../Donation/DonationPreview.vue";
import PaymentCardPreview from "./PaymentCardPreview.vue";
import CouponPreview from "./CouponPreview.vue";

const store = useAppStore();
defineProps({
  payment: {
    type: Object,
    default: () => {
      return {};
    },
  },
  inputClass: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
</script>
