<script setup lang="ts">
import config from "@/config";
import { useAppStore } from "@/store/app";
import { isPaymentGatewayConnected } from "@/util/methods";
import { UIAlert, UIButton, UISelect, UISwitch } from "@gohighlevel/ghl-ui";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import DonationSettings from "../Donation/DonationSettings.vue";
import ProductCardList from "../Products/ProductCardList.vue";
import ProductLayoutSettings from "../Products/ProductLayoutSettings.vue";
import ProductSettings from "../Products/ProductSettings.vue";
import PaymentFooter from "./PaymentFooter.vue";
import PaymentGeneralSettings from "./PaymentGeneralSettings.vue";
import CouponSettings from "./CouponSettings.vue";
const { t } = useI18n();

const props = defineProps({
  payment: {
    type: Object,
    required: true,
  },
  selectedIndex: {
    type: Number,
    required: true,
  },
});
const store = useAppStore();
const connectionBtn = ref(0);
const paymentTypeOption = [
  { label: t("common.payment.sellProducts"), value: "product" },
  { label: t("common.payment.collectPayment"), value: "donation" },
];
const paymentTypeValue = ref(store.payment.paymentType || "donation");
const addProduct = ref(false);
const currentProductSetting = ref();

onMounted(async () => {
  if (isPaymentGatewayConnected(store.paymentIntegrations, store.payment.liveModeOn)) {
    connectionBtn.value = 1;
  } else {
    connectionBtn.value = 0;
  }
});

const updatePaymentType = (type: string) => {
  paymentTypeValue.value = type;
  store.payment.paymentType = type;
  store.anyUnsavedChanges = true;
};

const liveStatusUpdate = (isLiveOn: boolean) => {
  if (isPaymentGatewayConnected(store.paymentIntegrations, isLiveOn)) {
    connectionBtn.value = 1;
  } else {
    connectionBtn.value = 0;
  }
  store.payment.liveModeOn = isLiveOn;
  store.anyUnsavedChanges = true;
};

const onConnectionClick = () => {
  if (!connectionBtn.value) {
    window
      .open(
        `https://${store.company.domain ? store.company.domain : config.GHL_DOMAIN}/v2/location/${
          store.locationId
        }/payments/integrations`,
        "_blank"
      )
      .focus();
  }
};
const onAddProduct = () => {
  addProduct.value = true;
};
const onBack = () => {
  addProduct.value = false;
  currentProductSetting.value = null;
};

const onProductSettings = (index) => {
  addProduct.value = true;
  currentProductSetting.value = index;
};
</script>

<template>
  <h6 class="field-name-sidebar">
    {{ payment.label || payment.name }}
  </h6>
  <div v-if="!addProduct" class="flex flex-col items-start self-stretch">
    <div class="flex flex-col items-start gap-3 self-stretch pt-2">
      <UIButton
        id="payment-connect"
        type="primary"
        class="flex items-center justify-center gap-2 self-stretch"
        :disabled="connectionBtn ? true : false"
        @click="onConnectionClick"
        >{{ connectionBtn ? t("common.payment.connected") : t("common.payment.connect") }}</UIButton
      >
      <div class="flex items-start justify-between self-stretch">
        <div class="flex items-start gap-4 self-stretch">
          <UISwitch :value="store.payment.liveModeOn" @update:value="liveStatusUpdate"></UISwitch>
          <div>{{ t("common.payment.liveModeOn") }}</div>
        </div>
        <div>
          {{ t("common.payment.status") }} :
          <span
            class="text-base"
            :class="store.payment.liveModeOn ? 'text-success-600' : 'text-red-600'"
            >{{
              store.payment.liveModeOn ? t("common.payment.live") : t("common.payment.test")
            }}</span
          >
        </div>
      </div>
      <div class="flex flex-col items-start justify-between gap-3 self-stretch">
        <div class="flex space-x-3">
          <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
            t("common.payment.paymentType")
          }}</span>
        </div>
        <UISelect
          id="paymentType"
          :value="paymentTypeValue"
          size="medium"
          :options="paymentTypeOption"
          @update:value="updatePaymentType"
        />
        <UIButton
          v-if="paymentTypeValue === 'product'"
          id="payment-connect"
          size="small"
          type="tertiary"
          class="flex items-center justify-center gap-2 self-stretch"
          :disabled="store.addedProductList.length >= 20"
          @click="onAddProduct"
          >{{ t("common.payment.product.addProduct") }}</UIButton
        >
        <UIAlert v-if="store.addedProductList.length >= 20" id="warning-message" type="warning">
          <template #content>{{ t("common.payment.product.maxProductError") }}</template>
        </UIAlert>
        <ProductCardList
          v-if="paymentTypeValue !== 'donation'"
          :products="store.payment.products"
          @product:settings="onProductSettings"
        ></ProductCardList>
      </div>
      <PaymentGeneralSettings :selected-index="props.selectedIndex"></PaymentGeneralSettings>
      <CouponSettings v-if="paymentTypeValue !== 'donation'"></CouponSettings>
      <ProductLayoutSettings v-if="paymentTypeValue !== 'donation'"></ProductLayoutSettings>
      <DonationSettings v-if="paymentTypeValue === 'donation'"></DonationSettings>
      <PaymentFooter></PaymentFooter>
    </div>
  </div>
  <ProductSettings
    v-if="addProduct"
    class="product-sidebar"
    :current-product="
      currentProductSetting >= 0 ? store.addedProductList[currentProductSetting] : undefined
    "
    @on-back="onBack"
  ></ProductSettings>
</template>

<style>
.sub-header {
  border-bottom: 1px solid #d0d5dd;
  margin: 0 -16px 17px -16px;
}
.select-suffix {
  border: 0px solid transparent;
  outline: none;
  border-radius: 0;
}

.center-btn {
  margin: 0 auto;
}
.product-sidebar {
  position: fixed;
  right: 0;
  top: 98px;
  width: 100%;
  max-width: 360px;
  background-color: #ffffff;
  border: 1px solid #dee2e6 !important;
  overflow-y: scroll;
  height: 95vh;
  padding: 15px;
  padding-bottom: 60px;
}

.sub-settings-header {
  border-bottom: 1px solid #d0d5dd;
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin: 0 -15px;
}
</style>
