<script setup lang="ts">
import { ref } from "vue";
import { UISelect } from "@gohighlevel/ghl-ui";
import { useAppStore } from "@/store/app";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps({
  borderType: {
    type: String,
    default: "none",
  },
});
const options = ref([
  {
    label: t("common.borderTypeList.dotted"),
    value: "dotted",
  },
  {
    label: t("common.borderTypeList.dashed"),
    value: "dashed",
  },
  {
    label: t("common.borderTypeList.solid"),
    value: "solid",
  },
  {
    label: t("common.borderTypeList.none"),
    value: "none",
  },
]);

const store = useAppStore();
const emit = defineEmits(["update:border-type"]);

const handleBorderChange = (val) => {
  emit("update:border-type", val);
  store.addToHistory();
};
</script>

<template>
  <div class="grid grid-cols-2 py-2">
    <label class="my-auto text-sm font-normal text-gray-700">{{ t("common.borderType") }}</label>
    <UISelect
      id="select-id"
      :value="borderType"
      :options="options"
      size="medium"
      placeholder="Select one"
      @change="handleBorderChange"
    />
  </div>
</template>

<style scoped>
.input-text {
  font-size: 12px !important;
}
</style>
