<template>
  <div class="mt-2.5">
    <div class="mb-2.5 flex items-center text-sm font-medium text-gray-700">
      <div class="ml-6 flex flex-1">
        <div class="w-[42%]">Option Label</div>
        <div>Prefill Value</div>
      </div>
    </div>
    <VueDraggableNext v-model="options" handle=".handle" class="space-y-2">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="flex items-center space-x-2 text-sm"
      >
        <Menu05Icon class="handle h-4 w-4 cursor-move text-gray-500" />
        <div class="flex flex-1 space-x-2">
          <UIInput
            :id="`option-label-${index}`"
            v-model="option.label"
            class="w-full"
            placeholder="Label"
            :status="errors[index] ? 'error' : ''"
          />
          <UIInput
            :id="`option-prefillValue-${index}`"
            v-model="option.prefillValue"
            class="w-full"
            placeholder="Value"
            :status="errors[index] ? 'error' : ''"
          />
        </div>
        <UIButton
          id="remove-option"
          :disabled="!option.label"
          @click="() => prepareToRemoveOption(index)"
        >
          <Trash01Icon class="h-5 w-5" />
        </UIButton>
      </div>
    </VueDraggableNext>
    <UIButton id="add-option" text class="mt-4" :disabled="isLastOptionEmpty" @click="addOption">
      <PlusIcon class="mr-1.5 h-5 w-5" />
      Add Option
    </UIButton>
  </div>
  <DeleteConfirmationModal
    :show="showDeleteModal"
    @confirm="removeOption"
    @cancel="cancelRemoveOption"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { UIButton, UIInput } from "@gohighlevel/ghl-ui";
import { PlusIcon, Trash01Icon, Menu05Icon } from "@gohighlevel/ghl-icons/24/outline";
import { VueDraggableNext } from "vue-draggable-next";
import DeleteConfirmationModal from "./DeleteConfirmationModal.vue";

const props = defineProps({
  picklistOptions: {
    type: Object,
    default: null,
  },
  customFieldCreated: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:picklistOptions"]);

const options = ref(props.picklistOptions || [{ label: "", value: "", prefillValue: "" }]);

const indexToRemove = ref(null);

const showDeleteModal = ref(false);

const errors = ref<string[]>([]);

const validateOptions = () => {
  errors.value = options.value.map((option) =>
    option.label?.trim() === "" ? "The option field is required" : ""
  );
};

watch(
  () => options,
  () => {
    validateOptions();
    emit("update:picklistOptions", options.value);
  },
  { deep: true }
);

const isLastOptionEmpty = computed(() => {
  const lastOption = options.value[options.value.length - 1];
  return lastOption.label?.trim() === "" && lastOption.value?.trim() === "";
});

const addOption = () => {
  options.value.push({ label: "", value: "", prefillValue: "" });
};

const prepareToRemoveOption = (index) => {
  if (options.value.length > 1) {
    if (props.customFieldCreated) {
      indexToRemove.value = index;
      showDeleteModal.value = true;
    } else {
      options.value.splice(index, 1);
    }
  }
};

const removeOption = async () => {
  if (indexToRemove.value !== null) {
    options.value.splice(indexToRemove.value, 1);
  }
  showDeleteModal.value = false;
};

const cancelRemoveOption = () => {
  indexToRemove.value = null;
  showDeleteModal.value = false;
};
</script>
