{
  "common": {
    "addCustomFields": "Fügen Sie benutzerdefinierte Felder hinzu",
    "addElement": "Element hinzufügen",
    "addUrlLike": "Fügen Sie gültige URLs hinzu, z",
    "address": "Adresse",
    "agencyBranding": "Agentur-Branding",
    "alert": "Alarm",
    "alignment": "Ausrichtung",
    "altText": "Alt-Text",
    "back": "Zurück",
    "background": "Hintergrund",
    "backgroundImage": "Hintergrundbild",
    "backgroundImageTooltipMessage": "Das Hintergrundbild soll die gesamte Seite abdecken und einen optisch ansprechenden Hintergrund für das Formular bieten. \nUm das Hintergrundbild anzuzeigen, klicken Sie auf die Option Vorschau.",
    "blur": "Verwischen",
    "border": "Grenze",
    "borderType": "Randtyp",
    "bottom": "Unten",
    "buttonStyle": "Knopfstil",
    "cancel": "Stornieren",
    "cancelKeep": "Abbrechen, behalten",
    "color": "Farbe",
    "conditionalLogicAlertOnSubmit": "Die bedingte Logik hat hier Vorrang vor der Nachricht/Umleitung",
    "contactInfo": "Kontaktinformation",
    "cornerRadius": "Eckenradius",
    "customCSS": "Benutzerdefinierte CSS",
    "customCSSTooltip": "Benutzerdefiniertes CSS hat Vorrang vor dem Formularstil",
    "customFieldSearch": "Suche mit Name",
    "customFields": "Benutzerdefinierte Felder",
    "customized": "Maßgeschneidert",
    "delete": "Löschen",
    "doNotAddRelativePath": "Fügen Sie keine relativen Pfade wie hinzu",
    "doNotShowMessage": "Nicht mehr anzeigen",
    "done": "Erledigt",
    "editHTML": "HTML bearbeiten",
    "embed": "Einbetten",
    "enableCountryPicker": "Aktivieren Sie die Länderauswahl",
    "enterSourceValue": "Geben Sie den Quellwert ein",
    "facebookPixelEvents": "Facebook-Pixel-Events",
    "facebookPixelID": "Facebook-Pixel-ID",
    "facebookPixelIDMessage": "Ignorieren Sie dieses Feld, wenn Sie dieses Formular/diese Umfrage in einem Trichter verwenden möchten",
    "fieldDescription": "Feld Beschreibung",
    "fieldStyle": "Feldstil",
    "fieldTitle": "Feldtitel",
    "fontColor": "Schriftfarbe",
    "fontFamily": "Schriftfamilie",
    "fontFamilyTooltip": "Builder unterstützt alle Arten von Schriftarten, wenn Sie diese nicht in der Liste sehen können. \nBitte suchen Sie im Dropdown-Menü danach",
    "fullWidth": "Gesamtbreite",
    "headerImage": "Header-Bild",
    "headerImageTooltipMessage": "Das Kopfzeilenbild soll die gesamte Breite des Formulars abdecken und bleibt oben im Formular positioniert. \nUm das Headerbild anzuzeigen, klicken Sie auf die Option „Vorschau“.",
    "hidden": "Versteckt",
    "hiddenValue": "Versteckter Wert",
    "horizontal": "Horizontal",
    "htmlEditorBodyMessage": "Fügen Sie hier Ihren Code hinzu",
    "htmlEditorHeader": "BENUTZERDEFINIERTES JAVASCRIPT / HTML",
    "htmlMessage": "Der benutzerdefinierte HTML-Code kommt hierher",
    "iframeEmbed": "Ifrmae-Einbettung",
    "imageHeight": "Bildhöhe",
    "imageUploadMessage": "Klicken oder ziehen Sie eine Bilddatei in diesen Bereich, um sie hochzuladen",
    "imageWidth": "Bild breite",
    "inputStyle": "Eingabestil",
    "integrate": "Integrieren",
    "label": "Etikett",
    "layout": "Layout",
    "left": "Links",
    "link": "Verknüpfung",
    "logic": "Logik",
    "miscellaneous": "Verschiedenes",
    "onSubmit": "Beim Absenden",
    "onpageView": "In der Seitenansicht",
    "options": "Optionen",
    "or": "oder",
    "padding": "Polsterung",
    "personalInfo": "Persönliche Informationen",
    "placeholder": "Platzhalter",
    "placeholderFacebookPixelIdInput": "Geben Sie die Facebook-Pixel-ID ein",
    "pleaseInput": "Bitte eingeben",
    "preview": "Vorschau",
    "proceed": "Fortfahren",
    "queryKey": "Abfrageschlüssel",
    "querykeyTooltipMessage": "Der Abfrageschlüssel, der als URL-Parameter zum Füllen dieses Felds verwendet werden kann",
    "quickAdd": "Schnell hinzufügen",
    "required": "Erforderlich",
    "right": "Rechts",
    "save": "Speichern",
    "saving": "Sparen",
    "shadow": "Schatten",
    "shortLabel": "Kurzbezeichnung",
    "showLabel": "Etikett anzeigen",
    "size": "Größe",
    "spread": "Verbreiten",
    "stickyContact": "Klebriger Kontakt",
    "stickyContactMessage": "Sticky Contact füllt zuvor eingegebene Kontaktinformationen automatisch aus, sodass Sie diese nicht zweimal eingeben müssen.",
    "styles": "Stile",
    "subText": "Untertext",
    "submit": "Einreichen",
    "text": "Text",
    "textStyle": "Textstil",
    "themes": {
      "alertThemeMessage": "Das Wechseln des Themas führt zum Verlust aller aktuellen Stiländerungen.",
      "removeTheme": "Thema entfernen",
      "themes": "Themen",
      "useTheme": "Thema verwenden"
    },
    "top": "Spitze",
    "useGDPRCompliantFont": "Verwenden Sie eine DSGVO-konforme Schriftart",
    "vertical": "Vertikal",
    "weight": "Gewicht",
    "width": "Breite",
    "yesSave": "Ja, Speichern",
    "box": "Kasten",
    "line": "Linie",
    "message": "Nachricht",
    "openUrl": "Öffne URL",
    "borderTypeList": {
      "dotted": "Gepunktet",
      "none": "Keiner",
      "solid": "Solide",
      "dashed": "Gestrichelt"
    },
    "button": "Taste",
    "enableTimezone": "Aktivieren Sie die Zeitzone",
    "emailNotification": "Email Benachrichtigung",
    "emailNotificationSubheader": "Erhalten Sie eine E-Mail, wenn jemand Ihr Formular ausfüllt",
    "subject": "Thema",
    "emailTo": "E-Mail an)",
    "senderName": "Absender",
    "senderNameWarning": "Ihr (Unterkontoname) wird hinzugefügt, wenn das Feld leer bleibt",
    "emailTooltip": "Die erste E-Mail-Adresse ist für das Feld „An“ vorgesehen. \nAlle nachfolgenden E-Mail-Adressen werden in das Feld „Cc“ aufgenommen.",
    "emailError": "Es ist mindestens eine E-Mail-Adresse erforderlich",
    "clear": "Klar",
    "showNotificationsSettings": "Benachrichtigungseinstellungen anzeigen",
    "emailServicePaid": "Bei dieser Funktion handelt es sich um einen kostenpflichtigen Dienst, für den je nach Nutzung Gebühren anfallen.",
    "integration": "Integration",
    "payment": {
      "connect": "Verbinden",
      "connected": "In Verbindung gebracht",
      "footerInfo": "Fußzeileninformationen",
      "includeOtherAmount": "Geben Sie einen anderen Betrag an",
      "live": "Live",
      "paymentFields": "Zahlungsfelder",
      "selectCurrency": "Währung wählen",
      "status": "Status",
      "suggestAnAmount": "Schlagen Sie einen Betrag vor",
      "test": "Prüfen",
      "liveModeOn": "Live-Modus",
      "customAmount": "Benutzerdefinierter Betrag",
      "paymentType": "Zahlungsart",
      "product": {
        "addProduct": "Produkt hinzufügen",
        "clickHere": "klicken Sie hier",
        "description": "Beschreibung",
        "editThisProduct": "Um dieses Produkt zu bearbeiten",
        "image": "Bild",
        "layoutSettings": "Layouteinstellungen",
        "maxProductError": "In einem Formular sind maximal 20 Produkte zulässig",
        "productSelectPlaceholder": "Bitte wählen Sie ein Produkt aus",
        "productSettings": "Produkteinstellungen",
        "selectProduct": "Ausgewähltes Produkt",
        "show": "Zeigen",
        "singleColumn": "Einzelne Spalte",
        "threeColumn": "Dreispaltig",
        "twoColumn": "Zweispaltig",
        "orderConfirmationWarning": "Sobald die Zahlung erfolgreich war, wird eine Bestellbestätigungsseite angezeigt",
        "orderConfirmation": "Bestellbestätigung",
        "createProduct": "Um ein Produkt zu erstellen",
        "quantity": "Menge"
      },
      "sellProducts": "Produkte verkaufen",
      "generalSettings": "Allgemeine Felder",
      "tagActiveBGColor": "Aktive Tag-Farbe",
      "collectPayment": "Sammeln Zahlung",
      "payments": "Zahlungen",
      "coupons": {
        "couponAlignment": "Coupon-Ausrichtung",
        "coupons": "Gutscheine",
        "enableCoupon": "Gutschein aktivieren",
        "fullWidth": "Gesamtbreite",
        "left": "Links",
        "right": "Rechts",
        "tooltipMessage": "Legen Sie die Position der Coupon-Eingabe aus den folgenden Typen fest"
      }
    },
    "autoResponder": "Auto-Responder",
    "autoResponderSubheader": "Senden Sie eine automatisierte E-Mail an die Person, die das Formular abschickt (die E-Mail enthält eine Kopie der im Formular eingegebenen Informationen).",
    "replyToEmail": "Antwort-auf-E-Mail",
    "open": "Offen",
    "won": "Gewonnen",
    "lost": "Verloren",
    "abandon": "Aufgeben",
    "selectAPipeline": "Wählen Sie eine Pipeline aus",
    "stage": "Bühne",
    "status": "Status",
    "opportunityValue": "Opportunitätswert",
    "createNewPipeline": "Neue Pipeline erstellen",
    "editOpportunitySettings": "Bearbeiten Sie die Opportunity-Einstellungen",
    "contact": "Kontakt",
    "opportunity": "Gelegenheit",
    "add": "Hinzufügen",
    "more": "Mehr",
    "opportunityHeading": "Wo soll Ihre Opportunity aktualisiert werden?",
    "date": {
      "dateFormat": "Datumsformat",
      "dateFormatTooltipMessage": "Wählen Sie ein Datumsformat. \nD stand für Tag, M für Monate und Y für Jahr",
      "dateSeparator": "Datumstrennzeichen",
      "dateSeparatorTooltipMessage": "Wählen Sie ein Trennzeichen, das Tag, Monat und Jahr in dem unter „Datumsformat“ ausgewählten Format trennt",
      "dateSettings": "Datumseinstellungen",
      "disablePicker": "Picker deaktivieren"
    },
    "restoreVersion": "Version wiederherstellen",
    "versionHistory": "Versionsgeschichte",
    "errorMessage": "Fehler, versuchen Sie es noch einmal",
    "version": "Ausführung:",
    "currentVersion": "Aktuelle Version",
    "conditionalLogicAlertOnShowHide": "Die bedingte Logik hat hier Vorrang vor den versteckten Einstellungen",
    "validateEmailHeader": "E-Mail validieren",
    "validateEmailDescription": "Die Schaltfläche wird sichtbar, sobald der Benutzer seine E-Mail-Adresse eingibt. \nDiese Funktion soll Ihnen dabei helfen, hochwertige Leads zu generieren.",
    "validatePhoneHeader": "Telefon validieren",
    "validatePhoneDescription": "Die Schaltfläche wird sichtbar, sobald der Benutzer sein Telefon betritt. \nDiese Funktion soll Ihnen dabei helfen, hochwertige Leads zu generieren.",
    "validatePhoneWarning": "Um die SMS-OTP-Überprüfung zu aktivieren, stellen Sie bitte sicher, dass Ihre Telefonnummer in Ihren Standorteinstellungen eingerichtet ist",
    "style": {
      "formLayoutType": "Formulartyp",
      "oneColumn": "Eine Spalte",
      "singleLine": "Einzelne Zeile",
      "twoColumn": "Zweispaltig",
      "fieldWidth": "Feldbreite",
      "fieldSpacing": "Feldabstand",
      "advanceSettings": "Erweiterte Einstellungen",
      "borderColor": "Randfarbe",
      "borderStyle": "Grenzstil",
      "borderWidth": "Rahmenbreite",
      "color": "Farbe",
      "colorsAndBackground": "Farben",
      "fontColor": "Schriftfarbe",
      "fontSize": "Schriftgröße",
      "fontWeight": "Schriftstärke",
      "inputBackground": "Eingabehintergrund",
      "inputField": "Eingabefeld",
      "labelColor": "Etikettenfarbe",
      "layout": "Layout",
      "placeholderColor": "Platzhalterfarbe",
      "shortLabelColor": "Kurze Etikettenfarbe",
      "labelAlignment": {
        "labelAlignment": "Etikettenausrichtung",
        "labelWidth": "Etikettenbreite"
      }
    },
    "replyToTooltip": "Passen Sie die Standard-Antwort-E-Mail-Adresse mithilfe von {'{'}{'{'}contact.email{'}'}{'}'} an, um Antworten an den Kontakt zu ermöglichen, der das Formular sendet.",
    "content": "Inhalt",
    "calculations": "Berechnungen",
    "uploadOptions": "Upload-Optionen",
    "messageStyling": "Nachrichtenstil",
    "opportunitySettingsWarning": "Bitte richten Sie Opportunity-Einstellungen ein, indem Sie auf die Schaltfläche „Opportunity-Einstellungen bearbeiten“ klicken.",
    "createContactOnPartialSubmission": "Erstellen Sie einen Kontakt bei teilweiser Übermittlung",
    "howItWorks": "Wie funktioniert es?",
    "createContactOnPartialSubmissionDescription": "Beenden Sie die Folie und klicken Sie auf „Weiter“. \nEin neuer Kontakt wird automatisch erstellt und gespeichert.",
    "swapWarningMessageSurvey": "Das Bearbeiten oder Austauschen von Optionen wirkt sich auf dieses benutzerdefinierte Feld an allen Stellen aus, an denen das Feld verwendet wird. \nEs kann auch die bedingte Logik zurücksetzen.",
    "swapWarningMessageForm": "Das Bearbeiten oder Austauschen von Optionen wirkt sich auf dieses benutzerdefinierte Feld an allen Stellen aus, an denen das Feld verwendet wird.",
    "addOption": "Option hinzufügen",
    "calculationValue": "Berechnungswert",
    "enableCalculations": "Berechnungen aktivieren",
    "optionLabel": "Optionsbeschriftung",
    "addressSettings": {
      "addressfields": "Adressfelder",
      "autoComplete": "Adresse automatisch vervollständigen",
      "autoCompleteTooltip": "Bietet die Möglichkeit, Adressen zu suchen und automatisch einzugeben. \nDiese Funktion ist kostenpflichtig.",
      "placeholderSearch": "Suchen Sie nach einer Adresse"
    }
  },
  "form": {
    "activateOn": "Aktivieren Sie auf",
    "activationOptions": "Aktivierungsmöglichkeiten",
    "addConditionalLogic": "Bedingte Logik hinzufügen",
    "addFormElement": "Formularelement hinzufügen",
    "allowMinimize": "Minimieren zulassen",
    "alwaysActivated": "Immer aktiviert",
    "alwaysShow": "Immer anzeigen",
    "conditonalLogic": {
      "addNewCondition": "Neue Bedingung hinzufügen",
      "alertConditionDelete": "Sind Sie sicher, dass Sie diese Bedingung löschen möchten?",
      "conditionExecutedMessage": "Bedingungen werden in der Top-Down-Reihenfolge ausgeführt.",
      "conditions": "Bedingungen",
      "deleteCondition": "Bedingung löschen",
      "displayCustomMessage": "Benutzerdefinierte Nachricht anzeigen",
      "disqualifyLead": "Lead disqualifizieren",
      "freeRoamMessage": "Sie können sich ohne Regeln frei bewegen – zumindest vorerst",
      "noExistingCondition": "Es liegen noch keine Konditionen vor",
      "openBelowUrl": "Öffnen Sie die folgende URL",
      "redirectToUrl": "Zur URL weiterleiten",
      "showCustomMessage": "Benutzerdefinierte Nachricht anzeigen",
      "showHideFields": "Felder ein-/ausblenden",
      "allOperationOptions": {
        "after": "Nach",
        "before": "Vor",
        "contains": "Enthält",
        "endsWith": "Endet mit",
        "greaterThan": "Größer als",
        "isEmpty": "Ist leer",
        "isEqualTo": "Ist gleich",
        "isFilled": "Ist gefüllt",
        "isNotEqualTo": "Ist ungleich zu",
        "lessThan": "Weniger als",
        "startsWith": "Beginnt mit"
      },
      "logic": {
        "and": "Und",
        "if": "Wenn",
        "or": "Oder",
        "then": "Dann"
      },
      "phoneAlertMessage": "Um nach Telefonnummern mit einer Landesvorwahl zu suchen, müssen Sie das Symbol „+“ explizit in Ihre Suchabfrage einschließen.",
      "deleteAlertMessage": "Sind Sie sicher, dass Sie dieses Feld löschen möchten? \nDie mit diesem Feld verknüpfte Bedingung wird ebenfalls entfernt.",
      "deleteField": "Feld löschen"
    },
    "minimizedTitlePlaceholder": "Minimierter Titel",
    "copyEmbedCode": "Einbettungscode kopieren",
    "deactivateAfterShowing": "Nach dem Anzeigen deaktivieren",
    "deactivationOptions": "Deaktivierungsmöglichkeiten",
    "deactiveOnceLeadCollected": "Deaktivieren, sobald Lead gesammelt wurde",
    "embedlayoutType": "Layouttyp einbetten",
    "embedlayoutTypeSubMessage": "Wählen Sie ein Layout zum Einbetten in einen Trichter oder eine externe Website aus",
    "formElement": "Formularelement",
    "formName": "Formularname",
    "formSettings": "Formulareinstellungen",
    "formStyle": "Formularstil",
    "fullScreenMode": "Vollbildmodus",
    "fullScreenModetooltip": "Wenn Sie diese Option aktivieren, dehnt sich das Formular über die gesamte Breite seines Containers aus und erstreckt sich bis zum Rand des Einbettungsbereichs, wobei die Breite des Formulars beibehalten wird.",
    "inline": "Im Einklang",
    "inlineDescription": "Ein Inline-Formular erscheint zusammen mit dem Inhalt der Seite als natives Element. \nEs überlagert sich nicht und kann nicht geschlossen oder minimiert werden.",
    "inlineForm": "Inline-Formular",
    "inlineHoverDescription": "Ein Inline-Formular erscheint zusammen mit dem Inhalt der Seite als natives Element. \nEs überlagert sich nicht und kann nicht geschlossen oder minimiert werden.",
    "integrateForm": "Formular integrieren",
    "neverDeactivate": "Niemals deaktivieren",
    "onFormSubmission": "Bei der Formularübermittlung",
    "politeSlideIn": "Höfliches Einschieben",
    "politeSlideInDescription": "Auf der unteren Seite des Bildschirms erscheint ein höfliches Slide-in, das nach dem Schließen wieder verschwindet",
    "politeSlideInHoverDescription": "Erscheint auf der Unterseite des Bildschirms und verschwindet, sobald es geschlossen wird",
    "popup": "Aufpoppen",
    "popupDescription": "Ein Pop-up zeigt das Formular als Overlay in einem Leuchtkasten. \nEs kann nicht minimiert, sondern geschlossen werden.",
    "popupHoverDescription": "Ein Pop-up zeigt das Formular als Overlay in einem Leuchtkasten. \nEs kann nicht minimiert, sondern geschlossen werden.",
    "seconds": "Sekunden",
    "showAfter": "Danach anzeigen",
    "showOnScrolling": "Beim Scrollen anzeigen",
    "stickySidebar": "Klebrige Seitenleiste",
    "stickySidebarDescription": "An der Seite des Bildschirms wird eine Sticky-Seitenleiste angezeigt, die auf eine Registerkarte minimiert werden kann, die an der Seite des Fensters haftet",
    "stickySidebarHoverDescription": "Erscheint an der Seite des Bildschirms und kann auf eine Lasche minimiert werden, die an der Seite des Fensters haftet",
    "thirdPartyScriptAlertMessage": "Skripte von Drittanbietern innerhalb des HTML-Blocks können leistungsstarke Funktionen bereitstellen, bergen jedoch auch Risiken für Datenschutz, Sicherheit, Leistung und Seitenverhalten. \nDaher empfehlen wir Ihnen, den Inhalt dieses Skripts zu überprüfen, bevor Sie es im Formular speichern.",
    "times": "mal",
    "triggerType": "Triggertyp",
    "typeOfBuilder": "Bilden",
    "visit": "besuchen",
    "dragYourElementFromLeft": "Ziehen Sie Ihre Elemente von links hierher",
    "enableTimezoneForm": "Durch die Aktivierung dieser Option werden die Zeitzoneninformationen der Person erfasst, die das Formular abschickt",
    "formNotification": "Formularbenachrichtigung",
    "copyFormLink": "Formularlink kopieren",
    "openFormLink": "Formularlink öffnen",
    "unsavedAlertMessage": "Das Formular enthält nicht gespeicherte Arbeiten. \nSind Sie sicher, dass Sie alle nicht gespeicherten Arbeiten verlassen und verwerfen möchten?",
    "style": {
      "formPadding": "Formularpolsterung"
    }
  },
  "survey": {
    "addSlide": "Folie hinzufügen",
    "addSurveyElement": "Umfrageelement hinzufügen",
    "backButton": "Zurück-Button",
    "backButtonTooltipMessage": "Die Zurück-Taste hilft dem Benutzer, zur vorherigen Folie zu wechseln",
    "delete": "Löschen",
    "disableAnimation": "Animation deaktivieren",
    "disableAnimationTooltipMessage": "Wenn Sie diese Option aktivieren, wird die Folienübergangsanimation deaktiviert",
    "disableAutoNavigation": "Deaktivieren Sie die automatische Navigation",
    "disableAutoNavigationTooltipMessage": "Wenn diese Option aktiviert ist, wechselt die Umfrage nicht automatisch zur nächsten Folie",
    "disqualifyAfterSubmit": "Nach dem Absenden disqualifizieren",
    "disqualifyImmediately": "Sofort disqualifizieren",
    "editFooter": "Fußzeile bearbeiten",
    "footer": {
      "backgroundFill": "Hintergrundfüllung",
      "buttonType": "Schaltflächentyp",
      "buttons": "Tasten",
      "enableNewFooter": "Aktivieren Sie „Neue Fußzeile“.",
      "enableProgressBar": "Aktivieren Sie den Fortschrittsbalken",
      "fillComplete": "Füllen abgeschlossen",
      "fillInactive": "Inaktiv ausfüllen",
      "fillNext": "Ausfüllen (nächstes)",
      "fillPrevious": "Füllen (vorheriges)",
      "fillSubmit": "Ausfüllen (Senden)",
      "footer": "Fusszeile",
      "footerHeight": "Fußzeilenhöhe",
      "nextText": "Weiter (Text)",
      "position": "Position",
      "previousText": "Zurück (Text)",
      "stickToSurvey": "Bleiben Sie bei der Umfrage",
      "submitText": "Senden (Text)",
      "textColor": "Textfarbe",
      "footerType": "Fußzeilentyp",
      "stickToCard": "Bleiben Sie bei der Karte",
      "stickToPage": "Bleiben Sie bei der Seite"
    },
    "footerHTML": "Fußzeilen-HTML",
    "footerHTMLMessage": "Sie können benutzerdefiniertes CSS (mithilfe des Style-Tags) und HTML in dasselbe Feld schreiben",
    "integrateCopyLinkMessage": "Kopieren Sie den Link unten und teilen Sie ihn ganz einfach, wo immer Sie möchten.",
    "integrateHeader": "Umfrage integrieren",
    "integrateSubHeader": "Nutzen Sie die folgenden Möglichkeiten, um Ihre individuelle Umfrage in Ihre Website zu integrieren",
    "jumpTo": "Springen zu",
    "onSurveySubmission": "Zur Einreichung einer Umfrage",
    "oneQuestionAtTime": "Eine Frage nach der anderen",
    "progressBar": "Fortschrittsanzeige",
    "progressBarTooltipMessage": "Wenn Sie diese Option deaktivieren, wird der Fortschrittsbalken aus der Umfrage entfernt",
    "scrollToTop": "Nach oben scrollen",
    "scrollToTopTooltipMessage": "Wenn diese Option aktiviert ist, scrollt die Umfrage auf der nächsten Folie nach oben",
    "settings": "Einstellungen",
    "slideConflictAlertMessage": "Diese Folie enthält mehrere Fragen, denen eine Logik zugewiesen ist. \nDies kann zu unbeabsichtigtem Verhalten führen",
    "slideDeleteAlertMessage": "Sind Sie sicher, dass Sie diese Folie löschen möchten?",
    "slideDeleteBetweenAlertMessage": "Dadurch werden die anderen Folien neu angeordnet. Sind Sie sicher, dass Sie diese Folie löschen möchten?",
    "slideName": "Folienname",
    "slidePosition": "Schiebeposition",
    "surveyElement": "Umfrageelement",
    "surveyName": "Umfragename",
    "surveySettings": "Umfrageeinstellungen",
    "survyeStyle": "Umfragestil",
    "thirdPartyScriptAlertMessage": "Skripte von Drittanbietern innerhalb des HTML-Blocks können leistungsstarke Funktionen bereitstellen, bergen jedoch auch Risiken für Datenschutz, Sicherheit, Leistung und Seitenverhalten. \nDaher empfehlen wir Ihnen, den Inhalt dieses Skripts zu überprüfen, bevor Sie es in der Umfrage speichern.",
    "typeOfBuilder": "Umfrage",
    "enableTimezoneSurvey": "Durch die Aktivierung dieser Option werden die Zeitzoneninformationen der Person erfasst, die die Umfrage einreicht",
    "surveyNotfication": "Umfragebenachrichtigung",
    "unsavedAlertMessage": "Die Umfrage enthält nicht gespeicherte Arbeiten. \nSind Sie sicher, dass Sie alle nicht gespeicherten Arbeiten verlassen und verwerfen möchten?",
    "style": {
      "surveyPadding": "Umfragepolsterung"
    }
  }
}
