import config from "@/config";
import { requests } from ".";

const LOCATION_SERVER_URL = config?.LOCATION_SERVER_URL;

interface CreateCustomField {
  name: string;
  fieldKey?: string;
  dataType?: string;
  documentType: string;
  showInForms?: boolean;
  model: string;
  parentId?: string;
  description?: string;
}

export const LocationService = {
  getCustomFields: (
    locationId: string,
    parentId: string,
    skip = 0,
    limit = 10,
    documentType = "field",
    query = "",
    includeStandards = true,
    model = "all"
  ) =>
    requests(LOCATION_SERVER_URL).get(
      `/locations/${locationId}/customFields/search?parentId=${parentId}&skip=${skip}&limit=${limit}&documentType=${documentType}&query=${query}&includeStandards=${includeStandards}&model=${model}`
    ),
  createCustomField: (locationId: string, params: CreateCustomField) =>
    requests(LOCATION_SERVER_URL).post(`/locations/${locationId}/customFields/`, params),
  updateCustomField: (locationId: string, id: string, params: CreateCustomField) =>
    requests(LOCATION_SERVER_URL).put(`/locations/${locationId}/customFields/${id}`, params),
};
