<template>
  <div class="grid grid-cols-2 gap-3">
    <PixelNumber
      :label="t('common.style.borderWidth')"
      :value="parseFloat(store?.formStyle?.border?.border)"
      @update:value="handleBorder"
    />
    <PixelNumber
      :label="t('common.cornerRadius')"
      :value="parseFloat(store?.formStyle?.border?.radius)"
      @update:value="handleBorderRadius"
    />
  </div>
  <ColorInput
    :label="t('common.style.borderColor')"
    :color="store?.formStyle?.border?.color"
    @update:color="handleBorderColor"
  />
  <BorderStyle
    :label="t('common.style.borderStyle')"
    :border-type="store?.formStyle?.border?.style"
    @update:border-type="handleBorderType"
  ></BorderStyle>
  <Shadow
    :label="t('common.shadow')"
    :shadow="store?.formStyle?.shadow"
    @update:color="handleShadowColor"
    @update:horizontal="handleShadowHorizontal"
    @update:vertical="handleShadowVertical"
    @update:blur="handleShadowBlur"
    @update:spread="handleShadowSpread"
  ></Shadow>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { useI18n } from "vue-i18n";
import BorderStyle from "../Elements/BorderStyle.vue";
import ColorInput from "../Elements/ColorInput.vue";
import PixelNumber from "../Elements/PixelNumber.vue";
import Shadow from "../Elements/Shadow.vue";

const { t } = useI18n();
const store = useAppStore();

const handleBorder = (val: number) => {
  store.formStyle.border.border = val;
  store.anyUnsavedChanges = true;
};
const handleBorderColor = (val: string) => {
  store.formStyle.border.color = val;
  store.anyUnsavedChanges = true;
};
const handleBorderType = (val: string) => {
  store.formStyle.border.style = val;
  store.anyUnsavedChanges = true;
};
const handleBorderRadius = (val: number) => {
  store.formStyle.border.radius = val;
  store.anyUnsavedChanges = true;
};

//Handle Form Shadow
const handleShadowHorizontal = (val: number) => {
  store.formStyle.shadow.horizontal = val;
  store.anyUnsavedChanges = true;
};
const handleShadowVertical = (val: number) => {
  store.formStyle.shadow.vertical = val;
  store.anyUnsavedChanges = true;
};
const handleShadowBlur = (val: number) => {
  store.formStyle.shadow.blur = val;
  store.anyUnsavedChanges = true;
};
const handleShadowSpread = (val: number) => {
  store.formStyle.shadow.spread = val;
  store.anyUnsavedChanges = true;
};
const handleShadowColor = (val: string) => {
  store.formStyle.shadow.color = val;
  store.anyUnsavedChanges = true;
};
</script>
