<template>
  <UISelect
    id="submit"
    v-model:value="disqualifyAfterSubmit"
    :options="disqualifyOptions"
    @update:value="disqualifyAfterSubmitSelection"
  />
  <UIInput
    id="disqualifyAfterSubmit"
    v-model="disqualifyAfterSubmitModel"
    :placeholder="placeholderForDisqualifyAfterSubmit"
    @blur="store.addToHistory()"
  />
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UIInput, UISelect } from "@gohighlevel/ghl-ui";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useAppStore();
const disqualifyOptions = [
  { label: "URL", value: "url" },
  { label: t("common.message"), value: "message" },
];

const disqualifyAfterSubmit = ref(store.formAction.endsurveyType == 1 ? "url" : "message");
const placeholderForDisqualifyAfterSubmit = computed(() => {
  return store.formAction.endsurveyType === 1 ? "Url Goes Here" : "Message Goes Here";
});
const disqualifyAfterSubmitModel = computed({
  get() {
    if (disqualifyAfterSubmit.value === "url") {
      return store.formAction.endsurveyUrl;
    }
    return store.formAction.endsurveyText;
  },
  set(value: string) {
    if (disqualifyAfterSubmit.value === "url") {
      store.formAction.endsurveyUrl = value;
    } else {
      store.formAction.endsurveyText = value;
    }
    store.anyUnsavedChanges = true;
  },
});

const disqualifyAfterSubmitSelection = (type) => {
  if (type === "url") {
    store.formAction.endsurveyType = 1;
  } else {
    disqualifyAfterSubmit.value = "message";
    store.formAction.endsurveyType = 2;
  }
  store.anyUnsavedChanges = true;
  store.addToHistory();
};
</script>
