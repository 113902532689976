<script lang="ts" setup>
import config from "@/config";
import { useAppStore } from "@/store/app";
import currency from "@/util/currency";
import { CursorBoxIcon, PlusIcon } from "@gohighlevel/ghl-icons/24/outline";
import {
  UIButton,
  UIInputNumber,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
  UISelect,
} from "@gohighlevel/ghl-ui";
import { cloneDeep } from "lodash";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const store = useAppStore();
const { getPipelinesFromStore } = storeToRefs(store);

const emit = defineEmits(["update:cancel", "update:success"]);

const nameMap = ({ name }) => ({
  label: name,
  value: name,
});

const cancelCallback = () => {
  opportunitySettings.value = cloneDeep(store.opportunitySettings) || {
    selectedPipeline: pipelineNames.value?.[0]?.value,
    stage: stageOptions.value?.[0]?.value,
    status: statusOptions.value?.[0]?.value,
    opportunityValue: 0,
  };
  emit("update:cancel", false);
};

const submitCallback = () => {
  const {
    selectedPipeline = "",
    stage = "",
    status = "",
    opportunityValue = "",
  } = opportunitySettings.value;

  const {
    id: pipelineId,
    name,
    stages,
  } = (getPipelinesFromStore.value ?? []).find(({ name }) => name === selectedPipeline) ?? {};

  const { id: pipelineStageId } = (stages ?? []).find(({ name }) => name === stage) ?? {};

  let opportunityCustomFields = store.formId
    ? store.elements.filter((field) => field.model === "opportunity")
    : store.slides.reduce((acc, slide) => {
        const filteredFields = slide.slideData.filter((field) => field.model === "opportunity");
        return acc.concat(filteredFields);
      }, []);

  opportunityCustomFields = opportunityCustomFields.map(({ Id, fieldKey }) => ({
    id: Id,
    key: fieldKey.replace("opportunity.", ""),
  }));

  emit("update:success", {
    ...opportunitySettings.value,
    payload: {
      pipelineId,
      locationId: store.locationId,
      name,
      pipelineStageId,
      status: status,
      monetaryValue: parseFloat(opportunityValue),
      customFields: opportunityCustomFields,
    },
  });
};

const pipelineNames = ref(getPipelinesFromStore.value.map(nameMap));

const stageOptions = ref(getPipelinesFromStore.value[0]?.stages.map(nameMap));

const statusOptions = ref(
  ["open", "won", "lost", "abandon"].map((status) => ({
    label: t(`common.${status}`),
    value: status,
  }))
);

const opportunitySettings = ref(
  cloneDeep(store.opportunitySettings) || {
    selectedPipeline: pipelineNames.value?.[0]?.value,
    stage: stageOptions.value?.[0]?.value,
    status: statusOptions.value?.[0]?.value,
    opportunityValue: 0,
  }
);

if (
  !getPipelinesFromStore.value?.find(
    ({ name }) => name === opportunitySettings.value?.selectedPipeline
  )
) {
  opportunitySettings.value = {
    selectedPipeline: pipelineNames.value?.[0]?.value,
    stage: stageOptions.value?.[0]?.value,
    status: statusOptions.value?.[0]?.value,
    opportunityValue: 0,
  };
}
const updateSelectedPipeline = (value: string) => {
  stageOptions.value = getPipelinesFromStore.value
    .find(({ name }) => name === value)
    ?.stages.map(({ name }) => ({
      label: name,
      value: name,
    }));
  opportunitySettings.value.stage = stageOptions.value?.[0].value;
};

const openOpportunitySettings = () =>
  window
    .open(
      `${config.mode === "dev" ? "http" : "https"}://${
        store.company.domain ? store.company.domain : config.GHL_DOMAIN
      }/v2/location/${store.locationId}/opportunities/pipeline`,
      "_blank"
    )
    .focus();

const currentLocationCurrency = currency?.[store.locationCountry as any] || "$";
</script>

<template>
  <UIModal :show="show" @update:show="cancelCallback">
    <template #header>
      <UIModalHeader
        id="modal-header"
        :title="t('common.opportunityHeading')"
        :icon="CursorBoxIcon"
        @close="cancelCallback"
      >
      </UIModalHeader>
    </template>
    <UIModalContent>
      <div class="mb-6 flex gap-x-4">
        <div class="w-full">
          <div class="mb-1 text-sm font-medium">{{ t("common.selectAPipeline") }}</div>
          <UISelect
            id="select-a-pipeline"
            v-model:value="opportunitySettings.selectedPipeline"
            :options="pipelineNames"
            placeholder="Select"
            @update:value="updateSelectedPipeline"
          />
        </div>
        <div class="w-full">
          <div class="mb-1 text-sm font-medium">{{ t("common.stage") }}</div>
          <UISelect
            id="stage"
            v-model:value="opportunitySettings.stage"
            :options="stageOptions"
            placeholder="Select"
          />
        </div>
      </div>

      <div class="mb-8 flex gap-x-4">
        <div class="w-full">
          <div class="mb-1 text-sm font-medium">{{ t("common.status") }}</div>
          <UISelect
            id="status"
            v-model:value="opportunitySettings.status"
            :options="statusOptions"
            placeholder="Select"
          />
        </div>
        <div class="w-full">
          <div class="mb-1 text-sm font-medium">{{ t("common.opportunityValue") }}</div>
          <UIInputNumber id="opportunity-value" v-model:value="opportunitySettings.opportunityValue"
            ><template #prefix>{{ currentLocationCurrency }}</template></UIInputNumber
          >
        </div>
      </div>
    </UIModalContent>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        :positive-text="t('common.save')"
        :negative-text="t('common.cancel')"
        @positive-click="submitCallback"
        @negative-click="cancelCallback"
      >
        <template #actionLeft>
          <UIButton
            id="create-new-pipeline"
            type="tertiary"
            :disabled="!store.isOpportunitiesEnabled"
            @click="openOpportunitySettings"
          >
            <PlusIcon class="mr-2 h-4 w-4" /> {{ t("common.createNewPipeline") }}
          </UIButton>
        </template>
      </UIModalFooter>
    </template>
  </UIModal>
</template>
