<template>
  <div
    v-if="editor"
    id="richTextEditor"
    class="toolbar editor-style flex flex-col flex-wrap rounded-lg border-gray-400"
  >
    <editor-content class="px-3 py-3.5 text-left" :editor="editor" />
    <div class="top-border flex items-center justify-between p-3">
      <div class="flex gap-2">
        <div
          class="flex items-center text-gray-600"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          <Heading01Icon class="icon h-5 w-5"></Heading01Icon>
        </div>
        <div
          class="flex items-center text-gray-600"
          :class="{ 'is-active': editor.isActive('paragraph') }"
          @click="editor.chain().focus().setParagraph().run()"
        >
          <Pilcrow01Icon class="icon h-5 w-5"></Pilcrow01Icon>
        </div>

        <div
          class="flex items-center text-gray-600"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <Bold01Icon class="icon h-5 w-5 stroke-[3.5px]" />
        </div>

        <div
          class="flex items-center text-gray-600"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <Italic01Icon class="icon h-5 w-5"></Italic01Icon>
        </div>

        <div
          class="flex items-center text-gray-600"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().run()"
        >
          <Strikethrough01Icon class="icon h-5 w-5"></Strikethrough01Icon>
        </div>
        <UIDivider class="divider" :vertical="true" />
        <UIDropdown
          id="dropdown-alignment"
          :options="alignmentOptions"
          @select="dropDownActionChange"
        >
          <AlignLeftIcon class="h-5 w-5 cursor-pointer outline-0" />
        </UIDropdown>
      </div>
      <UIDropdown
        v-if="isSubmitMessage"
        id="dropdown-extra-options"
        :options="extraOptions"
        @select="dropDownActionChange"
      >
        <DotsVerticalIcon class="h-5 w-5 cursor-pointer outline-0" />
      </UIDropdown>
    </div>
  </div>
  <LinkModal
    :show="showLinkModal"
    :url="linkValue"
    @update:success="setLink"
    @update:cancel="showLinkModal = false"
  />
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  Bold01Icon,
  DotsVerticalIcon,
  Heading01Icon,
  Italic01Icon,
  Link01Icon,
  LinkBroken01Icon,
  Pilcrow01Icon,
  Strikethrough01Icon,
} from "@gohighlevel/ghl-icons/24/outline";
import { renderIcon, UIDivider, UIDropdown } from "@gohighlevel/ghl-ui";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";
import { onMounted, ref, watch } from "vue";
import LinkModal from "./LinkModal.vue";

const emit = defineEmits(["update:modelValue"]);
const store = useAppStore();

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  isSubmitMessage: {
    type: Boolean,
    default: false,
  },
  editorHeight: {
    type: String,
    default: "84px",
  },
});

const editor = ref();
const linkValue = ref("");
const showLinkModal = ref(false);
const alignmentOptions = [
  {
    key: "alignLeft",
    icon: renderIcon(AlignLeftIcon),
  },
  {
    key: "alignCenter",
    icon: renderIcon(AlignCenterIcon),
  },
  {
    key: "alignRight",
    icon: renderIcon(AlignRightIcon),
  },
  {
    key: "alignJustify",
    icon: renderIcon(AlignJustifyIcon),
  },
];
const extraOptions = [
  {
    key: "link",
    icon: renderIcon(Link01Icon),
  },
  {
    key: "unlink",
    icon: renderIcon(LinkBroken01Icon),
  },
];
const dropDownActionChange = (action: string) => {
  switch (action) {
    case "alignLeft":
      editor.value.chain().focus().setTextAlign("left").run();
      break;
    case "alignCenter":
      editor.value.chain().focus().setTextAlign("center").run();
      break;
    case "alignRight":
      editor.value.chain().focus().setTextAlign("right").run();
      break;
    case "alignJustify":
      editor.value.chain().focus().setTextAlign("justify").run();
      break;
    case "link":
      showModal();
      break;
    case "unlink":
      editor.value.chain().focus().unsetLink().run();
      break;
    default:
  }
};

watch(
  () => props.modelValue,
  (newValue) => {
    const isSame = editor.value.getHTML() === newValue;
    if (isSame) {
      return;
    }
    editor.value.commands.setContent(newValue, false);
  }
);

onMounted(() => {
  editor.value = new Editor({
    content: props.modelValue,
    extensions: [
      StarterKit,
      TextAlign.configure({
        types: ["heading", "paragraph"],
        defaultAlignment: "left",
      }),
      Link.configure({
        autolink: false,
        openOnClick: false,
      }),
      Highlight,
    ],
    onUpdate: ({ editor }) => {
      emit("update:modelValue", editor.getHTML());
      store.addToHistory();
    },
  });
});
const showModal = () => {
  linkValue.value = editor.value.getAttributes("link").href;
  showLinkModal.value = true;
};
const setLink = (url: string) => {
  const checkHttp = new RegExp("^(http|https):|{{");
  if (url === "") {
    editor.value.chain().focus().extendMarkRange("link").unsetLink().run();
  }
  if (url && !checkHttp.test(url)) {
    url = "http://" + url;
  }
  editor.value.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  linkValue.value = url;
  showLinkModal.value = false;
};
</script>
<style scoped>
.rich-text-toolbar {
  background-color: #e2e2e2;
}
.icon {
  cursor: pointer;
  color: #101828;
}
.n-divider.n-divider--vertical {
  height: auto !important;
  background-color: #dee2e6;
}
.top-border {
  border-top: 1px solid #dee2e6;
}
.editor-style div:last-child div {
  word-break: break-word;
}

a {
  color: #68cef8;
}
.toolbar div > button {
  border: none;
}
.toolbar div > button > div > svg {
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: 0 0.2rem;
}

.toolbar .is-active {
  --tw-text-opacity: 1;
  color: #000;
}

.editor-style {
  hyphens: auto;
  word-wrap: break-word;
  border: 1px solid #dee2e6;
}
</style>

<style>
#richTextEditor .ProseMirror {
  overflow-wrap: anywhere;
  hyphens: auto;
  border-radius: 6px;
}
#richTextEditor .ProseMirror:focus-visible {
  outline: none !important;
  border: none !important;
}
/* set */
#richTextEditor .ProseMirror {
  min-height: 84px;
  overflow: auto;
  height: v-bind("props.editorHeight");
}

.n-dropdown-option-body__suffix {
  display: none !important;
}
</style>
