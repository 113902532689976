<script setup lang="ts">
import { useEmitter } from "@/composition/useEmitter";
import { useAppStore } from "@/store/app";
import { XCloseIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton, UIDrawer, UITabPane, UITabs } from "@gohighlevel/ghl-ui";
import { onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import FormCustomSidebar from "../../forms/FormCustomSidebar.vue";
import QuizElementSidebar from "../../quizzes/QuizElementSidebar.vue";
import SurveyCustomSidebar from "../../surveys/SurveyCustomSidebar.vue";
import Themes from "../Themes.vue";
import AdvanceSettings from "./AdvanceSettings.vue";
import SidebarOptions from "./OptionsSidebar.vue";
import SidebarStyles from "./Styles.vue";
const { t } = useI18n();

const store = useAppStore();
const emitter = useEmitter();

const formSidebarComponentKey = ref(0);
const surveySidebarComponentKey = ref(0);
const QuizElementSidebarKey = ref(0);
const forceRerenderForm = () => {
  formSidebarComponentKey.value += 1;
};
const forceRerenderSurvey = () => {
  surveySidebarComponentKey.value += 1;
};
const forceRerenderQuiz = () => {
  QuizElementSidebarKey.value += 1;
};

const show = ref(false);

const toggleOverlay = () => {
  show.value = !show.value;
  store.lastStatusOfRightDrawer = !store.lastStatusOfRightDrawer;
};

const closeOverlay = () => {
  show.value = false;
  store.lastStatusOfRightDrawer = false;
};

const closeAdvanceSettings = () => {
  store.isAdvanceSettingEnable = false;
  show.value = true;
};
const openAdvanceSettings = () => {
  store.isAdvanceSettingEnable = true;
  show.value = false;
};

onBeforeMount(() => {
  emitter.on("toggle-right-side-bar", toggleOverlay);
});

onBeforeUnmount(() => {
  emitter.off("toggle-right-side-bar", toggleOverlay);
});

watch(
  () => store.isAnyElementActive,
  (newData, oldData) => {
    if (newData) {
      show.value = false;
    } else if (store.lastStatusOfRightDrawer) {
      show.value = true;
    }
  }
);
// Reload Sidebar element settings
watch(
  () => store.currentCustomSidebarPreview,
  (newData, oldData) => {
    forceRerenderForm();
  }
);
watch(
  () => store.sidebarOverlaySettings,
  (newData, oldData) => {
    forceRerenderSurvey();
    forceRerenderQuiz();
  }
);
</script>

<template>
  <div>
    <div>
      <UIDrawer
        id="hl-builder-element-layout"
        :show="show"
        :width="360"
        placement="right"
        :show-mask="false"
        to="#builder-inner-container"
        display-directive="show"
        class="border-gray-100"
      >
        <div class="flex w-full justify-between p-2">
          <UIButton
            id="hl-builder-creator-header-close"
            :quaternary="true"
            text
            @click="closeOverlay"
          >
            <XCloseIcon class="h-5 w-5 text-gray-800" />
          </UIButton>
        </div>
        <div>
          <div id="sidebar-tab" class="sidebar-tab-container">
            <UITabs justify-content="space-evenly" default-value="styles">
              <UITabPane name="styles" :tab="t('common.styles')">
                <SidebarStyles :product="store.product" @open:advance="openAdvanceSettings" />
              </UITabPane>
              <UITabPane v-if="!store.quizId" name="themes" :tab="t('common.themes.themes')">
                <Themes :is-survey="store.surveyId ? true : false" />
              </UITabPane>
              <UITabPane name="options" :tab="t('common.options')">
                <SidebarOptions :product="store.product" />
              </UITabPane>
            </UITabs>
          </div>
        </div>
      </UIDrawer>
    </div>
  </div>
  <div>
    <div v-if="store.isAnyElementActive" class="sidebar-right">
      <FormCustomSidebar
        v-if="store.formId"
        id="form_custom_sidebar"
        :key="formSidebarComponentKey"
        :selected-index="store.currentCustomSidebarPreview"
      ></FormCustomSidebar>
      <SurveyCustomSidebar
        v-else-if="store.surveyId"
        id="survey_custom_sidebar"
        :key="surveySidebarComponentKey"
        :selected-element="store.sidebarOverlaySettings"
      >
      </SurveyCustomSidebar>
      <QuizElementSidebar
        v-else
        id="quiz_element_sidebar"
        :key="QuizElementSidebarKey"
        :selected-element="store.sidebarOverlaySettings"
      ></QuizElementSidebar>
    </div>
    <div v-if="store.isAdvanceSettingEnable" class="advance-settings-container">
      <AdvanceSettings @back-to-normal-settings="closeAdvanceSettings"></AdvanceSettings>
    </div>
  </div>
</template>

<style scoped>
.glh-search {
  position: relative;
}
.glh-search i {
  position: absolute;
  right: 15px;
  top: 18px;
}
.sidebar-tab-container {
  font-size: 14px;
}
#form_custom_sidebar,
#survey_custom_sidebar {
  overflow-y: scroll;
  scrollbar-width: none;
  height: 95vh;
  padding: 15px;
  padding-bottom: 60px;
}

#quiz_element_sidebar {
  overflow-y: scroll;
  scrollbar-width: none;
  height: 88vh;
  padding: 15px;
  padding-bottom: 60px;
}
#quiz_element_sidebar {
  padding: 0;
}
#form_custom_sidebar::-webkit-scrollbar {
  display: none;
}
#survey_custom_sidebar::-webkit-scrollbar,
#quiz_element_sidebar::-webkit-scrollbar {
  display: none;
}
#sidebar-tab .n-tabs-nav {
  width: 100%;
  z-index: 1;
  background: #ffffff;
  left: 0;
}
.close-icon-element {
  right: 18px;
  top: 18px;
  position: absolute;
  cursor: pointer;
}
.sidebar-right {
  position: fixed;
  right: 0;
  top: 98px;
  width: 100%;
  max-width: 360px;
  background-color: #ffffff;
  border: 1px solid #dee2e6 !important;
  height: 100%;
}

.advance-settings-container {
  position: fixed;
  right: 0;
  top: 98px;
  width: 100%;
  max-width: 360px;
  background-color: #ffffff;
  border: 1px solid #dee2e6 !important;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 90vh;
}
</style>
