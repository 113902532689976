<template>
  <UIModal :show="show" :width="472" @update:show="cancelCallback">
    <template #header>
      <UIModalHeader
        id="modal-header"
        type="primary"
        title="Manage Categories"
        description="You can edit or add new categories"
        @close="cancelCallback"
      >
      </UIModalHeader>
    </template>
    <UIModalContent>
      <div>
        <div
          v-for="(option, index) in category"
          :key="index"
          class="my-3 flex items-center text-sm"
        >
          <div class="mx-2 flex flex-1">
            <UIInput
              :id="`option-label-${index}`"
              :model-value="option.label"
              class="w-full"
              placeholder="Enter category name"
              :status="errors[index] ? 'error' : ''"
              @update:model-value="(value) => categoryRename(value, index)"
            />
          </div>
          <UIButton
            id="remove-option"
            :disabled="!option.label || index == 0"
            @click="() => prepareToRemoveOption(index)"
          >
            <Trash01Icon class="h-5 w-5" />
          </UIButton>
        </div>

        <UIButton id="add-option" text class="m-2" :disabled="isLastOptionEmpty" @click="addOption">
          <PlusIcon class="mr-1.5 h-5 w-5" />
          Add Category
        </UIButton>
      </div>
      <DeleteConfirmationModal
        :show="showDeleteModal"
        description="Deleting a catgeory will result into rest of that category in all the options"
        @confirm="removeOption"
        @cancel="cancelRemoveOption"
      />
    </UIModalContent>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        positive-text="Submit"
        negative-text="Cancel"
        type="primary"
        @negative-click="cancelCallback"
        @positive-click="positiveClick"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import { useEmitter } from "@/composition/useEmitter";
import { useAppStore } from "@/store/app";
import { PlusIcon, Trash01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton, UIInput, UIModal, UIModalFooter, UIModalHeader } from "@gohighlevel/ghl-ui";
import { cloneDeep } from "lodash";
import uuid4 from "uuid4";
import { computed, onMounted, reactive, ref, watch } from "vue";
import DeleteConfirmationModal from "../common/DeleteConfirmationModal.vue";
const store = useAppStore();
const emitter = useEmitter();

const emit = defineEmits(["update:closeModal"]);
const closeModal = () => emit("update:closeModal");

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const indexToRemove = ref(null);

const showDeleteModal = ref(false);

const errors = ref<string[]>([]);
const renamedCategory = [];
const category = reactive(cloneDeep(store.category));
const validateOptions = () => {
  errors.value = category.map((option) =>
    option.label?.trim() === "" ? "The option field is required" : ""
  );
};

watch(
  () => category,
  () => {
    validateOptions();
  },
  { deep: true }
);

const isLastOptionEmpty = computed(() => {
  const lastOption = category[category.length - 1];
  return lastOption.label?.trim() === "" && lastOption.value?.trim() === "";
});

const addOption = () => {
  category.push({ label: "", value: "", id: uuid4() });
  store.anyUnsavedChanges = true;
};

const prepareToRemoveOption = (index) => {
  if (category.length > 1) {
    indexToRemove.value = index;
    showDeleteModal.value = true;
  }
};

const categoryRename = (value, index) => {
  renamedCategory.push(category[index].id);
  category[index].label = value;
  if (index !== 0) {
    category[index].value = value.trim().replace(/\s/g, "_");
  }
  store.anyUnsavedChanges = true;
};

const removeOption = async () => {
  if (indexToRemove.value !== null) {
    const categoryToBedeleted = category[indexToRemove.value];
    category.splice(indexToRemove.value, 1);
    removeCategoryFromAllOptions(categoryToBedeleted);
    emitter.emit("category:rerender");
  }
  showDeleteModal.value = false;
  store.anyUnsavedChanges = true;
};

const cancelRemoveOption = () => {
  indexToRemove.value = null;
  showDeleteModal.value = false;
};

const cancelCallback = () => {
  closeModal();
};
const positiveClick = () => {
  store.category = category.filter((cat) => cat.label.trim() !== "");
  renamedCategory.forEach((id) => {
    renameCategoryFromAllOptions(id);
  });
  emitter.emit("category:rerender");
  closeModal();
};

const removeCategoryFromAllOptions = (categoryToBedeleted) => {
  store.slides.forEach((slide) => {
    slide.slideData.forEach((element) => {
      if (element.scoreByCategory) {
        Object.keys(element?.scoreByCategory).forEach((key) => {
          if (element.scoreByCategory[key].categoryId === categoryToBedeleted.id) {
            delete element.scoreByCategory[key];
          }
        });
      }
    });
  });
};
const renameCategoryFromAllOptions = (categoryId) => {
  const cat = store.category.find((c) => c.id === categoryId);
  store.slides.forEach((slide) => {
    slide.slideData.forEach((element) => {
      if (element.scoreByCategory) {
        Object.keys(element?.scoreByCategory).forEach((key) => {
          if (element.scoreByCategory[key].categoryId === categoryId) {
            element.scoreByCategory[key].category = cat.value;
          }
        });
      }
    });
  });
};
</script>
