<template>
  <section v-if="product === PRODUCT_TYPE.SURVEY" class="form_settings">
    <Switch
      :value="store.formAction.fieldSettingEnable"
      :label="t('survey.oneQuestionAtTime')"
      @update:value="updateOneQuestionAtTime"
    />
    <Switch
      :value="store.builderConfig.stickyContact"
      :label="t('common.stickyContact')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.stickyContact"
      @update:value="updateStickyContact"
    />
    <Switch
      v-if="product === PRODUCT_TYPE.SURVEY && !store?.builderConfig?.newFooter"
      :value="store.builderConfig.isBackButtonEnable"
      :label="t('survey.backButton')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.backButton"
      @update:value="updateBackButtonEnable"
    />
    <Switch
      v-if="product === PRODUCT_TYPE.SURVEY"
      :value="store.builderConfig.disableAutoNavigation"
      :label="t('survey.disableAutoNavigation')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.disableAutoNavigation"
      @update:value="updatedisableAutoNavigation"
    />
    <Switch
      v-if="product === PRODUCT_TYPE.SURVEY && !store?.builderConfig?.newFooter"
      :value="store.builderConfig.isProgressBarEnabled"
      :label="t('survey.progressBar')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.progressBar"
      @update:value="updateIsProgressBarEnabled"
    />
    <Switch
      v-if="product === PRODUCT_TYPE.SURVEY && !store?.builderConfig?.newFooter"
      :value="store.builderConfig.isAnimationDisabled"
      :label="t('survey.disableAnimation')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.disableAnimation"
      @update:value="updateIsAnimationDisabled"
    />
    <Switch
      v-if="product === PRODUCT_TYPE.SURVEY"
      :value="store.builderConfig.isSurveyScrollEnabled"
      :label="t('survey.scrollToTop')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.scrollToTop"
      @update:value="updateIsSurveyScrollEnabled"
    />
    <Switch
      :label="t('common.useGDPRCompliantFont')"
      :value="store.builderConfig.isGDPRCompliant"
      @update:value="handleGDPRCompliant"
    />
    <Switch
      v-model:value="store.builderConfig.enableTimezone"
      :label="t('common.enableTimezone')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.enableTimezoneSurvey"
      @update:value="store.anyUnsavedChanges = true"
    />
  </section>
  <section v-else-if="product === PRODUCT_TYPE.QUIZ" class="form_settings">
    <Switch
      :value="store.builderConfig.stickyContact"
      :label="t('common.stickyContact')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.stickyContact"
      @update:value="updateStickyContact"
    />
    <Switch
      :value="store.builderConfig.isBackButtonEnable"
      :label="t('survey.backButton')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.backButton"
      @update:value="updateBackButtonEnable"
    />
    <Switch
      :value="store.builderConfig.isAnimationDisabled"
      :label="t('survey.disableAnimation')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.disableAnimation"
      @update:value="updateIsAnimationDisabled"
    />
    <Switch
      :value="store.builderConfig.isSurveyScrollEnabled"
      :label="t('survey.scrollToTop')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.scrollToTop"
      @update:value="updateIsSurveyScrollEnabled"
    />
    <Switch
      :label="t('common.useGDPRCompliantFont')"
      :value="store.builderConfig.isGDPRCompliant"
      @update:value="handleGDPRCompliant"
    />
    <Switch
      v-model:value="store.builderConfig.enableTimezone"
      :label="t('common.enableTimezone')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.enableTimezoneSurvey"
      @update:value="store.anyUnsavedChanges = true"
    />
  </section>
  <section v-else class="form_settings">
    <Switch
      :value="store.builderConfig.stickyContact"
      :label="t('common.stickyContact')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.stickyContact"
      @update:value="updateStickyContact"
    />
    <Switch
      :label="t('common.useGDPRCompliantFont')"
      :value="store.builderConfig.isGDPRCompliant"
      @update:value="handleGDPRCompliant"
    />
    <Switch
      v-model:value="store.builderConfig.enableTimezone"
      :label="t('common.enableTimezone')"
      :tooltip="true"
      :tooltip-message="tooltipMessage.enableTimezoneForm"
      @update:value="store.anyUnsavedChanges = true"
    />
  </section>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { PRODUCT_TYPE, tooltipMessage } from "@/util/constants";
import { useI18n } from "vue-i18n";
import Switch from "../Elements/Switch.vue";

const { t } = useI18n();
const store = useAppStore();

defineProps({
  product: {
    type: String,
    default: PRODUCT_TYPE.FORM,
    required: true,
  },
});

const updateOneQuestionAtTime = async (value: boolean) => {
  store.formAction.fieldSettingEnable = value;
  store.anyUnsavedChanges = true;
  await slideManage(value);
};
const updateStickyContact = (value: boolean) => {
  store.builderConfig.stickyContact = value;
  store.anyUnsavedChanges = true;
};
const updateBackButtonEnable = (value: boolean) => {
  store.builderConfig.isBackButtonEnable = value;
  store.anyUnsavedChanges = true;
};
const updatedisableAutoNavigation = (value: boolean) => {
  store.builderConfig.disableAutoNavigation = value;
  store.anyUnsavedChanges = true;
};
const updateIsProgressBarEnabled = (value: boolean) => {
  store.builderConfig.isProgressBarEnabled = value;
  store.anyUnsavedChanges = true;
};
const updateIsAnimationDisabled = (value: boolean) => {
  store.builderConfig.isAnimationDisabled = value;
  store.anyUnsavedChanges = true;
};
const updateIsSurveyScrollEnabled = (value: boolean) => {
  store.builderConfig.isSurveyScrollEnabled = value;
  store.anyUnsavedChanges = true;
};

const handleGDPRCompliant = (val: boolean) => {
  store.builderConfig.isGDPRCompliant = val;
  store.anyUnsavedChanges = true;
};

const slideManage = async (isOneQuestionEnabled) => {
  const tempKey = "survey_temp_slides_" + store.surveyId;
  const tempKeyOneQuestion = "survey_onequestion_" + store.surveyId;
  const slideLength = store.slides.length;

  if (isOneQuestionEnabled && store.slides.length === 1) {
    localStorage.setItem(tempKey, JSON.stringify(store.slides));
    //Deleting disabled one quesiton at a time logic as we will get it later from storage.
    delete store.slides[0].logic;
    //Getting if any one question at a time logic is present or not
    const oneQuestionLogicTemp = JSON.parse(localStorage.getItem(tempKeyOneQuestion));
    if (oneQuestionLogicTemp) {
      store.slides[0].logic = oneQuestionLogicTemp;
    }
  }
  if (isOneQuestionEnabled && store.slides.length > 1) {
    const mappedSlideFromOtherElements = [];
    store.slides.forEach((slide, index) => {
      slide.slideData.forEach((element) => {
        mappedSlideFromOtherElements.push(element);
      });
    });
    localStorage.setItem(tempKey, JSON.stringify(store.slides));
    //Deleting disabled one quesiton at a time logic as we will get it later from storage.
    store.slides.forEach((slide, index) => {
      delete slide.logic;
    });
    store.slides[0].slideData = mappedSlideFromOtherElements;
    store.slides.splice(1, slideLength);
    //Getting if any one question at a time logic is present or not
    const oneQuestionLogicTemp = JSON.parse(localStorage.getItem(tempKeyOneQuestion));
    if (oneQuestionLogicTemp) {
      store.slides[0].logic = oneQuestionLogicTemp;
    }
  }

  if (!isOneQuestionEnabled) {
    //If one question at a time logic is present then storing it in localstorage for future use
    if (store.slides[0].logic) {
      localStorage.setItem(tempKeyOneQuestion, JSON.stringify(store.slides[0].logic));
    }

    const tempSlides = localStorage.getItem(tempKey);
    if (tempSlides) {
      store.slides = JSON.parse(tempSlides);
    }
  }
};
</script>

<style>
.form_settings {
  display: flex;
  align-self: stretch;
  justify-items: flex-start;
  flex-direction: column;
  gap: 16px;
}
</style>
