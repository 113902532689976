<template>
  <div
    v-if="store.payment.paymentType !== 'product'"
    class="flex flex-col items-start gap-[15px] self-stretch"
  >
    <div v-if="store.payment.suggestionOn" class="flex flex-wrap items-start gap-[9px]">
      <div
        v-for="(amount, index) in store.payment.suggestedAmount"
        :key="index"
        class="payment-tag flex flex-[0_1_1] items-center justify-center rounded-[22px] border-2 border-solid border-gray-200 p-[12px]"
      >
        <label class="mb-0 w-[81px] justify-center text-center text-sm text-gray-700">
          <span>{{ store.payment.currency.symbol }} &nbsp;</span>{{ amount }}</label
        >
      </div>
      <div v-if="store.payment.enableOtherAmount">
        <div
          class="payment-tag flex flex-[0_1_1] items-center justify-center rounded-[22px] border-2 border-solid border-gray-200 p-[12px]"
        >
          <label class="mb-0 justify-center text-center text-sm text-gray-700">Other amount</label>
        </div>
      </div>
    </div>
    <div v-else class="payment-input-container relative inline-block w-[100%]">
      <input
        id="paymentInput"
        :placeholder="store.payment.amountPlaceHolder"
        :class="inputClass"
        class="form-control"
        :style="{
          fontSize: store.fieldStyle.placeholderFontSize + 'px',
        }"
        :readonly="true"
      />
      <span class="currency-code"> {{ store.payment.currency.code }}</span>
    </div>
    <span v-if="payment.shortLabel" class="short-label">{{ payment.shortLabel }}</span>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
const store = useAppStore();

defineProps({
  payment: {
    type: Object,
    default: () => {
      return {};
    },
  },
  inputClass: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
</script>

<style>
#payment-input-container {
  margin: auto;
  position: relative;
  display: inline-block;
}

#paymentInput {
  padding-right: 35px;
}
.currency-code {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(-50%, -50%);
}
</style>
