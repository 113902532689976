<template>
  <div id="colorPicker" class="flex flex-col items-start gap-2 self-stretch">
    <UITextXsMedium>{{ label }} </UITextXsMedium>
    <div class="color-picker-container" :class="flatRadius ? 'left-zero' : ''">
      <div class="color-picker" :class="compact ? 'compact' : ''">
        <UIColorPicker
          :value="defaultValue"
          type="default"
          @update:value="handleColorPickerInput"
          @on-complete="store.addToHistory"
        />
        <UITextSmNormal>#{{ color }}</UITextSmNormal>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UIColorPicker, UITextSmNormal, UITextXsMedium } from "@gohighlevel/ghl-ui";
import { ref, watch } from "vue";
const store = useAppStore();

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "FFFFFF",
  },
  compact: {
    type: Boolean,
    default: false,
  },
  flatRadius: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:color"]);
const defaultValue = ref("#" + props.color);

watch(
  () => props.color,
  (newColor) => (defaultValue.value = `#${newColor}`)
);

const handleColorPickerInput = (value) => {
  const color = value.replace("#", "");
  const isValidColor: boolean = /(^[0-9A-F]{8}$)|(^[0-9A-F]{6}$)|(^[0-9A-F]{3}$)/i.test(color);
  if (!isValidColor) {
    return;
  }
  defaultValue.value = value;
  emit("update:color", color);
};
</script>

<style scoped>
.color-picker-container {
  display: flex;
  padding: 7px 12px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.color-picker {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.compact {
  gap: 0px;
}

#colorPicker .n-color-picker {
  width: 24px;
  height: 24px;
}
.v-binder-follower-content {
  z-index: 11000 !important;
}

.left-zero {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0px !important;
}
</style>
