<template>
  <ColorInput
    :label="t('common.style.shortLabelColor')"
    :color="store?.fieldStyle?.shortLabel?.color"
    @update:color="handleShortLabelColor"
  />
  <div class="flex items-start gap-3">
    <FontFamilyInput
      :label="t('common.fontFamily')"
      :value="store?.fieldStyle?.shortLabel?.fontFamily"
      select-class="w-[122px]"
      @update:value="handleShortLabelFontFamily"
    />
    <PixelNumber
      :label="t('common.style.fontSize')"
      :value="parseFloat(store?.fieldStyle?.shortLabel?.fontSize)"
      @update:value="handleShortLabelFontSize"
    />
    <PixelNumber
      :label="t('common.style.fontWeight')"
      :value="parseFloat(store?.fieldStyle?.shortLabel?.fontWeight)"
      :max="1800"
      @update:value="handleShortLabelFontWeight"
    />
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { useI18n } from "vue-i18n";
import ColorInput from "../Elements/ColorInput.vue";
import FontFamilyInput from "../Elements/FontFamilyInput.vue";
import PixelNumber from "../Elements/PixelNumber.vue";

const { t } = useI18n();
const store = useAppStore();

const handleShortLabelColor = (val: string) => {
  store.fieldStyle.shortLabel.color = val;
  store.anyUnsavedChanges = true;
};
const handleShortLabelFontFamily = (val: string) => {
  if (!store.fontCollection.includes(val)) {
    store.fontCollection.push(val);
  }
  store.fieldStyle.shortLabel.fontFamily = val;
  store.anyUnsavedChanges = true;
};
const handleShortLabelFontSize = (val: number) => {
  store.fieldStyle.shortLabel.fontSize = val;
  store.anyUnsavedChanges = true;
};
const handleShortLabelFontWeight = (val: number) => {
  store.fieldStyle.shortLabel.fontWeight = val;
  store.anyUnsavedChanges = true;
};
</script>
