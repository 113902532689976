import config from "@/config";
import { requests } from ".";

const PAYMENT_SERVER_URL = config?.PAYMENT_SERVER_URL;
const PRODUCT_SERVER_URL = config?.PRODUCT_SERVER_URL;

export const PaymentServices = {
  integrations: (locationId: String) =>
    requests(PAYMENT_SERVER_URL).get(`/payments/integrations/${locationId}`),
  getCurrency: () => requests(PAYMENT_SERVER_URL).get("/payments/currency"),
  getProductList: (locationId, search, skip?, limit?, productIds?) =>
    requests(PRODUCT_SERVER_URL).get(
      `/products/?locationId=${locationId}&search=${search}&offset=${skip}&limit=${limit}${
        productIds ? `&productIds = ${productIds}` : ""
      }`
    ),
};
