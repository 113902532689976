<script setup lang="ts">
import { useEmitter } from "@/composition/useEmitter";
import { useAppStore } from "@/store/app";
import {
  CATEGORY_FIELDS,
  DEFAULT_LABEL_COLOR,
  DEFAULT_TEXT_COLOR,
  OPTIONS_FIELD,
  PRODUCT_TYPE,
} from "@/util/constants";
import { applyStyles } from "@/util/methods";
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  ArrowLeftIcon,
  Edit01Icon,
  InfoCircleIcon,
} from "@gohighlevel/ghl-icons/24/outline";
import {
  UIAlert,
  UIButton,
  UICheckbox,
  UICollapse,
  UICollapseItem,
  UIDivider,
  UIInput,
  UIInputGroup,
  UIInputGroupLabel,
  UIInputNumber,
  UISwitch,
  UITooltip,
} from "@gohighlevel/ghl-ui";
import { snakeCase } from "lodash";
import { storeToRefs } from "pinia";
import { computed, onBeforeMount, onBeforeUnmount, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Card from "../common/Card.vue";
import CodeEditorModel from "../common/CodeEditorModel.vue";
import ColorInputField from "../common/ColorInputField.vue";
import CountInput from "../common/CountInput.vue";
import DateSettings from "../common/DateSettings.vue";
import DropdownEditor from "../common/DropdownEditor.vue";
import EditOpportunitySettingsModal from "../common/EditOpportunitySettingsModal.vue";
import Switch from "../common/Elements/Switch.vue";
import FileUploadEditor from "../common/FileUploadEditor.vue";
import ListEditor from "../common/ListEditor.vue";
import RadioSelectEditor from "../common/RadioSelectEditor.vue";
import RichTextEditor from "../common/RichTextEditor.vue";
import ScoreEditor from "../common/ScoreEditor.vue";
import TextSidebar from "../common/TextSidebar.vue";

const store = useAppStore();
const emitter = useEmitter();

const { t } = useI18n();
const { getCustomFieldsFromStore } = storeToRefs(store);
const props = defineProps({
  selectedElement: {
    type: Object,
    default() {
      return {};
    },
  },
});

const { slideIndex, elementIndex } = store.getSlideIndexAndElementIndex(props.selectedElement);
//Get Slide Settings or Slide Elements Options Settings
const selectedElementStore = reactive(
  props.selectedElement.slideId && props.selectedElement.elementId
    ? store.slides[slideIndex].slideData[elementIndex]
    : store.slides[slideIndex]
);

const dateElement = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex];
});

const showModal = ref(false);
const uniqueKeyPlaceholder = ref(snakeCase(selectedElementStore?.label));

const showModelUpdate = (modalUpdate: boolean) => {
  showModal.value = modalUpdate;
};

const submitUpdate = (updatedCode: string) => {
  selectedElementStore.html = updatedCode;
  store.anyUnsavedChanges = true;
};

const onClickOfEditHTML = () => {
  showModal.value = !showModal.value;
};

const onBack = () => {
  store.currentCustomSidebarPreview = undefined;
  store.isAnyElementActive = false;
};

const getSlideName = computed(() => {
  return store.slides[slideIndex].slideName;
});

const getLabel = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex].label;
});

const getTypeLabel = computed(() => {
  return (
    store.slides[slideIndex].slideData[elementIndex]?.typeLabel ||
    (selectedElementStore.tag === "header" ? "Text" : selectedElementStore.label) ||
    selectedElementStore.name
  );
});

const getUniqueKey = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex]?.fieldKey;
});

const updatePicklistOptions = (options) => {
  if (CATEGORY_FIELDS.includes(store.slides[slideIndex].slideData[elementIndex]?.dataType)) {
    store.slides[slideIndex].slideData[elementIndex].picklistOptions = options.map(
      (option) => option.label
    );
  } else {
    store.slides[slideIndex].slideData[elementIndex].picklistOptions = options;
  }
  if (store.slides[slideIndex].slideData[elementIndex]?.id) {
    store.slides[slideIndex].slideData[elementIndex].customfieldUpdated = true;
  }
  store.anyUnsavedChanges = true;
};

const updateUniqueKey = (value: string) => {
  store.slides[slideIndex].slideData[elementIndex].fieldKey = value;
  store.anyUnsavedChanges = true;
};

const getPlaceholder = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex].placeholder;
});

const getShortLabel = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex].shortLabel;
});

const getHiddenQuery = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex].hiddenFieldQueryKey;
});

const getRequired = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex].required;
});

const getHidden = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex].hidden;
});

const getEnableCountryPicker = computed(() => {
  if (store.slides[slideIndex].slideData[elementIndex].enableCountryPicker === undefined) {
    return true;
  }
  return store.slides[slideIndex].slideData[elementIndex].enableCountryPicker;
});

const enableValidateEmailModel = computed({
  get() {
    return store.slides[slideIndex].slideData[elementIndex]?.enableValidateEmail ?? false;
  },
  set(value: boolean) {
    store.slides[slideIndex].slideData[elementIndex].enableValidateEmail = value;
    store.anyUnsavedChanges = true;
  },
});

const enableValidatePhoneModel = computed({
  get() {
    return store.slides[slideIndex].slideData[elementIndex]?.enableValidatePhone ?? false;
  },
  set(value: boolean) {
    store.slides[slideIndex].slideData[elementIndex].enableValidatePhone = value;
    store.anyUnsavedChanges = true;
  },
});

const getHiddenValue = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex].hiddenFieldValue;
});

const getSource = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex].value;
});

const getSpreadColumns = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex]?.spreadColumns;
});
const getNumberOfColumns = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex]?.columnsNumber;
});

const getDefaultNumberOfColumns = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex]?.picklistOptionsImage ? undefined : 1;
});

const isHiddenFieldDisable = computed(() =>
  Boolean(store.slides[slideIndex].slideData[elementIndex]?.required)
);

const isRequiredFieldDisable = computed(() =>
  Boolean(store.slides[slideIndex].slideData[elementIndex]?.hidden)
);

const questionOptions = computed(() => {
  const currentElementUuid = selectedElementStore.uuid;
  return store.getQuizQuestionDropdown(currentElementUuid);
});

onBeforeMount(() => {
  emitter.on("logic:update", (receviedLogic) => {
    if (store.logic[receviedLogic.current]) {
      store.logic[receviedLogic.current][receviedLogic.index] = receviedLogic;
    } else {
      store.logic[receviedLogic.current] = {};
      store.logic[receviedLogic.current][receviedLogic.index] = receviedLogic;
    }
    store.anyUnsavedChanges = true;
  });
  emitter.on("logic:sort", (receviedLogic) => {
    if (store.logic[receviedLogic.current]) {
      delete store.logic[receviedLogic.current][receviedLogic.newIndex];
      delete store.logic[receviedLogic.current][receviedLogic.oldIndex];
    }
    store.anyUnsavedChanges = true;
  });
  emitter.on("category:update", (category) => {
    if (store.slides[category.slideIndex].slideData[category.elementIndex].scoreByCategory) {
      store.slides[category.slideIndex].slideData[category.elementIndex].scoreByCategory[
        category.index
      ] = category;
    } else {
      store.slides[category.slideIndex].slideData[category.elementIndex].scoreByCategory = {};
      store.slides[category.slideIndex].slideData[category.elementIndex].scoreByCategory[
        category.index
      ] = category;
    }
    store.anyUnsavedChanges = true;
  });
  emitter.on("category:sort", (category) => {
    if (store.slides[category.slideIndex].slideData[category.elementIndex].scoreByCategory) {
      delete store.slides[category.slideIndex].slideData[category.elementIndex].scoreByCategory[
        category.oldIndex
      ];
      delete store.slides[category.slideIndex].slideData[category.elementIndex].scoreByCategory[
        category.newIndex
      ];
      forceRenderOptionFields();
    }
    store.anyUnsavedChanges = true;
  });
  emitter.on("category:rerender", () => {
    forceRenderOptionFields();
    store.anyUnsavedChanges = true;
  });
  emitter.on("option:deleted", (deleteLogic) => {
    if (store.logic[deleteLogic.current]) {
      delete store.logic[deleteLogic.current];
    }
    if (store.slides[deleteLogic.slideIndex].slideData[deleteLogic.elementIndex].scoreByCategory) {
      delete store.slides[deleteLogic.slideIndex].slideData[deleteLogic.elementIndex]
        .scoreByCategory;
    }
    store.anyUnsavedChanges = true;
  });
});
const optionModified = ref(0);

const forceRenderOptionFields = () => {
  optionModified.value += 1;
};

onBeforeUnmount(() => {
  emitter.off("logic:update");
  emitter.off("logic:sort");
  emitter.off("category:update");
  emitter.off("category:sort");
  emitter.off("category:rerender");
  emitter.off("option:deleted");
});

const handleImageWidth = (val) => {
  store.slides[slideIndex].slideData[elementIndex].width = val;
  store.anyUnsavedChanges = true;
};

const handleImageHeight = (val) => {
  store.slides[slideIndex].slideData[elementIndex].height = val;
  store.anyUnsavedChanges = true;
};

const handleTextColor = (val: string) => {
  store.slides[slideIndex].slideData[elementIndex].textColor = val;
  store.anyUnsavedChanges = true;
};

const handleLinkColor = (val: string) => {
  store.slides[slideIndex].slideData[elementIndex].linkColor = val;
  store.anyUnsavedChanges = true;
};

const getAltImage = computed(() => {
  return store.slides[slideIndex].slideData[elementIndex].alt;
});

const alignImage = (align) => {
  store.slides[slideIndex].slideData[elementIndex].align = align;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};

const updatePreview = (element) => {
  element.preview = applyStyles(element.placeholder, element.linkColor, element.textColor);
};

const updateCalculation = (calculation) => {
  store.slides[slideIndex].slideData[elementIndex].calculation = calculation;
  store.anyUnsavedChanges = true;
};

const saveOpportunitySettings = (settings) => {
  store.opportunitySettings = settings;
  store.anyUnsavedChanges = true;
  store.showOpportunitiesModal = false;
};

const tncElement = store.slides[slideIndex].slideData[elementIndex];

watch(
  () => [tncElement?.placeholder, tncElement?.linkColor, tncElement?.textColor],
  () => {
    updatePreview(tncElement);
  }
);

//To Maintain Reactivity of Store
const handleTextUpdate = (output) => {
  Object.assign(store.slides[slideIndex].slideData[elementIndex], output);
};

const updateSlideName = (value: string) => {
  store.slides[slideIndex].slideName = value;
  store.anyUnsavedChanges = true;
};

const updateLabel = (value: string) => {
  uniqueKeyPlaceholder.value = snakeCase(value);
  if (
    selectedElementStore?.dataType === "SCORE" &&
    !uniqueKeyPlaceholder.value.startsWith("score_")
  ) {
    uniqueKeyPlaceholder.value = "score_" + uniqueKeyPlaceholder.value;
  }
  updateUniqueKey(uniqueKeyPlaceholder.value);
  store.slides[slideIndex].slideData[elementIndex].label = value;
  store.anyUnsavedChanges = true;
};

const updatePlaceholder = (value: string) => {
  store.slides[slideIndex].slideData[elementIndex].placeholder = value;
  store.anyUnsavedChanges = true;
};

const updateShortLabel = (value: string) => {
  store.slides[slideIndex].slideData[elementIndex].shortLabel = value;
  store.anyUnsavedChanges = true;
};

const updateHiddenQuery = (value: string) => {
  store.slides[slideIndex].slideData[elementIndex].hiddenFieldQueryKey = value;
  store.anyUnsavedChanges = true;
};

const updateRequired = (value: boolean) => {
  store.slides[slideIndex].slideData[elementIndex].required = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateHidden = (value: boolean) => {
  store.slides[slideIndex].slideData[elementIndex].hidden = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateEnableCountryPicker = (value: boolean) => {
  store.slides[slideIndex].slideData[elementIndex].enableCountryPicker = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateHiddenValue = (value: string) => {
  store.slides[slideIndex].slideData[elementIndex].hiddenFieldValue = value;
  store.anyUnsavedChanges = true;
};

const updateSource = (value: string) => {
  store.slides[slideIndex].slideData[elementIndex].value = value;
  store.anyUnsavedChanges = true;
};

const updateAltImage = (value: string) => {
  store.slides[slideIndex].slideData[elementIndex].alt = value;
  store.anyUnsavedChanges = true;
};

const updateDateFormat = (value: string) => {
  store.slides[slideIndex].slideData[elementIndex].format = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};
const updateDateSeparator = (value: string) => {
  store.slides[slideIndex].slideData[elementIndex].separator = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateDatedisablePicker = (value: boolean) => {
  store.slides[slideIndex].slideData[elementIndex].disablePicker = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateSpreadColumns = (value: boolean) => {
  store.slides[slideIndex].slideData[elementIndex].spreadColumns = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};
const updateNumberOfColumns = (value: number) => {
  store.slides[slideIndex].slideData[elementIndex].columnsNumber = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};
</script>

<template>
  <section id="quizContainerSettings" class="flex flex-col gap-2">
    <div class="relative mt-2 flex items-center justify-center">
      <div class="arrow-button absolute left-0 ml-4" @click="onBack">
        <ArrowLeftIcon class="h-4 w-4 text-gray-800" />
      </div>
      <div class="text-md text-gray-900">{{ getTypeLabel }}</div>
      <div v-if="selectedElement.elementId === undefined" class="text-md text-gray-900">
        {{ selectedElementStore.slideName }}
      </div>
    </div>
    <div id="quizSettings">
      <div v-if="selectedElement.elementId === undefined" class="flex flex-col p-3">
        <div class="form-group">
          <div class="flex space-x-3">
            <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
              t("quiz.pageName")
            }}</span>
          </div>
          <UIInput
            id="pageName"
            class="standard-input-ghl"
            :model-value="getSlideName"
            @update:model-value="updateSlideName"
            @blur="store.addToHistory()"
          >
          </UIInput>
        </div>
        <div class="form-group">
          <div class="flex space-x-3">
            <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
              t("quiz.pagePosition")
            }}</span>
          </div>
          <UIInputNumber
            id="pagePosition"
            :value="slideIndex + 1"
            :disabled="true"
            size="large"
            :show-button="false"
            class="standard-input-ghl"
            @blur="store.addToHistory()"
          ></UIInputNumber>
        </div>
      </div>
      <UICollapse id="question" :arrow-placement="'left'" :default-expanded-names="['Question']">
        <UICollapseItem
          v-if="selectedElement.elementId !== undefined"
          id="general-settings"
          title="Question"
          name="Question"
        >
          <div v-if="selectedElementStore?.type == 'html'">
            <div class="form-group">
              <Card :header="selectedElementStore.placeholder">
                <template #default>
                  <UIButton
                    id="editHtml"
                    type="default"
                    class="w-[100%]"
                    @click="onClickOfEditHTML"
                    >{{ t("common.editHTML") }}</UIButton
                  >
                </template>
              </Card>
            </div>
            <div>
              <CodeEditorModel
                v-if="showModal"
                :model-active="showModal"
                :code="store.slides[slideIndex].slideData[elementIndex].html"
                @update:show-model="showModelUpdate"
                @update:submit="submitUpdate"
              />
            </div>
          </div>
          <div v-else-if="selectedElementStore.tag === 'header'">
            <TextSidebar
              :property="
                JSON.parse(JSON.stringify(store.slides[slideIndex].slideData[elementIndex]))
              "
              @update:text="handleTextUpdate"
            />
          </div>
          <div v-else-if="selectedElementStore.tag === 'source'">
            <h6 class="field-name-sidebar">{{ selectedElementStore.label }}</h6>
            <div class="form-group">
              <UIInput
                id="sourceLabel"
                class="standard-input-ghl"
                :placeholder="t('common.enterSourceValue')"
                :model-value="getSource"
                @update:model-value="updateSource"
                @blur="store.addToHistory()"
              ></UIInput>
            </div>
          </div>
          <div v-else-if="selectedElementStore.tag === 'captcha'">
            <div class="form-group">
              <div class="flex space-x-3">
                <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
                  t("common.label")
                }}</span>
              </div>
              <UIInput
                id="captchaLabel"
                class="standard-input-ghl"
                :model-value="getLabel"
                @update:model-value="updateLabel"
                @blur="store.addToHistory()"
              >
              </UIInput>
            </div>
          </div>
          <div v-else-if="selectedElementStore.tag === 'image'">
            <h6 class="field-name-sidebar">
              {{ selectedElementStore.label }}
            </h6>
            <div class="form-group">
              <div class="flex space-x-3">
                <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
                  t("common.altText")
                }}</span>
              </div>
              <UIInput
                id="altText"
                class="standard-input-ghl"
                :model-value="getAltImage"
                @update:model-value="updateAltImage"
                @blur="store.addToHistory()"
              ></UIInput>
            </div>
            <div class="form-group">
              <div class="flex space-x-3">
                <div class="alignment">
                  <div class="alignment-div">{{ t("common.alignment") }}</div>
                  <div class="icons-collection">
                    <AlignLeftIcon
                      class="custom-icons h-5 w-5"
                      @click="alignImage('left')"
                    ></AlignLeftIcon>
                    <AlignCenterIcon
                      class="custom-icons h-5 w-5"
                      @click="alignImage('center')"
                    ></AlignCenterIcon>
                    <AlignRightIcon
                      class="custom-icons h-5 w-5"
                      @click="alignImage('right')"
                    ></AlignRightIcon>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="flex space-x-3">
                <CountInput
                  :label="t('common.imageWidth')"
                  :value="selectedElementStore.width || ''"
                  :max="1800"
                  @update:value="handleImageWidth"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="flex space-x-3">
                <CountInput
                  :label="t('common.imageHeight')"
                  :value="selectedElementStore.height || ''"
                  :max="1800"
                  @update:value="handleImageHeight"
                />
              </div>
            </div>
          </div>
          <div v-else-if="selectedElementStore.tag === 'terms_and_conditions'">
            <div class="form-group flex flex-col gap-y-4">
              <div>
                <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
                  t("common.text")
                }}</span>
                <RichTextEditor
                  :is-submit-message="true"
                  :model-value="getPlaceholder"
                  @update:model-value="updatePlaceholder"
                ></RichTextEditor>
              </div>
              <div>
                <div class="flex space-x-3">
                  <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
                    {{ t("common.queryKey") }}
                    <UITooltip trigger="hover" placement="top">
                      <template #trigger>
                        <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                      </template>
                      {{ t("common.querykeyTooltipMessage") }}
                    </UITooltip>
                  </div>
                </div>
                <div>
                  <UIInput
                    id="fieldQueryKey"
                    class="standard-input-ghl"
                    :model-value="getHiddenQuery"
                    @update:model-value="updateHiddenQuery"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>
              </div>
              <div class="form-check-req-hid">
                <UICheckbox
                  id="fieldRequired"
                  :checked="getRequired"
                  @update:checked="updateRequired"
                  >{{ t("common.required") }}</UICheckbox
                >
              </div>
              <div>
                <span class="hl-text-input-label mt-1 block text-sm font-medium text-gray-700">{{
                  t("common.color")
                }}</span>
                <div class="flex">
                  <ColorInputField
                    class="w-2/3"
                    :label="t('common.text')"
                    :color="
                      store.slides[slideIndex].slideData[elementIndex]?.textColor ||
                      DEFAULT_TEXT_COLOR
                    "
                    @update:color="handleTextColor"
                  />
                  <ColorInputField
                    class="w-2/3"
                    :label="t('common.link')"
                    :color="
                      store.slides[slideIndex].slideData[elementIndex]?.linkColor ||
                      DEFAULT_LABEL_COLOR
                    "
                    @update:color="handleLinkColor"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="['date'].includes(dateElement.type)">
            <DateSettings
              :date-element="dateElement"
              :index="selectedElement.index"
              :is-required-field-disable="isRequiredFieldDisable"
              :is-hidden-field-disable="isHiddenFieldDisable"
              @update-label="updateLabel"
              @update-placeholder="updatePlaceholder"
              @update-short-label="updateShortLabel"
              @update-hidden-query="updateHiddenQuery"
              @update-required="updateRequired"
              @update-hidden="updateHidden"
              @update-hidden-value="updateHiddenValue"
              @update-date-format="updateDateFormat"
              @update-date-separator="updateDateSeparator"
              @update-date-disable-picker="updateDatedisablePicker"
            ></DateSettings>
            <div v-if="selectedElementStore?.model === 'opportunity'">
              <UIDivider />
              <div class="m-4 flex justify-center">
                <UIButton
                  id="edit-opportunity-settings"
                  type="tertiary"
                  @click="store.showOpportunitiesModal = true"
                >
                  <Edit01Icon class="mr-2 h-4 w-4" /> {{ t("common.editOpportunitySettings") }}
                </UIButton>
              </div>
            </div>

            <EditOpportunitySettingsModal
              :show="store.showOpportunitiesModal"
              @update:success="saveOpportunitySettings"
              @update:cancel="store.showOpportunitiesModal = false"
            />
          </div>
          <div v-else>
            <div class="form-group">
              <div class="flex space-x-3">
                <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
                  t("common.question")
                }}</span>
              </div>
              <UIInput
                id="fieldTitle"
                class="standard-input-ghl"
                :model-value="getLabel"
                @update:model-value="updateLabel"
                @blur="store.addToHistory()"
              >
              </UIInput>
            </div>
            <div
              v-if="!['TEXTBOX_LIST', 'SIGNATURE'].includes(selectedElementStore?.dataType)"
              class="form-group"
            >
              <div class="flex space-x-3">
                <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
                  t("common.placeholder")
                }}</span>
              </div>
              <UIInput
                id="fieldPlaceholder"
                class="standard-input-ghl"
                :model-value="getPlaceholder"
                @update:model-value="updatePlaceholder"
                @blur="store.addToHistory()"
              ></UIInput>
            </div>
            <div
              v-if="
                !['image', 'header', 'button', 'terms_and_conditions'].includes(
                  selectedElementStore.tag
                )
              "
              class="form-group"
            >
              <div class="flex space-x-3">
                <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
                  t("common.shortLabel")
                }}</span>
              </div>
              <UIInput
                id="shortLabel"
                class="standard-input-ghl"
                :model-value="getShortLabel"
                @update:model-value="updateShortLabel"
                @blur="store.addToHistory()"
              >
              </UIInput>
            </div>
            <div
              v-if="
                !['TEXTBOX_LIST', 'MULTIPLE_OPTIONS', 'FILE_UPLOAD', 'SIGNATURE'].includes(
                  selectedElementStore?.dataType
                )
              "
              class="form-group"
            >
              <div class="flex space-x-3">
                <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
                  {{ t("common.queryKey") }}
                  <UITooltip trigger="hover" placement="top">
                    <template #trigger>
                      <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                    </template>
                    {{ t("common.querykeyTooltipMessage") }}
                  </UITooltip>
                </div>
              </div>
              <UIInput
                id="fieldQueryKey"
                class="standard-input-ghl"
                :model-value="getHiddenQuery"
                @update:model-value="updateHiddenQuery"
                @blur="store.addToHistory()"
              ></UIInput>
            </div>
            <div class="form-check-req-hid mb-3">
              <UICheckbox
                id="fieldRequired"
                :disabled="isRequiredFieldDisable"
                :checked="getRequired"
                @update:checked="updateRequired"
                >{{ t("common.required") }}</UICheckbox
              >
              <UICheckbox
                v-if="
                  ![
                    'TEXTBOX_LIST',
                    'SINGLE_OPTIONS',
                    'MULTIPLE_OPTIONS',
                    'FILE_UPLOAD',
                    'SIGNATURE',
                  ].includes(selectedElementStore?.dataType)
                "
                id="fieldCheckbox"
                :disabled="isHiddenFieldDisable"
                :checked="getHidden"
                @update:checked="updateHidden"
                >{{ t("common.hidden") }}</UICheckbox
              >
            </div>
            <div v-if="selectedElementStore.hidden" class="form-group hidden-field">
              <div class="flex space-x-3">
                <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
                  t("common.hiddenValue")
                }}</span>
              </div>
              <UIInput
                id="fieldHiddenvalue"
                class="standard-input-ghl"
                :model-value="getHiddenValue"
                @update:model-value="updateHiddenValue"
                @blur="store.addToHistory()"
              ></UIInput>
            </div>
            <div>
              <UICheckbox
                v-if="
                  selectedElementStore.tag == 'phone' || selectedElementStore.dataType == 'PHONE'
                "
                id="fieldCheckbox"
                :checked="getEnableCountryPicker"
                @update:checked="updateEnableCountryPicker"
                >{{ t("common.enableCountryPicker") }}</UICheckbox
              >
            </div>
            <div v-if="selectedElementStore?.model === 'opportunity'">
              <UIDivider />
              <div class="flex justify-center">
                <UIButton
                  id="edit-opportunity-settings"
                  type="tertiary"
                  @click="store.showOpportunitiesModal = true"
                >
                  <Edit01Icon class="mr-2 h-4 w-4" />
                  {{ t("common.editOpportunitySettings") }}
                </UIButton>
              </div>
              <p v-if="!store.opportunitySettings" class="pt-6">
                <UIAlert id="warning-message" type="warning">
                  <template #content>
                    {{ t("common.opportunitySettingsWarning") }}
                  </template>
                </UIAlert>
              </p>
            </div>
            <EditOpportunitySettingsModal
              :show="store.showOpportunitiesModal"
              @update:success="saveOpportunitySettings"
              @update:cancel="store.showOpportunitiesModal = false"
            />
            <div v-if="selectedElementStore?.tag === 'email' && !selectedElementStore?.hidden">
              <div class="mt-8 flex justify-between">
                <span class="hl-text-input-label mb-1 block text-gray-700">
                  {{ t("common.validateEmailHeader") }}
                </span>
                <UICheckbox
                  id="email-checkbox"
                  v-model:checked="enableValidateEmailModel"
                  @update:checked="store.addToHistory"
                />
              </div>
              <div class="text-xs text-gray-700">
                {{ t("common.validateEmailDescription") }}
              </div>
            </div>
            <div
              v-if="
                (selectedElementStore?.tag === 'phone' ||
                  selectedElementStore?.dataType === 'PHONE') &&
                !selectedElementStore?.hidden
              "
            >
              <div class="mt-8 flex justify-between">
                <span class="hl-text-input-label mb-1 block text-gray-700">
                  {{ t("common.validatePhoneHeader") }}
                </span>
                <UICheckbox
                  id="phone-checkbox"
                  v-model:checked="enableValidatePhoneModel"
                  @update:checked="store.addToHistory"
                />
              </div>
              <div class="mb-4 text-xs text-gray-700">
                {{ t("common.validatePhoneDescription") }}
              </div>
              <UIAlert id="warning-message" type="warning">
                <template #content>
                  {{ t("common.validatePhoneWarning") }}
                </template>
              </UIAlert>
            </div>
          </div>
        </UICollapseItem>
        <UICollapseItem
          v-if="OPTIONS_FIELD.includes(selectedElementStore.dataType)"
          id="options"
          title="Options"
          name="Options"
        >
          <section class="flex flex-col items-start gap-4">
            <section class="flex items-end">
              <section class="flex flex-col items-start gap-1.5 self-stretch">
                <ListEditor
                  v-if="selectedElementStore?.dataType === 'TEXTBOX_LIST'"
                  :picklist-options="selectedElementStore?.picklistOptions"
                  :custom-field-created="
                    selectedElementStore?.customEdited || !selectedElementStore?.custom
                  "
                  @update:picklist-options="updatePicklistOptions"
                />
                <RadioSelectEditor
                  v-if="selectedElementStore?.dataType === 'RADIO'"
                  :key="optionModified"
                  :is-quiz="store.quizId"
                  :slide-index="slideIndex"
                  :element-index="elementIndex"
                  :selected-element="selectedElementStore"
                  :question-options="questionOptions"
                  :options="selectedElementStore?.picklistOptions"
                  :custom-field-created="
                    selectedElementStore?.customEdited || !selectedElementStore?.custom
                  "
                  @update:options="updatePicklistOptions"
                />
                <DropdownEditor
                  v-if="
                    ['SINGLE_OPTIONS', 'MULTIPLE_OPTIONS', 'CHECKBOX'].includes(
                      selectedElementStore?.dataType
                    )
                  "
                  :key="optionModified"
                  :is-quiz="store.quizId"
                  :slide-index="slideIndex"
                  :element-index="elementIndex"
                  :selected-element="selectedElementStore"
                  :question-options="questionOptions"
                  :options="selectedElementStore?.picklistOptions"
                  :custom-field-created="
                    selectedElementStore?.customEdited || !selectedElementStore?.custom
                  "
                  @update:options="updatePicklistOptions"
                />
                <ScoreEditor
                  v-if="selectedElementStore?.dataType === 'SCORE'"
                  :calculation="selectedElementStore?.calculation"
                  :product-type="PRODUCT_TYPE.QUIZ"
                  @update:calculation="updateCalculation"
                />
                <UIAlert
                  v-if="
                    [
                      'TEXTBOX_LIST',
                      'SINGLE_OPTIONS',
                      'MULTIPLE_OPTIONS',
                      'RADIO',
                      'CHECKBOX',
                    ].includes(selectedElementStore?.dataType)
                  "
                  id="warning-message"
                  type="warning"
                >
                  <template #content>
                    Editing or swapping options will impact this custom field across all places
                    where the field is used.
                  </template>
                </UIAlert>
                <div
                  v-if="['RADIO', 'CHECKBOX'].includes(selectedElementStore?.dataType)"
                  class="m-2 flex flex-col gap-2 self-stretch"
                >
                  <Switch
                    :value="getSpreadColumns"
                    class="w-full"
                    label="Spread to Columns"
                    @update:value="updateSpreadColumns"
                  ></Switch>
                  <UIInputGroup v-if="getSpreadColumns">
                    <UIInputNumber
                      id="fieldQueryKey"
                      size="large"
                      class="w-100"
                      :min="1"
                      :max="10"
                      :default-value="getDefaultNumberOfColumns"
                      :show-button="false"
                      :model-value="getNumberOfColumns"
                      @update:model-value="updateNumberOfColumns"
                      @blur="store.addToHistory()"
                    ></UIInputNumber
                    ><UIInputGroupLabel>Cols</UIInputGroupLabel>
                  </UIInputGroup>
                </div>
              </section>
            </section>
          </section>
        </UICollapseItem>
        <UICollapseItem
          v-if="selectedElementStore?.dataType === 'FILE_UPLOAD'"
          id="uploadOptions"
          title="Upload Options"
          name="Upload Options"
        >
          <FileUploadEditor
            :options="selectedElementStore?.picklistOptions || ['all']"
            @update:options="updatePicklistOptions"
          />
          <div>
            <div class="my-4 flex items-center justify-between">
              <div class="my-auto text-sm font-normal text-gray-700">Allow Multiple Files</div>
              <UISwitch v-model:value="selectedElementStore.isMultipleFile" size="small" />
            </div>
            <div v-if="selectedElementStore.isMultipleFile">
              <div class="mb-2 mt-4 text-sm font-normal text-gray-700">Max File Limit</div>
              <UIInput id="max-file-limit" v-model:value="selectedElementStore.maxFileSize" />
            </div>
          </div>
        </UICollapseItem>
        <UICollapseItem
          v-if="!selectedElementStore?.standard && selectedElement.elementId"
          id="advanced-settings"
          title="Advanced Settings"
          name="Advanced Settings"
        >
          <div>
            <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
              Custom Field Name
              <UITooltip trigger="hover" placement="top">
                <template #trigger>
                  <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                </template>
                This field name is unique for each custom field.
              </UITooltip>
            </div>
            <UIInput
              id="custom-field-label"
              class="standard-input-ghl"
              :model-value="
                selectedElementStore?.customFieldLabel ||
                selectedElementStore?.name ||
                selectedElementStore?.label
              "
              :disabled="selectedElementStore?.customEdited || !selectedElementStore?.custom"
              @update:model-value="updateLabel"
              @blur="store.addToHistory()"
            />
            <div class="switch-handle mb-1 mt-6 block text-sm font-medium text-gray-700">
              Unique Key
              <UITooltip trigger="hover" placement="top">
                <template #trigger>
                  <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                </template>
                This key is unique for each custom field.
              </UITooltip>
            </div>
            <UIInput
              id="uniqueKey"
              class="standard-input-ghl"
              :model-value="getUniqueKey"
              :disabled="
                selectedElementStore?.customEdited ||
                !selectedElementStore?.custom ||
                selectedElementStore?.dataType === 'SCORE'
              "
              :placeholder="uniqueKeyPlaceholder"
              @update:model-value="updateUniqueKey"
              @blur="store.addToHistory()"
            />
          </div>
        </UICollapseItem>
      </UICollapse>
    </div>
  </section>
</template>

<style>
.back-section {
  display: flex;
  width: 360px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
}
#quizSettings {
  display: flex;
  padding: 0px 0px;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-self: stretch;
}
#quizSettings .n-collapse-item__header {
  display: flex;
  align-items: start;
  padding: 16px;
  gap: 20px;
  border-bottom: 1px solid v #eaecf0;
  background: #f9fafb;
}
#quizSettings .n-collapse .n-collapse-item {
  margin-top: 0;
}
#quizSettings .n-collapse-item__content-wrapper {
  padding: 0 16px;
}
.field-name-sidebar {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center !important;
  margin-bottom: 1rem !important;
}

#custom-sidebar-form {
  font-size: 14px;
}

.standard-input-ghl {
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.query-key {
  display: flex;
  align-items: baseline;
}

.form-group {
  margin-bottom: 1rem;
}

.hidden-field {
  margin-top: 1rem;
}

.form-check-req-hid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.arrow-button {
  margin-bottom: 0px;
  cursor: pointer;
}

.alignment {
  display: flex;
  margin-bottom: 10px;
}

.alignment-div {
  margin-right: 10px;
}

.custom-icons {
  color: #38a0db;
  margin: 0 10px;
  cursor: pointer;
}

#hide-tabs .n-tabs-nav-scroll-wrapper {
  display: none !important;
}

#logic-card > div.p-3 {
  padding-bottom: 1px !important;
}
</style>
