<script setup lang="ts">
import { UIModal, UIModalContent, UIModalFooter, UIModalHeader } from "@gohighlevel/ghl-ui";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import RichTextEditor from "./RichTextEditor.vue";
const { t } = useI18n();
const props = defineProps({
  modelActive: {
    type: Boolean,
    default: false,
  },
  code: {
    type: String,
    default: "",
  },
});
const showModalNative = ref(props.modelActive);
const emit = defineEmits(["update:showModel", "update:submit", "update:cancel", "update:close"]);
const descriptionModel = ref(null);

watch(
  () => showModalNative.value,
  (newValue) => {
    emit("update:showModel", newValue);
  }
);
const inputValue = ref(props.code || "");

const editorOptions = ref({
  height: 500,
  plugins:
    " autolink link image lists charmap hr anchor pagebreak  searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking table  directionality emoticons template link",
  toolbar_mode: "floating",
  toolbar: "undo redo bold italic bullist numlist link image forecolor backcolor ",
  a11y_advanced_options: true,
});

const closeEditor = () => {
  showModalNative.value = false;
  emit("update:close", inputValue.value);
};
const submitCallback = () => {
  showModalNative.value = false;
  emit("update:submit", inputValue.value);
};

const cancelCallback = () => {
  showModalNative.value = false;
  emit("update:cancel", inputValue.value);
};
</script>

<template>
  <div>
    <UIModal
      id="tiny_editor"
      ref="descriptionModel"
      v-model:show="showModalNative"
      :width="700"
      :z-index="1"
    >
      <template #header>
        <UIModalHeader
          id="tine_editor_header"
          :title="t('common.fieldDescription')"
          @close="closeEditor"
        >
        </UIModalHeader>
      </template>
      <UIModalContent class="py-3 text-center">
        <RichTextEditor v-model="inputValue" editor-height="300px"></RichTextEditor>
      </UIModalContent>
      <template #footer>
        <UIModalFooter
          id="modal-footer"
          :positive-text="t('common.yesSave')"
          :negative-text="t('common.cancelKeep')"
          @positive-click="submitCallback"
          @negative-click="cancelCallback"
        >
        </UIModalFooter>
      </template>
    </UIModal>
  </div>
</template>

<style>
.tox-notifications-container {
  display: none;
}
.tox.tox-silver-sink.tox-tinymce-aux {
  display: none !important;
}
</style>
