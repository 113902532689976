import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import FormBuilder from "@/pages/FormBuilder.vue";
import SurveyBuilder from "@/pages/SurveyBuilder.vue";
import QuizBuilder from "@/pages/QuizBuilder.vue";

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: "/form-builder-v2/:form_id",
    name: "form-builder-v2",
    component: FormBuilder,
  },
  {
    path: "/survey-builder-v2/:survey_id",
    name: "survey-builder-v2",
    component: SurveyBuilder,
  },
  {
    path: "/quiz-builder-v2/:quiz_id",
    name: "quiz-builder-v2",
    component: QuizBuilder,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export { router };
