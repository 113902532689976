<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UISelect, UITextXsMedium } from "@gohighlevel/ghl-ui";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps({
  label: {
    type: String,
    default: "Input Style",
  },
  value: {
    type: String,
    default: "box",
  },
});

const options = [
  { label: t("common.box"), value: "box" },
  { label: t("common.line"), value: "line" },
];

const store = useAppStore();
const emit = defineEmits(["update:value"]);
const handleSelect = (val: string) => {
  emit("update:value", val);
  store.addToHistory();
};
</script>

<template>
  <div id="pixelNumber" class="flex flex-col items-start gap-2 self-stretch">
    <UITextXsMedium>{{ label }}</UITextXsMedium>
    <UISelect
      id="input_style_type"
      size="large"
      :value="value"
      :options="options"
      @change="handleSelect"
    />
  </div>
</template>

<style scoped></style>
