<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UIInputNumber } from "@gohighlevel/ghl-ui";

defineProps({
  label: {
    type: String,
    default: "",
  },
  value: {
    type: Number || String,
    default: 0,
  },
  isLabelSmall: {
    type: Boolean,
    default: false,
  },
  max: {
    type: Number,
    default: 200,
  },
  min: {
    type: Number,
    default: 0,
  },
});

const store = useAppStore();
const emit = defineEmits(["update:value"]);
const handleSetCount = (val: string) => emit("update:value", parseFloat(val) || 0);
</script>
<template>
  <div :class="isLabelSmall ? 'mb-2' : 'mb-2 grid grid-cols-2'">
    <label
      :class="
        isLabelSmall
          ? 'm-0 block text-xs font-normal text-gray-700'
          : 'my-auto text-sm font-normal text-gray-700'
      "
      >{{ label }}</label
    >
    <div :class="isLabelSmall ? 'relative mt-1 rounded-md shadow-sm' : 'd-inline pixel-count'">
      <UIInputNumber
        id="text-input"
        :show-button="false"
        class="input-field"
        :min="min"
        :max="max"
        :value="value"
        @change="handleSetCount"
        @blur="store.addToHistory"
      >
        <template #suffix> px </template>
      </UIInputNumber>
    </div>
  </div>
</template>

<style lang="css" scoped>
.input-field {
  font-size: 12px !important;
}
</style>
