import { createI18n } from "vue-i18n";

import da from "../locales/da.json";
import de from "../locales/de.json";
import enUS from "../locales/en.json";
import es from "../locales/es.json";
import fi from "../locales/fi.json";
import frCA from "../locales/fr_CA.json";
import frFR from "../locales/fr_FR.json";
import it from "../locales/it.json";
import nl from "../locales/nl.json";
import no from "../locales/no.json";
import ptBR from "../locales/pt_BR.json";
import ptPT from "../locales/pt_PT.json";
import sv from "../locales/sv.json";

declare global {
  interface Window {
    builderApp: any;
  }
}

const locale = localStorage.getItem("locale") as string;
export const i18n = createI18n({
  locale: locale,
  globalInjection: true,
  legacy: false,
  runtimeOnly: true,
  fallbackLocale: "en-US",
  messages: {
    "en-US": enUS,
    es,
    de,
    "fr-CA": frCA,
    "fr-FR": frFR,
    "pt-BR": ptBR,
    "pt-PT": ptPT,
    it,
    nl,
    sv,
    da,
    fi,
    no,
  },
});
// return i18n;
