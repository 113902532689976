<script setup lang="ts">
import { reactive, ref, watch } from "vue";
import { UIModal, UIModalHeader, UIModalFooter, UIModalContent } from "@gohighlevel/ghl-ui";
import CodeEditor from "./CodeEditor.vue";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/store/app";

const { t } = useI18n();

const props = defineProps({
  modelActive: {
    type: Boolean,
    default: false,
  },
  code: {
    type: String,
    default: "",
  },
});
const showModalNative = ref(props.modelActive);
const emit = defineEmits(["update:showModel", "update:submit", "update:cancel", "update:close"]);
const store = useAppStore();

watch(
  () => showModalNative.value,
  (newValue) => {
    emit("update:showModel", newValue);
  }
);
const inputValue = ref(props.code || "");
const cmOption = reactive({
  tabSize: 4,
  styleActiveLine: true,
  lineNumbers: true,
  line: true,
  mode: "text/html",
  lineWrapping: true,
  theme: "dracula",
});

const closeEditor = () => {
  showModalNative.value = false;
  emit("update:close", inputValue.value);
};
const submitCallback = () => {
  showModalNative.value = false;
  setTimeout(() => {
    store.addToHistory();
  }, 100);
  emit("update:submit", inputValue.value);
};

const cancelCallback = () => {
  showModalNative.value = false;
  emit("update:cancel", inputValue.value);
};

const codeChangeListner = (updatedCode: string) => {
  inputValue.value = updatedCode;
};
</script>

<template>
  <div>
    <UIModal id="model" v-model:show="showModalNative" :width="700">
      <template #header>
        <UIModalHeader id="model-header" :title="t('common.htmlEditorHeader')" @close="closeEditor">
        </UIModalHeader>
      </template>
      <UIModalContent class="py-3 text-center">
        <CodeEditor
          ref="myCm"
          :code-input="inputValue"
          :placeholder="t('common.htmlEditorBodyMessage')"
          :height="400"
          :options="cmOption"
          @update:input-code="codeChangeListner"
        />
      </UIModalContent>
      <template #footer>
        <UIModalFooter
          id="modal-footer"
          :positive-text="t('common.yesSave')"
          :negative-text="t('common.cancelKeep')"
          @positive-click="submitCallback"
          @negative-click="cancelCallback"
        >
        </UIModalFooter>
      </template>
    </UIModal>
  </div>
</template>
