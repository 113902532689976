<template>
  <UIInput
    id="facbookPixelId"
    :value="store.facebook.fbPixelId"
    :placeholder="t('common.placeholderFacebookPixelIdInput')"
    @update-value="updateFacbookPixelId"
    @blur="store.addToHistory()"
  />
  <UIAlert id="info-message" type="info">
    <template #content> {{ t("common.facebookPixelIDMessage") }}</template>
  </UIAlert>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UIInput, UITextSmRegular, UIAlert } from "@gohighlevel/ghl-ui";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useAppStore();

const updateFacbookPixelId = (value: string) => {
  store.facebook.fbPixelId = value;
  store.anyUnsavedChanges = true;
};
</script>
