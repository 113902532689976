{
  "common": {
    "addCustomFields": "Aggiungi campi personalizzati",
    "addElement": "Aggiungi elemento",
    "addUrlLike": "Aggiungi URL validi come",
    "address": "Indirizzo",
    "agencyBranding": "Marchio dell'agenzia",
    "alert": "Mettere in guardia",
    "alignment": "Allineamento",
    "altText": "Testo alternativo",
    "back": "Indietro",
    "background": "Sfondo",
    "backgroundImage": "Immagine di sfondo",
    "backgroundImageTooltipMessage": "L'immagine di sfondo deve coprire l'intera pagina, fornendo uno sfondo visivamente accattivante per il modulo. \nPer visualizzare l'immagine di sfondo, fare clic sull'opzione Anteprima.",
    "blur": "Sfocatura",
    "border": "Confine",
    "borderType": "Tipo di bordo",
    "bottom": "Metter il fondo a",
    "buttonStyle": "Stile pulsante",
    "cancel": "Annulla",
    "cancelKeep": "Annulla, mantieni",
    "color": "Colore",
    "conditionalLogicAlertOnSubmit": "La logica condizionale avrà la precedenza sul messaggio/reindirizzamento qui",
    "contactInfo": "Informazioni di contatto",
    "cornerRadius": "Raggio dell'angolo",
    "customCSS": "CSS personalizzato",
    "customCSSTooltip": "I CSS personalizzati hanno la precedenza sullo stile del modulo",
    "customFieldSearch": "Ricerca per nome",
    "customFields": "Campi personalizzati",
    "customized": "Personalizzato",
    "delete": "Eliminare",
    "doNotAddRelativePath": "Non aggiungere percorsi relativi come",
    "doNotShowMessage": "Non mostrare più",
    "done": "Fatto",
    "editHTML": "Modifica HTML",
    "embed": "Incorporare",
    "enableCountryPicker": "Abilita il selettore del paese",
    "enterSourceValue": "Inserisci il valore di origine",
    "facebookPixelEvents": "Eventi Pixel di Facebook",
    "facebookPixelID": "ID pixel di Facebook",
    "facebookPixelIDMessage": "Ignora questo campo se prevedi di utilizzare questo modulo/sondaggio all'interno di una canalizzazione",
    "fieldDescription": "Descrizione del campo",
    "fieldStyle": "Stile campo",
    "fieldTitle": "Titolo del campo",
    "fontColor": "Colore del carattere",
    "fontFamily": "Famiglia di font",
    "fontFamilyTooltip": "Il builder supporta tutti i tipi di carattere se non puoi visualizzarli nell'elenco. \nSi prega di cercarlo nel menu a discesa",
    "fullWidth": "Intera larghezza",
    "headerImage": "Immagine dell'intestazione",
    "headerImageTooltipMessage": "L'immagine dell'intestazione è progettata per coprire l'intera larghezza del modulo e rimane posizionata nella parte superiore del modulo. \nPer visualizzare l'immagine dell'intestazione, fare clic sull'opzione Anteprima.",
    "hidden": "Nascosto",
    "hiddenValue": "Valore nascosto",
    "horizontal": "Orizzontale",
    "htmlEditorBodyMessage": "Aggiungi il tuo codice qui",
    "htmlEditorHeader": "JAVASCRIPT/HTML PERSONALIZZATO",
    "htmlMessage": "L'HTML personalizzato va qui",
    "iframeEmbed": "Incorpora ifrmae",
    "imageHeight": "Altezza dell'immagine",
    "imageUploadMessage": "Fare clic o trascinare un file immagine in quest'area per caricarlo",
    "imageWidth": "Larghezza immagine",
    "inputStyle": "Stile di input",
    "integrate": "Integrare",
    "label": "Etichetta",
    "layout": "Disposizione",
    "left": "Sinistra",
    "link": "Collegamento",
    "logic": "Logica",
    "onSubmit": "All'invio",
    "onpageView": "Nella visualizzazione della pagina",
    "options": "Opzioni",
    "or": "O",
    "padding": "Imbottitura",
    "personalInfo": "Informazioni personali",
    "placeholder": "Segnaposto",
    "placeholderFacebookPixelIdInput": "Inserisci l'ID del pixel di Facebook",
    "pleaseInput": "Per favore inserisci",
    "preview": "Anteprima",
    "proceed": "Procedere",
    "queryKey": "Chiave di interrogazione",
    "querykeyTooltipMessage": "La chiave di query che può essere utilizzata come parametro URL per popolare questo campo",
    "quickAdd": "Aggiunta rapida",
    "required": "Necessario",
    "right": "Giusto",
    "save": "Salva",
    "saving": "Salvataggio",
    "shadow": "Ombra",
    "shortLabel": "Etichetta corta",
    "showLabel": "Mostra etichetta",
    "size": "Misurare",
    "spread": "Diffusione",
    "stickyContact": "Contatto appiccicoso",
    "stickyContactMessage": "Il contatto permanente riempirà automaticamente le informazioni di contatto precedentemente inserite in modo da non doverle inserire due volte.",
    "styles": "Stili",
    "subText": "Sottotesto",
    "submit": "Invia",
    "text": "Testo",
    "textStyle": "Stile del testo",
    "themes": {
      "alertThemeMessage": "Cambiare tema comporterà la perdita di tutte le modifiche di stile attuali.",
      "removeTheme": "Rimuovi tema",
      "themes": "Temi",
      "useTheme": "Usa il tema"
    },
    "top": "Superiore",
    "useGDPRCompliantFont": "Utilizza caratteri conformi al GDPR",
    "vertical": "Verticale",
    "weight": "Peso",
    "width": "Larghezza",
    "yesSave": "Sì, salva",
    "miscellaneous": "Varie",
    "box": "Scatola",
    "line": "Linea",
    "message": "Messaggio",
    "openUrl": "Apri URL",
    "borderTypeList": {
      "dotted": "Punteggiato",
      "none": "Nessuno",
      "solid": "Solido",
      "dashed": "Tratteggiato"
    },
    "button": "Pulsante",
    "enableTimezone": "Abilita fuso orario",
    "emailNotification": "notifica per email",
    "emailNotificationSubheader": "Ricevi un'e-mail quando qualcuno compila il modulo",
    "subject": "Soggetto",
    "emailTo": "Invia una email a)",
    "senderName": "Nome del mittente",
    "senderNameWarning": "Il tuo (nome del sottoaccount) verrà aggiunto se lasciato vuoto",
    "emailTooltip": "Il primo indirizzo email è designato per il campo \"A\". \nEventuali indirizzi email successivi verranno inclusi nel campo \"Cc\".",
    "emailError": "È richiesto almeno un indirizzo email",
    "clear": "Chiaro",
    "showNotificationsSettings": "Mostra le impostazioni delle notifiche",
    "emailServicePaid": "Questa funzionalità è un servizio a pagamento e comporta costi in base all'utilizzo.",
    "integration": "Integrazione",
    "payment": {
      "connect": "Collegare",
      "connected": "Collegato",
      "footerInfo": "Informazioni sul piè di pagina",
      "includeOtherAmount": "Includere altro importo",
      "live": "Vivere",
      "paymentFields": "Campi di pagamento",
      "selectCurrency": "Seleziona Valuta",
      "status": "Stato",
      "suggestAnAmount": "Suggerisci un importo",
      "test": "Test",
      "liveModeOn": "Modalità dal vivo",
      "customAmount": "Importo personalizzato",
      "paymentType": "Modalità di pagamento",
      "product": {
        "addProduct": "Aggiungi prodotto",
        "clickHere": "clicca qui",
        "description": "Descrizione",
        "editThisProduct": "Per modificare questo prodotto",
        "image": "Immagine",
        "layoutSettings": "Impostazioni di Layout",
        "maxProductError": "Sono ammessi al massimo 20 prodotti in un modulo",
        "productSelectPlaceholder": "Seleziona un prodotto",
        "productSettings": "Impostazioni del prodotto",
        "selectProduct": "Seleziona prodotto",
        "show": "Spettacolo",
        "singleColumn": "Colonna singola",
        "threeColumn": "Tre colonne",
        "twoColumn": "Due colonne",
        "orderConfirmationWarning": "Una volta che il pagamento è andato a buon fine, verrà visualizzata una pagina di conferma dell'ordine",
        "orderConfirmation": "Conferma dell'ordine",
        "createProduct": "Per creare un prodotto",
        "quantity": "Quantità"
      },
      "sellProducts": "Vendi prodotti",
      "generalSettings": "Campi generali",
      "tagActiveBGColor": "Colore tag attivo",
      "collectPayment": "Raccogli il pagamento",
      "payments": "Pagamenti",
      "coupons": {
        "couponAlignment": "Allineamento dei coupon",
        "coupons": "Buoni",
        "enableCoupon": "Abilita buono",
        "fullWidth": "Intera larghezza",
        "left": "Sinistra",
        "right": "Giusto",
        "tooltipMessage": "Imposta la posizione dell'input del coupon dai tipi seguenti"
      }
    },
    "autoResponder": "Risponditore automatico",
    "autoResponderSubheader": "Invia un'e-mail automatica alla persona che invia il modulo (l'e-mail include una copia delle informazioni inserite nel modulo)",
    "replyToEmail": "Rispondi all'e-mail",
    "open": "Aprire",
    "won": "Vinto",
    "lost": "Perduto",
    "abandon": "Abbandono",
    "selectAPipeline": "Seleziona una pipeline",
    "stage": "Palcoscenico",
    "status": "Stato",
    "opportunityValue": "Valore dell'opportunità",
    "createNewPipeline": "Crea una nuova pipeline",
    "editOpportunitySettings": "Modifica le impostazioni dell'opportunità",
    "contact": "Contatto",
    "opportunity": "Opportunità",
    "add": "Aggiungere",
    "more": "Di più",
    "opportunityHeading": "Dove vuoi che venga aggiornata la tua opportunità?",
    "date": {
      "dateFormat": "Formato data",
      "dateFormatTooltipMessage": "Seleziona un formato data. \nD sta per giorno, M per mesi e Y per anno",
      "dateSeparator": "Separatore di data",
      "dateSeparatorTooltipMessage": "Selezionare un separatore che separerà giorno, mese e anno nel formato selezionato in Formato data",
      "dateSettings": "Impostazioni della data",
      "disablePicker": "Disabilita selettore"
    },
    "restoreVersion": "Ripristina versione",
    "versionHistory": "Cronologia delle versioni",
    "errorMessage": "Errore, riprova",
    "version": "Versione:",
    "currentVersion": "Versione attuale",
    "conditionalLogicAlertOnShowHide": "La logica condizionale avrà la precedenza sulle impostazioni nascoste qui",
    "validateEmailHeader": "Convalida e-mail",
    "validateEmailDescription": "Il pulsante diventerà visibile una volta che l'utente avrà inserito la propria email. \nQuesta funzionalità è progettata per aiutarti a generare lead di qualità.",
    "validatePhoneHeader": "Convalida telefono",
    "validatePhoneDescription": "Il pulsante diventerà visibile una volta che l'utente accede al telefono. \nQuesta funzionalità è progettata per aiutarti a generare lead di qualità.",
    "validatePhoneWarning": "Per abilitare la verifica SMS OTP, assicurati che il tuo numero di telefono sia configurato nelle impostazioni di posizione",
    "style": {
      "formLayoutType": "Tipo di modulo",
      "oneColumn": "Una colonna",
      "singleLine": "Linea singola",
      "twoColumn": "Due colonne",
      "fieldWidth": "Larghezza del campo",
      "fieldSpacing": "Spaziatura del campo",
      "advanceSettings": "Impostazioni avanzate",
      "borderColor": "Colore del bordo",
      "borderStyle": "Stile del bordo",
      "borderWidth": "Larghezza del bordo",
      "color": "colore",
      "colorsAndBackground": "Colori",
      "fontColor": "Colore del carattere",
      "fontSize": "Dimensione del font",
      "fontWeight": "Peso del carattere",
      "inputBackground": "Inserisci lo sfondo",
      "inputField": "Campo di inserimento",
      "labelColor": "Colore dell'etichetta",
      "layout": "Disposizione",
      "placeholderColor": "Colore segnaposto",
      "shortLabelColor": "Colore etichetta corta",
      "labelAlignment": {
        "labelAlignment": "Allineamento delle etichette",
        "labelWidth": "Larghezza etichetta"
      }
    },
    "replyToTooltip": "Personalizza l'indirizzo email di risposta predefinito utilizzando {'{'}{'{'}contact.email{'}'}{'}'} per abilitare le risposte al contatto che invia il modulo.",
    "content": "Contenuto",
    "calculations": "Calcoli",
    "uploadOptions": "Opzioni di caricamento",
    "messageStyling": "Stile dei messaggi",
    "opportunitySettingsWarning": "Configura le impostazioni dell'opportunità facendo clic sul pulsante Modifica impostazioni opportunità.",
    "createContactOnPartialSubmission": "Crea contatto in caso di invio parziale",
    "howItWorks": "Come funziona?",
    "createContactOnPartialSubmissionDescription": "Termina la diapositiva e fai clic su \"Avanti\". \nUn nuovo contatto verrà creato e memorizzato automaticamente.",
    "swapWarningMessageSurvey": "Le opzioni di modifica o scambio avranno effetto su questo campo personalizzato in tutti i luoghi in cui viene utilizzato il campo. \nPuò anche ripristinare la logica condizionale.",
    "swapWarningMessageForm": "Le opzioni di modifica o scambio avranno effetto su questo campo personalizzato in tutti i luoghi in cui viene utilizzato il campo.",
    "addOption": "Aggiungi opzione",
    "calculationValue": "Valore di calcolo",
    "enableCalculations": "Abilita calcoli",
    "optionLabel": "Etichetta opzione",
    "addressSettings": {
      "addressfields": "Campi indirizzo",
      "autoComplete": "Indirizzo di completamento automatico",
      "autoCompleteTooltip": "Fornisce la possibilità di cercare e compilare automaticamente l'indirizzo. \nQuesta funzionalità è a pagamento.",
      "placeholderSearch": "Cerca un indirizzo"
    }
  },
  "form": {
    "activateOn": "Attiva su",
    "activationOptions": "Opzioni di attivazione",
    "addConditionalLogic": "Aggiungi logica condizionale",
    "addFormElement": "Aggiungi elemento modulo",
    "allowMinimize": "Consenti minimizza",
    "alwaysActivated": "Sempre attivato",
    "alwaysShow": "Mostra sempre",
    "conditonalLogic": {
      "addNewCondition": "Aggiungi nuova condizione",
      "alertConditionDelete": "Sei sicuro di voler eliminare questa condizione?",
      "conditionExecutedMessage": "Le condizioni verranno eseguite dall'ordine dall'alto verso il basso.",
      "conditions": "Condizioni",
      "deleteCondition": "Elimina condizione",
      "displayCustomMessage": "Visualizza un messaggio personalizzato",
      "disqualifyLead": "Squalificare il vantaggio",
      "freeRoamMessage": "Sei libero di vagare senza regole, almeno per ora",
      "noExistingCondition": "Nessuna condizione esistente ancora",
      "openBelowUrl": "Apri sotto l'URL",
      "redirectToUrl": "Reindirizzamento all'URL",
      "showCustomMessage": "Mostra messaggio personalizzato",
      "showHideFields": "Mostra/nascondi campi",
      "allOperationOptions": {
        "after": "Dopo",
        "before": "Prima",
        "contains": "Contiene",
        "endsWith": "Finisce con",
        "greaterThan": "Più grande di",
        "isEmpty": "È vuoto",
        "isEqualTo": "È uguale a",
        "isFilled": "È pieno",
        "isNotEqualTo": "Non è uguale a",
        "lessThan": "Meno di",
        "startsWith": "Inizia con"
      },
      "logic": {
        "and": "E",
        "if": "Se",
        "or": "O",
        "then": "Poi"
      },
      "phoneAlertMessage": "Per cercare numeri di telefono con prefisso internazionale, devi includere esplicitamente il simbolo '+' nella query di ricerca.",
      "deleteAlertMessage": "Sei sicuro di voler eliminare questo campo? \nVerrà rimossa anche la condizione associata a questo campo.",
      "deleteField": "Elimina campo"
    },
    "minimizedTitlePlaceholder": "Titolo ridotto al minimo",
    "copyEmbedCode": "Copiare il codice incluso",
    "deactivateAfterShowing": "Disattiva dopo la visualizzazione",
    "deactivationOptions": "Opzioni di disattivazione",
    "deactiveOnceLeadCollected": "Disattivare una volta raccolto il lead",
    "embedlayoutType": "Incorpora tipo di layout",
    "embedlayoutTypeSubMessage": "Seleziona un layout da incorporare in una canalizzazione o in un sito web esterno",
    "formElement": "Elemento del modulo",
    "formName": "Nome del modulo",
    "formSettings": "Impostazioni del modulo",
    "formStyle": "Stile modulo",
    "fullScreenMode": "Modalità schermo intero",
    "fullScreenModetooltip": "L'attivazione farà sì che il modulo si espanda per tutta la larghezza del suo contenitore, estendendosi fino al bordo dell'area di incorporamento pur aderendo alla larghezza del modulo.",
    "inline": "In linea",
    "inlineDescription": "Un modulo in linea viene visualizzato insieme al contenuto della pagina come elemento nativo. \nNon si sovrappone e non può essere chiuso o ridotto a icona.",
    "inlineForm": "Modulo in linea",
    "inlineHoverDescription": "Un modulo in linea viene visualizzato insieme al contenuto della pagina come elemento nativo. \nNon si sovrappone e non può essere chiuso o ridotto a icona.",
    "integrateForm": "Modulo Integrato",
    "neverDeactivate": "Non disattivare mai",
    "onFormSubmission": "All'invio del modulo",
    "politeSlideIn": "Inserimento educato",
    "politeSlideInDescription": "Nella parte inferiore dello schermo viene visualizzata una breve diapositiva che scompare una volta chiusa",
    "politeSlideInHoverDescription": "Appare nella parte inferiore dello schermo e scompare una volta chiuso",
    "popup": "Apparire",
    "popupDescription": "Un pop-up mostra la forma come una sovrapposizione all'interno di una scatola luminosa. \nNon può essere minimizzato ma chiuso.",
    "popupHoverDescription": "Un pop-up mostra la forma come una sovrapposizione all'interno di una scatola luminosa. \nNon può essere minimizzato ma chiuso.",
    "seconds": "secondi",
    "showAfter": "Mostra dopo",
    "showOnScrolling": "Mostra durante lo scorrimento",
    "stickySidebar": "Barra laterale appiccicosa",
    "stickySidebarDescription": "Sul lato dello schermo verrà visualizzata una barra laterale fissa che può essere ridotta a icona in una scheda attaccata al lato della finestra",
    "stickySidebarHoverDescription": "Appaiono sul lato dello schermo e possono essere ridotti a icona in una scheda attaccata al lato della finestra",
    "thirdPartyScriptAlertMessage": "Gli script di terze parti all'interno del blocco HTML possono fornire funzionalità potenti, ma comportano anche rischi per la privacy, la sicurezza, le prestazioni e il comportamento della pagina. \nPertanto, ti consigliamo di rivedere il contenuto di questo script prima di salvarlo nel modulo.",
    "times": "volte",
    "triggerType": "Tipo di trigger",
    "typeOfBuilder": "Modulo",
    "visit": "visita",
    "dragYourElementFromLeft": "Trascina i tuoi elementi qui da sinistra",
    "enableTimezoneForm": "L'attivazione di questa opzione acquisirà le informazioni sul fuso orario della persona che invia il modulo",
    "formNotification": "Notifica del modulo",
    "copyFormLink": "Copia il collegamento del modulo",
    "openFormLink": "Apri il collegamento al modulo",
    "unsavedAlertMessage": "Il modulo contiene lavoro non salvato. \nSei sicuro di voler uscire e scartare tutto il lavoro non salvato?",
    "style": {
      "formPadding": "Imbottitura del modulo"
    }
  },
  "survey": {
    "addSlide": "Aggiungi diapositiva",
    "addSurveyElement": "Aggiungi elemento sondaggio",
    "backButton": "Tasto indietro",
    "backButtonTooltipMessage": "Il pulsante Indietro aiuterà l'utente a spostare la diapositiva precedente",
    "delete": "Eliminare",
    "disableAnimation": "Disabilita animazione",
    "disableAnimationTooltipMessage": "L'attivazione di questa opzione disabiliterà l'animazione di transizione delle diapositive",
    "disableAutoNavigation": "Disattiva la navigazione automatica",
    "disableAutoNavigationTooltipMessage": "Se attivato, il sondaggio non passerà automaticamente alla diapositiva successiva",
    "disqualifyAfterSubmit": "Squalifica dopo l'invio",
    "disqualifyImmediately": "Squalificare immediatamente",
    "editFooter": "Modifica piè di pagina",
    "footer": {
      "backgroundFill": "Riempimento dello sfondo",
      "buttonType": "Tipo di pulsante",
      "buttons": "Pulsanti",
      "enableNewFooter": "Abilita nuovo piè di pagina",
      "enableProgressBar": "Abilita la barra di avanzamento",
      "fillComplete": "Riempi Completato",
      "fillInactive": "Riempi inattivo",
      "fillNext": "Compila (successivo)",
      "fillPrevious": "Riempi (precedente)",
      "fillSubmit": "Compila (Invia)",
      "footer": "Piè di pagina",
      "footerHeight": "Altezza piè di pagina",
      "nextText": "Successivo (testo)",
      "position": "Posizione",
      "previousText": "Precedente (testo)",
      "stickToSurvey": "Attenersi al sondaggio",
      "submitText": "Invia (testo)",
      "textColor": "Colore del testo",
      "footerType": "Tipo di piè di pagina",
      "stickToCard": "Attaccati alla carta",
      "stickToPage": "Attieniti alla pagina"
    },
    "footerHTML": "HTML nel piè di pagina",
    "footerHTMLMessage": "Puoi scrivere CSS personalizzati (usando il tag style) e HTML all'interno della stessa casella",
    "integrateCopyLinkMessage": "Copia il link qui sotto e condividilo facilmente dove vuoi.",
    "integrateHeader": "Sondaggio integrato",
    "integrateSubHeader": "Utilizza le seguenti opzioni per integrare il tuo sondaggio personalizzato nel tuo sito web",
    "jumpTo": "Salta a",
    "onSurveySubmission": "Sulla presentazione del sondaggio",
    "oneQuestionAtTime": "Una domanda alla volta",
    "progressBar": "Barra di avanzamento",
    "progressBarTooltipMessage": "Disattivando questa opzione verrà rimossa la barra di avanzamento dal sondaggio",
    "scrollToTop": "Scorri fino all'inizio",
    "scrollToTopTooltipMessage": "Se attivato, il sondaggio scorrerà verso l'alto nella diapositiva successiva",
    "settings": "Impostazioni",
    "slideConflictAlertMessage": "Questa diapositiva contiene più domande a cui è impostata una logica. \nCiò può causare alcuni comportamenti imprevisti",
    "slideDeleteAlertMessage": "Sei sicuro di voler eliminare questa diapositiva?",
    "slideDeleteBetweenAlertMessage": "Questo riorganizzerà le altre diapositive. Sei sicuro di voler eliminare questa diapositiva?",
    "slideName": "Nome diapositiva",
    "slidePosition": "Posizione della diapositiva",
    "surveyElement": "Elemento di sondaggio",
    "surveyName": "Nome del sondaggio",
    "surveySettings": "Impostazioni del sondaggio",
    "survyeStyle": "Stile di sondaggio",
    "thirdPartyScriptAlertMessage": "Gli script di terze parti all'interno del blocco HTML possono fornire funzionalità potenti, ma comportano anche rischi per la privacy, la sicurezza, le prestazioni e il comportamento della pagina. \nPertanto, ti consigliamo di rivedere il contenuto di questo script prima di salvarlo nel sondaggio.",
    "typeOfBuilder": "Sondaggio",
    "enableTimezoneSurvey": "L'attivazione di questa opzione acquisirà le informazioni sul fuso orario della persona che invia il sondaggio",
    "surveyNotfication": "Notifica del sondaggio",
    "unsavedAlertMessage": "Il sondaggio contiene lavoro non salvato. \nSei sicuro di voler uscire e scartare tutto il lavoro non salvato?",
    "style": {
      "surveyPadding": "Imbottitura del sondaggio"
    }
  }
}
