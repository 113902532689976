<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UISwitch } from "@gohighlevel/ghl-ui";
import { debounce } from "lodash";
import { useI18n } from "vue-i18n";
import { UITooltip } from "@gohighlevel/ghl-ui";

import questionIcon from "@/assets/icons/question-icon.svg";

const { t } = useI18n();
const store = useAppStore();

const toggleAutoComplete = debounce((value) => {
  store.addressSettings.autoCompleteEnabled = value;
  store.addToHistory();
  store.anyUnsavedChanges = true;
}, 10);
</script>

<template>
  <div class="flex justify-between py-2">
    <div class="flex items-center gap-1">
      <div class="switch-handle my-auto text-base">
        {{ t("common.addressSettings.autoComplete") }}
      </div>
      <UITooltip>
        <template #trigger>
          <questionIcon></questionIcon>
        </template>
        {{ t("common.addressSettings.autoCompleteTooltip") }}
      </UITooltip>
    </div>
    <div class="switch">
      <UISwitch
        size="small"
        :value="store.addressSettings.autoCompleteEnabled"
        :disabled="false"
        @update:value="toggleAutoComplete"
      />
    </div>
  </div>
</template>
