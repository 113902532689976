<template>
  <UISelect
    id="submit"
    v-model:value="disqualifyImmediately"
    :options="disqualifyOptions"
    @update:value="disqualifyImmediatelySelection"
  />
  <UIInput
    id="disqualifyImmediately"
    v-model="disqualifyImmediatelyModel"
    :placeholder="placeholderForDisqualify"
    @blur="store.addToHistory()"
  />
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UIInput, UISelect } from "@gohighlevel/ghl-ui";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useAppStore();

const disqualifyOptions = [
  { label: "URL", value: "url" },
  { label: t("common.message"), value: "message" },
];

const disqualifyImmediately = ref(store.formAction.disqualifiedType == 1 ? "url" : "message");

const placeholderForDisqualify = computed(() => {
  return store.formAction.disqualifiedType === 1 ? "Url Goes Here" : "Message Goes Here";
});

const disqualifyImmediatelyModel = computed({
  get() {
    if (disqualifyImmediately.value === "url") {
      return store.formAction.disqualifiedUrl;
    }
    return store.formAction.disqualifiedText;
  },
  set(value: string) {
    if (disqualifyImmediately.value === "url") {
      store.formAction.disqualifiedUrl = value;
    } else {
      store.formAction.disqualifiedText = value;
    }
    store.anyUnsavedChanges = true;
  },
});

const disqualifyImmediatelySelection = (type) => {
  if (type === "url") {
    store.formAction.disqualifiedType = 1;
  } else {
    disqualifyImmediately.value = "message";
    store.formAction.disqualifiedType = 2;
  }
  store.anyUnsavedChanges = true;
  store.addToHistory();
};
</script>
