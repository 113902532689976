<template>
  <div class="product-card">
    <div class="card-inner">
      <div class="card-inner-container">
        <slot name="handle"></slot>
        <div class="avatar-group">
          <img class="avatar" :style="{ 'background-image': `url(${product.image})` }" />
        </div>
        <div class="product-info">
          <UITextSmRegular>{{ product.name }}</UITextSmRegular>
          <UITextXsRegular class="h-9 self-stretch">{{ product.vairant }}</UITextXsRegular>
          <UITextSmRegular>{{
            (store.currencyList[product.prices[0].currency]?.symbol || "$") + product.amount
          }}</UITextSmRegular>
        </div>
        <div class="flex flex-col items-start justify-center gap-2">
          <div class="icon">
            <Settings01Icon
              class="h-4 w-4 cursor-pointer"
              @click="onProductSettings(productIndex)"
            ></Settings01Icon>
          </div>
          <div class="icon">
            <Trash01Icon
              class="h-4 w-4 cursor-pointer"
              @click="onProductDelete(productIndex)"
            ></Trash01Icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Menu05Icon, Settings01Icon, Trash01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UITextSmRegular, UITextXsRegular } from "@gohighlevel/ghl-ui";
import { ref } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import { useAppStore } from "@/store/app";
const store = useAppStore();
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  productIndex: {
    type: Number,
    required: true,
  },
});
const emits = defineEmits(["product:deleted", "product:settings"]);

const onProductDelete = (id: number) => {
  emits("product:deleted", id);
};
const onProductSettings = (id: number) => {
  emits("product:settings", id);
};
</script>

<style scoped>
.product-card {
  display: flex;
  padding: 16px 16px 16px 8px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.card-inner {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
}
.avatar-group {
  display: flex;
  align-items: center;
}
.avatar {
  display: flex;
  width: 56px;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  border: 1px solid #eaecf0;
  background-size: cover;
}
.product-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  width: 150px;
}
.card-inner-container {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}
.icon {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.divder {
  border-bottom: 1px solid #d0d5dd;
}
</style>
