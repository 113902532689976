import { createApp } from "vue";
import "./style.css";
import "./common.css";
import "@gohighlevel/ghl-ui/dist/style.css";
import App from "./App.vue";
import { router } from "./router";
import { createPinia } from "pinia";
import { i18n } from "./plugin/vue-i18n";
import mitt from "mitt";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { setupSentry } from "./util/setupSentry";

const app = createApp(App);
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.use(createPinia());
app.use(i18n);
app.use(router);
app.mount("#builderApp");
setupSentry(app, router, process.env.NODE_ENV);
