<template>
  <div id="hl-builder-header">
    <UIToolbar class="header-toolbar p-0">
      <UIToolbarGroup class="min-w-[230px]">
        <div class="flex">
          <div class="border-right p-3">
            <div class="flex pr-3">
              <UIButton id="builder-back" text size="small" class="text-gray-800" @click="back">
                <ArrowLeftIcon class="mr-2 h-4 w-4" />
                <span>{{ t("common.back") }}</span>
              </UIButton>
            </div>
          </div>
        </div>
      </UIToolbarGroup>

      <UIToolbarGroup class="min-w-[230px]">
        <div class="flex items-center justify-end px-3 py-2">
          <UIButton
            id="restore-btn"
            size="small"
            class="text-gray-800"
            :loading="isSaving"
            :disabled="currentVersionSelected"
            @click="restoreVersion"
          >
            <RefreshCcw01Icon class="mr-2 h-4 w-4" />
            {{ t("common.restoreVersion") }}
          </UIButton>
        </div>
      </UIToolbarGroup>
    </UIToolbar>
  </div>
  <div class="flex h-screen w-full overflow-hidden">
    <div class="cards-container border-right w-80">
      <div class="border-bottom fixed top-[51px] w-[270px] bg-white p-3">
        <UITextMdMedium> {{ t("common.versionHistory") }} </UITextMdMedium>
      </div>
      <div class="card-list mt-[105px] flex flex-col overflow-y-auto pb-16">
        <VersionHistoryCard
          v-for="(item, index) in store.builder.versionHistory"
          :key="index"
          :index="index"
          :item="item"
          :count="store.builder.versionHistory.length"
          :active="item.versionId === currentVersion"
          @click="() => changeCurrentVersion(item)"
        />
      </div>
    </div>
    <section class="version-preview mt-[51px] flex-grow overflow-hidden">
      <div v-if="loader" class="flex h-screen items-center justify-center">
        <UISpin size="large" />
      </div>
      <iframe
        v-show="!loader"
        :key="currentVersion"
        class="h-full w-full overflow-y-auto"
        :src="getPreviewUrl"
        @load="previewLoaded"
      />
    </section>
  </div>
</template>
<script setup lang="ts">
import { useNotify } from "@/composition/notification";
import { useMethods } from "@/composition/useMethods";
import config from "@/config";
import { FormServices } from "@/service/FormServices";
import { SurveyServices } from "@/service/SurveyServices";
import { useAppStore } from "@/store/app";
import { ArrowLeftIcon, RefreshCcw01Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton, UISpin, UITextMdMedium, UIToolbar, UIToolbarGroup } from "@gohighlevel/ghl-ui";
import { computed, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import VersionHistoryCard from "./VersionHistoryCard.vue";

const { sendNotification } = useNotify();
const store = useAppStore();
const { t } = useI18n();
const { sleep } = useMethods();
const isSaving = ref(false);
const loader = ref(true);

const back = () => (store.showVersions = false);
const previewLoaded = () => (loader.value = false);
const currentVersion = ref(store.builder.versionHistory?.[0]?.versionId);

const getPreviewUrl = computed(() => {
  if (store.formId) {
    return `${config.GHL_DOMAIN_UI}/widget/form/${store.formId}?versionId=${currentVersion.value}&notrack=true`;
  }
  if (store.surveyId) {
    return `${config.GHL_DOMAIN_UI}/widget/survey/${store.surveyId}?versionId=${currentVersion.value}&notrack=true`;
  }
  return `${config.GHL_DOMAIN_UI}/widget/quiz/${store.quizId}?versionId=${currentVersion.value}&notrack=true`;
});

const currentVersionSelected = computed(
  () => currentVersion.value === store.builder.versionHistory?.[0]?.versionId
);

const changeCurrentVersion = (item) => {
  loader.value = true;
  nextTick(() => {
    currentVersion.value = item.versionId;
  });
};

const restoreVersion = async () => {
  try {
    isSaving.value = true;
    if (store.formId) {
      const {
        data: { status, form },
      } = await FormServices.restoreFormVersion({
        formId: store.formId,
        versionId: currentVersion.value,
      });
      store.builder.versionHistory = status ? form?.versionHistory : [];
    } else {
      const {
        data: { status, survey },
      } = await SurveyServices.restoreSurveyVersion({
        surveyId: store.surveyId,
        versionId: currentVersion.value,
      });
      store.builder.versionHistory = status ? survey?.versionHistory : [];
    }
    await sleep(6000);
    await store.getFormOrSurvey();
    changeCurrentVersion(store.builder.versionHistory?.[0]);
    isSaving.value = false;
  } catch (err) {
    isSaving.value = false;
    sendNotification({
      title: t("common.errorMessage"),
      description: err.message,
      type: "error",
    });
  }
};
</script>
<style scoped>
.header-toolbar {
  background-color: #ffffff !important;
  border-bottom: 0.5px solid #e2e2e2 !important;
}
#hl-builder-header .hl-toolbar-group {
  padding: 5px 0px;
}

.form-save-btn {
  width: 100%;
}

#hl-builder-header {
  position: fixed;
  z-index: 21;
  width: 100%;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.cards-container {
  height: calc(100% - 50px);
}
.card-list {
  height: calc(100vh - 50px);
}

.version-preview {
  width: 100%;
  height: 100%;
}
.version-preview iframe {
  width: 100%;
  height: 100vh;
  border: none;
}
</style>
