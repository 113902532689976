<template>
  <ColorInput
    :label="t('common.background')"
    :color="store?.formStyle?.background"
    @update:color="handleFormBackground"
  />
  <div class="w-100 grid grid-cols-2 gap-4">
    <ColorInput
      :label="t('common.fontColor')"
      :color="store?.fieldStyle?.fontColor"
      @update:color="handleFieldFontColor"
    />
    <ColorInput
      :label="t('common.style.inputBackground')"
      :color="store?.fieldStyle?.bgColor"
      @update:color="handleFieldBackground"
    />
  </div>
  <ImageUpload :header="t('common.backgroundImage')" field="bgImage" />
  <ImageUpload :header="t('common.headerImage')" field="headerImageSrc" />
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { useI18n } from "vue-i18n";
import ColorInput from "../Elements/ColorInput.vue";
import ImageUpload from "../Elements/ImageUpload.vue";

const { t } = useI18n();
const store = useAppStore();

defineProps({
  isSurvey: {
    type: Boolean,
    default: true,
  },
});

const handleFormBackground = (val: string) => {
  store.formStyle.background = val;
  store.anyUnsavedChanges = true;
};

const handleFieldFontColor = (val: string) => {
  store.fieldStyle.fontColor = val;
  store.anyUnsavedChanges = true;
};

const handleFieldBackground = (val: string) => {
  store.fieldStyle.bgColor = val;
  store.anyUnsavedChanges = true;
};
</script>
