<template>
  <Switch
    v-if="!store?.version || store?.version < 2"
    class="switch-ghl"
    :label="t('survey.footer.enableNewFooter')"
    :value="store?.builderConfig?.newFooter"
    @update:value="handleNewFooter"
  />
  <section
    v-if="store?.builderConfig?.newFooter"
    class="flex flex-col items-center justify-center gap-[20px] self-stretch"
  >
    <div class="flex flex-col gap-2">
      <UITextXsMedium>{{ t("survey.footer.footerType") }}</UITextXsMedium>
      <div class="flex items-start gap-4 self-stretch">
        <div class="footer-card-saperator" @click="handleFooterStyle('stickyFooter', true)">
          <div class="footer-card" :class="[store?.footerStyle?.stickyFooter ? 'active' : '']">
            <StickToCard></StickToCard>
          </div>
          <div>{{ t("survey.footer.stickToCard") }}</div>
        </div>
        <div class="footer-card-saperator" @click="handleFooterStyle('stickyFooter', false)">
          <div class="footer-card" :class="[!store?.footerStyle?.stickyFooter ? 'active' : '']">
            <StickToPage></StickToPage>
          </div>
          <div>{{ t("survey.footer.stickToPage") }}</div>
        </div>
      </div>
    </div>
    <ColorInput
      :label="t('survey.footer.backgroundFill')"
      :color="store?.footerStyle?.backgroundFill"
      @update:color="handleFooterStyle('backgroundFill', $event)"
    />
    <div class="grid grid-cols-2 gap-4">
      <PixelNumber
        :label="t('survey.footer.footerHeight')"
        :value="parseFloat(store?.footerStyle?.height)"
        :min="50"
        :max="200"
        @update:value="handleFooterStyle('height', $event)"
      />
      <FontFamilyInput
        :label="t('common.fontFamily')"
        :value="store?.footerStyle?.fontFamily"
        @update:value="handleFooterStyle('fontFamily', $event)"
      ></FontFamilyInput>
      <PixelNumber
        :label="t('common.size')"
        :value="parseFloat(store?.footerStyle?.fontSize)"
        @update:value="handleFooterStyle('fontSize', $event)"
      />
      <div class="flex flex-col items-start gap-2 self-stretch">
        <UITextXsMedium>{{ t("common.weight") }}</UITextXsMedium>
        <UISelect
          id="font-weight"
          :value="store?.footerStyle?.fontWeight"
          :options="fontWeights"
          placeholder="Select one"
          @change="
            handleFooterStyle('fontWeight', $event);
            store.addToHistory();
          "
        />
      </div>
    </div>
    <section
      class="message-styling flex flex-col items-start justify-center gap-4 self-stretch p-3"
    >
      <Switch
        :label="t('survey.footer.enableProgressBar')"
        :value="store?.footerStyle?.enableProgressBar"
        :disabled="store?.footerStyle?.stickyFooter"
        @update:value="handleFooterStyle('enableProgressBar', $event)"
      />
      <div
        v-if="store?.footerStyle?.enableProgressBar"
        class="flex flex-col justify-items-start gap-4 self-stretch"
      >
        <div class="flex items-center justify-between py-2 text-gray-700">
          {{ t("common.themes.themes") }}
        </div>

        <ThemeInput :theme-selected="store?.footerStyle?.theme" @update-theme="handleTheme" />
        <div class="grid grid-cols-2 gap-3">
          <ColorInput
            :label="t('survey.footer.textColor')"
            :compact="true"
            :color="store?.footerStyle?.progressBarStyle?.textColor"
            @update:color="handleProgressBarStyle('textColor', $event)"
          />
          <ColorInput
            :label="t('survey.footer.fillComplete')"
            :compact="true"
            :color="store?.footerStyle?.progressBarStyle?.completeFillColor"
            @update:color="handleProgressBarStyle('completeFillColor', $event)"
          />
          <ColorInput
            :label="t('survey.footer.fillInactive')"
            :compact="true"
            :color="store?.footerStyle?.progressBarStyle?.inactiveFillColor"
            @update:color="handleProgressBarStyle('inactiveFillColor', $event)"
          />
          <ColorInput
            :label="t('common.style.borderColor')"
            :compact="true"
            :color="store?.footerStyle?.progressBarStyle?.borderColor"
            @update:color="handleProgressBarStyle('borderColor', $event)"
          />
          <PixelNumber
            :label="t('common.style.borderWidth')"
            :value="parseFloat(store?.footerStyle?.progressBarStyle?.borderWidth)"
            @update:value="handleProgressBarStyle('borderWidth', $event)"
          />
          <PixelNumber
            :label="t('common.cornerRadius')"
            :value="parseFloat(store?.footerStyle?.progressBarStyle?.borderRadius)"
            @update:value="handleProgressBarStyle('borderRadius', $event)"
          />
        </div>
      </div>
    </section>
    <section
      class="message-styling flex flex-col items-start justify-center gap-4 self-stretch p-3"
    >
      <UITextSmMedium>
        {{ t("survey.footer.buttons") }}
      </UITextSmMedium>
      <div class="w-100 grid grid-cols-2 gap-3">
        <div class="flex flex-col items-start gap-2 self-stretch">
          <UITextXsMedium class="flex"
            >{{ t("survey.footer.buttonType") }}
            <UITooltip :style="{ maxWidth: '300px' }" trigger="hover" placement="top">
              <template #trigger>
                <InfoCircleIcon class="ml-1 h-4 w-4 text-gray-400"></InfoCircleIcon>
              </template>
              {{ t("common.fontFamilyTooltip") }}
            </UITooltip></UITextXsMedium
          >
          <UISelect
            id="button-type"
            :value="store?.footerStyle?.buttonStyle?.buttonType"
            :options="buttonTypes"
            placeholder="Select one"
            @change="
              handleButtonStyle('buttonType', $event);
              store.addToHistory();
            "
          />
        </div>
        <div class="flex flex-col items-start gap-2">
          <UITextXsMedium class="flex"
            >{{ t("survey.footer.position") }}
            <UITooltip :style="{ maxWidth: '300px' }" trigger="hover" placement="top">
              <template #trigger>
                <InfoCircleIcon class="ml-1 h-4 w-4 text-gray-400"></InfoCircleIcon>
              </template>
              {{ t("common.fontFamilyTooltip") }}
            </UITooltip></UITextXsMedium
          >
          <UISelect
            id="position"
            :value="store?.footerStyle?.buttonStyle?.position"
            :options="positions"
            placeholder="Select one"
            @change="
              handleButtonStyle('position', $event);
              store.addToHistory();
            "
          />
        </div>
      </div>
      <ColorInput
        :label="t('survey.footer.textColor')"
        :color="store?.footerStyle?.buttonStyle?.fontColor"
        @update:color="handleButtonStyle('fontColor', $event)"
      />
      <UIForm id="button-form" ref="root" :model="buttonFormValue" :rules="rules">
        <div class="grid grid-cols-2">
          <UIFormItem path="prevBtnText">
            <div class="flex flex-col items-start gap-2 self-stretch">
              <UITextXsMedium
                >{{ t("survey.footer.previousText")
                }}<span class="ml-1 text-red-600">*</span></UITextXsMedium
              >
              <UIInput
                id="prevBtnText"
                v-model="buttonFormValue.prevBtnText"
                class="right-zero"
                placeholder="PREV"
                :disabled="store?.footerStyle?.buttonStyle?.buttonType === 'onlyArrow'"
                @blur="store.addToHistory()"
              />
            </div>
          </UIFormItem>
          <ColorInput
            :label="t('survey.footer.fillPrevious')"
            :color="store?.footerStyle?.buttonStyle?.backButtonBgColor"
            :flat-radius="true"
            :compact="true"
            @update:color="handleButtonStyle('backButtonBgColor', $event)"
          />
        </div>
        <div class="grid grid-cols-2">
          <UIFormItem path="nextBtnText">
            <div class="flex flex-col items-start gap-2 self-stretch">
              <UITextXsMedium
                >{{ t("survey.footer.nextText")
                }}<span class="ml-1 text-red-600">*</span></UITextXsMedium
              >
              <UIInput
                id="nextBtnText"
                v-model="buttonFormValue.nextBtnText"
                class="right-zero"
                placeholder="NEXT"
                :disabled="store?.footerStyle?.buttonStyle?.buttonType === 'onlyArrow'"
                @blur="store.addToHistory()"
              />
            </div>
          </UIFormItem>
          <ColorInput
            :label="t('survey.footer.fillNext')"
            :color="store?.footerStyle?.buttonStyle?.nextButtonBgColor"
            :flat-radius="true"
            :compact="true"
            @update:color="handleButtonStyle('nextButtonBgColor', $event)"
          />
        </div>
        <div class="grid grid-cols-2">
          <UIFormItem path="submitBtnText">
            <div class="flex flex-col items-start gap-2 self-stretch">
              <UITextXsMedium
                >{{ t("survey.footer.submitText")
                }}<span class="ml-1 text-red-600">*</span></UITextXsMedium
              >
              <UIInput
                id="submitBtnText"
                v-model="buttonFormValue.submitBtnText"
                class="right-zero"
                placeholder="SUBMIT"
                @blur="store.addToHistory()"
              />
            </div>
          </UIFormItem>
          <ColorInput
            :label="t('survey.footer.fillSubmit')"
            :color="store?.footerStyle?.buttonStyle?.submitButtonBgColor"
            :flat-radius="true"
            :compact="true"
            @update:color="handleButtonStyle('submitButtonBgColor', $event)"
          />
        </div>
      </UIForm>
    </section>
  </section>
</template>

<script setup lang="ts">
import StickToCard from "@/assets/icons/stick-to-card.svg";
import StickToPage from "@/assets/icons/stick-to-page.svg";
import { useAppStore } from "@/store/app";
import { PERCENTAGE_BAR_DEFAULT_TEXTCOLOR, STEPS_DEFAULT_TEXTCOLOR } from "@/util/constants";
import { InfoCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import {
  UIForm,
  UIFormItem,
  UIInput,
  UISelect,
  UITextSmMedium,
  UITextXsMedium,
  UITooltip,
} from "@gohighlevel/ghl-ui";
import { Ref, computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import ColorInput from "../Elements/ColorInput.vue";
import FontFamilyInput from "../Elements/FontFamilyInput.vue";
import PixelNumber from "../Elements/PixelNumber.vue";
import Switch from "../Elements/Switch.vue";
import ThemeInput from "../ThemeInput.vue";

const { t } = useI18n();
const store = useAppStore();

const fontWeights = Array.from({ length: 9 }, (_, index) => {
  const weight = (index + 1) * 100;
  return {
    label: weight.toString(),
    value: weight.toString(),
  };
});

const buttonTypes = computed(() => [
  {
    label: "Text & Arrow",
    value: "textAndArrow",
  },
  {
    label: "Only Text",
    value: "onlyText",
  },
  {
    label: "Only Arrow",
    value: "onlyArrow",
    disabled: !store.enableSaveButton,
  },
]);

const positions = [
  {
    label: "Left",
    value: "left",
  },
  {
    label: "Left & Right",
    value: "leftAndRight",
  },
  {
    label: "Right",
    value: "right",
  },
];

const handleNewFooter = (val: boolean) => {
  store.builderConfig.newFooter = val;
  store.anyUnsavedChanges = true;
};

const handleFooterStyle = (property, value) => {
  if (property === "stickyFooter") {
    store.footerStyle.stickyFooter = value;
    store.footerStyle.enableProgressBar = !value;
  } else if (property === "height") {
    store.footerStyle.height = value || 50;
  } else if (property === "fontFamily") {
    if (!store.fontCollection.includes(value)) {
      store.fontCollection.push(value);
    }
    store.footerStyle.fontFamily = value;
  } else {
    store.footerStyle[property] = value;
  }
  store.anyUnsavedChanges = true;
};

const handleTheme = (val: string) => {
  store.footerStyle.theme = val;
  const progressBarStyle = store.footerStyle?.progressBarStyle;
  if (progressBarStyle) {
    if (
      val === "percentageBar" &&
      progressBarStyle.textColor === PERCENTAGE_BAR_DEFAULT_TEXTCOLOR
    ) {
      progressBarStyle.textColor = STEPS_DEFAULT_TEXTCOLOR;
    } else if (val === "steps" && progressBarStyle.textColor === STEPS_DEFAULT_TEXTCOLOR) {
      progressBarStyle.textColor = PERCENTAGE_BAR_DEFAULT_TEXTCOLOR;
    }
  }
  store.anyUnsavedChanges = true;
};

const handleProgressBarStyle = (property, value) => {
  store.footerStyle.progressBarStyle[property] = value;
  store.anyUnsavedChanges = true;
};

const handleButtonStyle = (property, value) => {
  store.footerStyle.buttonStyle[property] = value;
  store.anyUnsavedChanges = true;
};

const prevBtnTextModel = computed({
  get() {
    return store.footerStyle.buttonStyle.prevBtnText;
  },
  set(value: string) {
    store.footerStyle.buttonStyle.prevBtnText = value;
    store.anyUnsavedChanges = true;
  },
});

const nextBtnTextModel = computed({
  get() {
    return store.footerStyle.buttonStyle.nextBtnText;
  },
  set(value: string) {
    store.footerStyle.buttonStyle.nextBtnText = value;
    store.anyUnsavedChanges = true;
  },
});

const submitBtnTextModel = computed({
  get() {
    return store.footerStyle.buttonStyle.submitBtnText;
  },
  set(value: string) {
    store.footerStyle.buttonStyle.submitBtnText = value;
    store.anyUnsavedChanges = true;
  },
});

const buttonFormValue = ref({
  prevBtnText: prevBtnTextModel.value,
  nextBtnText: nextBtnTextModel.value,
  submitBtnText: submitBtnTextModel.value,
});

const root = ref();

const validateRequiredText = (value: string, model: Ref<string>) => {
  if (!(value && value.trim() !== "")) {
    return new Error("Field is required");
  }
  if (value.trim().length > 16) {
    return new Error("Maximum length exceeded (16 characters)");
  }
  model.value = value.trim();
  return true;
};

const rules = {
  prevBtnText: [
    {
      required: true,
      validator: (rule: any, value: string) => validateRequiredText(value, prevBtnTextModel),
    },
  ],
  nextBtnText: [
    {
      required: true,
      validator: (rule: any, value: string) => validateRequiredText(value, nextBtnTextModel),
    },
  ],
  submitBtnText: [
    {
      required: true,
      validator: (rule: any, value: string) => validateRequiredText(value, submitBtnTextModel),
    },
  ],
};

watch(
  buttonFormValue,
  async () => {
    try {
      await root.value.getForm().validate();
      store.enableSaveButton = true;
    } catch (error) {
      store.enableSaveButton = false;
    }
  },
  { deep: true }
);

watch(store?.footerStyle?.buttonStyle, (val) => {
  buttonFormValue.value.prevBtnText = val.prevBtnText;
  buttonFormValue.value.nextBtnText = val.nextBtnText;
  buttonFormValue.value.submitBtnText = val.submitBtnText;
});
</script>

<style scoped>
.footer-card-saperator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  flex: 1 0 0;
}

.footer-card {
  display: flex;
  height: 100px;
  width: 140px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid #eaecf0;
  background: #fff;
}

.footer-card-active {
  border: 2px solid #155eef;
}

.right-zero {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.n-form-item.n-form-item--top-labelled {
  grid-template-rows: auto;
}

.form-item {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;
}

.active {
  border: 2px solid #155eef !important;
  background: #eff4ff;
}
</style>
