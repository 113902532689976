<script setup lang="ts">
import { ref, watch } from "vue";
import { UIInputNumber, UIColorPicker } from "@gohighlevel/ghl-ui";
import { useAppStore } from "@/store/app";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps({
  border: {
    type: Number,
    default: 0,
  },
  borderColor: {
    type: String,
    default: "FFFFFF",
  },
  max: {
    type: Number,
    default: 100,
  },
});
const borderColor = ref("#" + props.borderColor);
const emit = defineEmits(["update:border", "update:color"]);
const handleBorder = (val: string) => emit("update:border", parseFloat(val) || 0);
const store = useAppStore();

const handleSetPickerColor = (value) => {
  const color = value.replace("#", "");
  const isValidColor: boolean = /(^[0-9A-F]{8}$)|(^[0-9A-F]{6}$)|(^[0-9A-F]{3}$)/i.test(color);
  if (!isValidColor) {
    return;
  }
  borderColor.value = value;
  emit("update:color", color);
};

watch(
  () => props.borderColor,
  (newColor) => (borderColor.value = `#${newColor}`)
);
</script>

<template>
  <div class="grid grid-cols-2">
    <label class="my-auto text-sm font-normal text-gray-700">{{ t("common.border") }}</label>
    <div id="input-color-wrapper" class="input-color-picker">
      <UIInputNumber
        id="border-input"
        :show-button="false"
        :value="border"
        :min="0"
        :max="max"
        class="input-field"
        @change="handleBorder"
        @blur="store.addToHistory()"
      >
        <template #suffix>
          <span class="px">px</span>
        </template>
      </UIInputNumber>
      <div class="border-input-color">
        <UIColorPicker
          :value="borderColor"
          :compact="true"
          @on-complete="store.addToHistory"
          @update:value="handleSetPickerColor"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.input-field {
  font-size: 12px;
}
.border-picker {
  position: relative;
}
.px {
  position: relative;
  left: -40px̦;
}
.input-color-picker {
  display: flex;
  width: 88%;
}
#input-color-wrapper,
#border-input {
  width: 88%;
}
.border-input-color {
  padding-left: 15px;
}
</style>
