<template>
  <UIModal id="alert-modal" v-model:show="showModal" :z-index="5000">
    <template #header>
      <UIModalHeader
        id="modal-header"
        :title="title"
        :description="description"
        :icon="AlertCircleIcon"
        :type="headerType"
        @close="cancelCallback"
      >
      </UIModalHeader>
    </template>
    <!--  content goes here  -->
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        :positive-text="positiveText"
        :negative-text="negativeText"
        :type="footerType"
        @positive-click="submitCallback"
        @negative-click="cancelCallback"
      >
        <template v-if="isCheckboxEnabled" #actionLeft>
          <UICheckbox
            id="modal-form-checkout"
            :checked="hideModal"
            @update:checked="hideModalNextTime"
          >
            {{ t("common.doNotShowMessage") }}
          </UICheckbox>
        </template>
      </UIModalFooter>
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import { AlertCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import { useAppStore } from "@/store/app";
import { UICheckbox, UIModal, UIModalFooter, UIModalHeader } from "@gohighlevel/ghl-ui";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps({
  title: {
    type: String,
    default: "Title",
  },
  description: {
    type: String,
    default: "Description",
  },
  positiveText: {
    type: String,
    default: "Yes",
  },
  negativeText: {
    type: String,
    default: "Cancel",
  },
  headerType: {
    type: String,
    default: "warning",
  },
  footerType: {
    type: String,
    default: "primary",
  },
  modalName: String,
  showModalPopup: {
    type: Boolean,
    default: false,
  },
  isCheckboxEnabled: {
    type: Boolean,
    default: false,
  },
  hideModal: {
    type: Boolean,
    default: false,
  },
});
const store = useAppStore();
const emits = defineEmits(["pop:positive", "pop:negative", "pop:change", "pop:check"]);

const showModal = ref(props.showModalPopup || false);

watch(
  () => showModal.value,
  (result) => {
    emits("pop:change", props.modalName, result);
  }
);
const cancelCallback = () => {
  showModal.value = false;
  emits("pop:negative", props.modalName, false);
};
const submitCallback = () => {
  showModal.value = false;
  emits("pop:positive", props.modalName, true);
  store.addToHistory();
};
const hideModalNextTime = (isChecked: boolean) => {
  emits("pop:check", isChecked);
};
</script>
