<template>
  <h6 class="field-name-sidebar">
    {{ dateElement?.standard !== false ? dateElement?.label : dateElement?.name }}
  </h6>
  <div class="form-group">
    <div class="flex space-x-3">
      <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
        t("common.label")
      }}</span>
    </div>
    <UIInput
      id="label"
      class="standard-input-ghl"
      :model-value="dateElement.label"
      @update:model-value="updateLabel"
      @blur="store.addToHistory()"
    ></UIInput>
  </div>
  <div class="form-group">
    <div class="flex space-x-3">
      <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
        t("common.placeholder")
      }}</span>
    </div>
    <UIInput
      id="fieldPlaceholder"
      class="standard-input-ghl"
      :model-value="dateElement.placeholder"
      @update:model-value="updatePlaceholder"
      @blur="store.addToHistory()"
    ></UIInput>
  </div>
  <div class="form-group">
    <div class="flex space-x-3">
      <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
        t("common.shortLabel")
      }}</span>
    </div>
    <UIInput
      id="shortLabel"
      class="standard-input-ghl"
      :model-value="dateElement.shortLabel"
      @update:model-value="updateShortLabel"
      @blur="store.addToHistory()"
    ></UIInput>
  </div>
  <div class="form-group">
    <div class="flex space-x-3">
      <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
        {{ t("common.queryKey") }}
        <UITooltip width="300px" trigger="hover" placement="top">
          <template #trigger>
            <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
          </template>
          {{ t("common.querykeyTooltipMessage") }}
        </UITooltip>
      </div>
    </div>
    <UIInput
      id="fieldQueryKey"
      class="standard-input-ghl"
      :model-value="dateElement.hiddenFieldQueryKey"
      @update:model-value="updateHiddenQuery"
      @blur="store.addToHistory()"
    ></UIInput>
  </div>
  <div v-if="isForm" class="form-group">
    <div class="flex justify-between pb-2">
      <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
        {{ t("common.style.fieldWidth") }}
      </div>
    </div>
    <UIInputGroup>
      <UIInputNumber
        id="fieldWidthKey"
        size="large"
        class="w-100"
        :min="1"
        :max="100"
        :show-button="false"
        :model-value="dateElement.fieldWidthPercentage"
        @update:model-value="updateFieldWidthPercentage"
        @blur="getFieldWidthPercentage()"
      ></UIInputNumber
      ><UIInputGroupLabel>%</UIInputGroupLabel>
    </UIInputGroup>
  </div>
  <div class="form-check-req-hid">
    <UICheckbox
      id="fieldRequired"
      :disabled="isRequiredFieldDisable"
      :checked="dateElement.required"
      @update:checked="updateRequired"
      >{{ t("common.required") }}</UICheckbox
    >
    <UICheckbox
      id="fieldCheckbox"
      :disabled="isHiddenFieldDisable"
      :checked="dateElement.hidden"
      @update:checked="updateHidden"
      >{{ t("common.hidden") }}</UICheckbox
    >
  </div>
  <div v-if="dateElement?.hidden" class="form-group hidden-field">
    <div class="flex space-x-3">
      <span class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700">{{
        t("common.hiddenValue")
      }}</span>
    </div>
    <UIInput
      id="fieldHiddenvalue"
      class="standard-input-ghl"
      :model-value="dateElement.hiddenFieldValue"
      @update:model-value="updateHiddenValue"
      @blur="store.addToHistory()"
    ></UIInput>
  </div>
  <div class="mt-8">
    <div class="sub-header flex flex-col self-stretch border-gray-300 p-[0px_16px_12px_16px]">
      {{ t("common.date.dateSettings") }}
    </div>
    <div id="date-format" class="form-group">
      <div class="flex space-x-3">
        <span class="switch-handle mb-2 block text-sm font-medium text-gray-700">
          {{ t("common.date.dateFormat") }}
          <UITooltip width="300px" trigger="hover" placement="top">
            <template #trigger>
              <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
            </template>
            {{ t("common.date.dateFormatTooltipMessage") }}
          </UITooltip>
        </span>
      </div>
      <UIRadioGroup :value="selectedFormat" name="dateFormat" @update:value="updateDateFormat">
        <UISpace size="small" horizontal>
          <UIRadioGroupItem
            v-for="format in dateFormat"
            :key="format"
            :value="format"
            class="w-[120px] text-xs font-medium"
            :disabled="false"
            :compact="true"
          >
            <template #content>
              <div>
                {{ format }}
              </div>
            </template>
          </UIRadioGroupItem>
        </UISpace>
      </UIRadioGroup>
    </div>
    <div id="date-separator" class="form-group">
      <div class="flex space-x-3">
        <span class="switch-handle mb-2 block text-sm font-medium text-gray-700">
          {{ t("common.date.dateSeparator") }}
          <UITooltip width="300px" trigger="hover" placement="top">
            <template #trigger>
              <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
            </template>
            {{ t("common.date.dateSeparatorTooltipMessage") }}
          </UITooltip>
        </span>
      </div>
      <UIRadioGroup
        :value="selectedSeparator"
        name="dateSeparator"
        @update:value="updateDateSeparator"
      >
        <UISpace size="small" horizontal>
          <UIRadioGroupItem
            v-for="separator in separators"
            :key="separator"
            :value="separator"
            class="text-xs font-medium"
            :disabled="false"
            :compact="true"
          >
            <template #content>
              <div>
                {{ separator }}
              </div>
            </template>
          </UIRadioGroupItem>
        </UISpace>
      </UIRadioGroup>
    </div>

    <div class="form-check-req-hid">
      <UICheckbox
        id="picker-settings"
        :checked="dateElement.disablePicker"
        @update:checked="updateDateDisablePicker"
        >{{ t("common.date.disablePicker") }}</UICheckbox
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/store/app";
import {
  UIButton,
  UICheckbox,
  UIInput,
  UITooltip,
  UIRadioGroup,
  UISpace,
  UIRadioGroupItem,
  UIInputGroup,
  UIInputGroupLabel,
  UIInputNumber,
} from "@gohighlevel/ghl-ui";
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  ArrowLeftIcon,
  InfoCircleIcon,
} from "@gohighlevel/ghl-icons/24/outline";
import { ref, watch } from "vue";
import { dateFormat, separators } from "@/util/constants";

const { t } = useI18n();
const store = useAppStore();

const props = defineProps({
  dateElement: {
    type: Object,
    default: () => {
      return {};
    },
  },
  isRequiredFieldDisable: {
    type: Boolean,
    default: false,
  },
  isHiddenFieldDisable: {
    type: Boolean,
    default: false,
  },
  getFieldWidthPercentage: {
    type: Function,
    required: true,
  },
  isForm: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "updateLabel",
  "updatePlaceholder",
  "updateShortLabel",
  "updateHiddenQuery",
  "updateFieldWidthPercentage",
  "updateRequired",
  "updateHidden",
  "updateHiddenValue",
  "updateDateFormat",
  "updateDateSeparator",
  "updateDateDisablePicker",
]);

const selectedFormat = ref(props.dateElement.format || "YYYY-MM-DD");
const selectedSeparator = ref(props.dateElement.separator || "-");

const updateLabel = (value: string) => {
  emit("updateLabel", value);
};
const updatePlaceholder = (value: string) => {
  emit("updatePlaceholder", value);
};
const updateHiddenQuery = (value: string) => {
  emit("updateHiddenQuery", value);
};
const updateFieldWidthPercentage = (value: string) => {
  emit("updateFieldWidthPercentage", value);
};
const updateShortLabel = (value: string) => {
  emit("updateShortLabel", value);
};
const updateRequired = (value: boolean) => {
  emit("updateRequired", value);
};
const updateHidden = (value: boolean) => {
  emit("updateHidden", value);
};
const updateHiddenValue = (value: string) => {
  emit("updateHiddenValue", value);
};

const updateDateFormat = (value: string) => {
  selectedFormat.value = value;
  store.addToHistory();
  emit("updateDateFormat", value);
};

const updateDateSeparator = (value: string) => {
  selectedSeparator.value = value;
  emit("updateDateSeparator", value);
};

const updateDateDisablePicker = (value: boolean) => {
  emit("updateDateDisablePicker", value);
};

watch(
  () => props.dateElement.format,
  (newValue, oldValue) => {
    selectedFormat.value = newValue || "YYYY-MM-DD";
  }
);

watch(
  () => props.dateElement.separator,
  (newValue, oldValue) => {
    selectedSeparator.value = newValue || "-";
  }
);
</script>

<style>
.selector-box {
  border: 1px solid rgb(224, 224, 230);
  padding: 5px 5px;
  border-radius: 5px;
  color: rgb(55 65 81);
  text-align: center;
  cursor: pointer;
  border-radius: 0;
}
.selector-box-active {
  background: #1d4ed8;
  color: white;
  border: 0;
}
.separator {
  width: 3rem;
  border-radius: 0;
}
#date-format
  .hl-radio-group-item.n-radio-button
  .hl-radio-group-item-container
  .hl-radio-group-item-content {
  padding-left: 0;
  padding-right: 0;
}
#date-separator
  .hl-radio-group-item.n-radio-button
  .hl-radio-group-item-container
  .hl-radio-group-item-content {
  padding-left: 2;
  padding-right: 2;
}
</style>
