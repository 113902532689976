<script setup lang="ts">
import Codemirror from "codemirror-editor-vue3";
// import "codemirror/mode/htmlmixed/htmlmixed.js";
// import "codemirror/mode/javascript/javascript.js";
// import "codemirror/mode/css/css.js";
// import "codemirror/addon/hint/html-hint.js";
// import "codemirror/addon/hint/show-hint.js";

import "codemirror/addon/display/placeholder.js";
import "codemirror/theme/dracula.css";
import { ref } from "vue";

const emit = defineEmits(["update:inputCode"]);
const props = defineProps({
  codeInput: {
    type: String,
    default: "",
  },
  height: {
    type: Number,
    default: 300,
  },
  placeholder: {
    type: String,
    default: "Add your code here",
  },
  editorOptions: {
    type: Object,
    default() {
      return {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "text/html",
        lineWrapping: true,
        theme: "dracula",
      };
    },
  },
});

const inputCode = ref(props.codeInput || "");

const inputEvent = (input: string) => {
  emit("update:inputCode", input);
};
</script>

<template>
  <Codemirror
    ref="myCm"
    v-model:value="inputCode"
    class="hl-custom-code-editor w-full rounded-md text-left"
    :placeholder="placeholder"
    :height="height"
    :options="props.editorOptions"
    @input="inputEvent"
  />
</template>

<style>
.hl-custom-code-editor .CodeMirror,
.CodeMirror-merge-pa {
  font-family: monospace !important;
}
.codemirror-container {
  font-size: 15px;
}
</style>
