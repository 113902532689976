<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UIInputNumber, UITextXsMedium } from "@gohighlevel/ghl-ui";
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  value: {
    type: Number || String,
    default: 0,
  },
  isLabelSmall: {
    type: Boolean,
    default: false,
  },
  max: {
    type: Number,
    default: 1800,
  },
  min: {
    type: Number,
    default: 0,
  },
  icon: {
    type: Function,
    required: false,
  },
});

const store = useAppStore();
const emit = defineEmits(["update:value"]);
const handleSetCount = (val: string) => emit("update:value", parseFloat(val) || 0);
</script>
<template>
  <div id="pixelNumber" class="flex flex-col items-start gap-2 self-stretch">
    <UITextXsMedium>{{ label }}</UITextXsMedium>
    <UIInputNumber
      id="text-input"
      class="w-100"
      :show-button="false"
      :min="min"
      :max="max"
      size="large"
      :value="value"
      @change="handleSetCount"
      @blur="store.addToHistory"
    >
      <template #prefix>
        <component :is="icon" class="h-4 w-4"></component>
      </template>
      <template #suffix>
        <div class="pixel-text flex h-4 w-4 items-center justify-center">PX</div>
      </template>
    </UIInputNumber>
  </div>
</template>

<style lang="css" scoped>
.pixel-text {
  color: #98a2b3;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
</style>
