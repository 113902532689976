<template>
  <UITextXsRegular>
    {{ t("survey.footerHTMLMessage") }}
  </UITextXsRegular>

  <UIButton id="footerButton" type="primary" class="w-[100%]" @click="onClickOfEditHTML">{{
    t("survey.editFooter")
  }}</UIButton>
  <CodeEditorModel
    v-if="showModal"
    :model-active="showModal"
    :code="code"
    @update:show-model="showModelUpdate"
    @update:submit="submitUpdate"
  />
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UIButton, UITextXsRegular } from "@gohighlevel/ghl-ui";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import CodeEditorModel from "../CodeEditorModel.vue";

const { t } = useI18n();
const store = useAppStore();

const showModal = ref(false);
const code = ref(store.formAction.footerHtml || "");

const showModelUpdate = (modalUpdate: boolean) => {
  showModal.value = modalUpdate;
};

const submitUpdate = (updatedCode: string) => {
  code.value = updatedCode;
  store.formAction.footerHtml = updatedCode;
  store.anyUnsavedChanges = true;
};

const onClickOfEditHTML = () => {
  showModal.value = !showModal.value;
  code.value = store.formAction.footerHtml;
};
</script>
