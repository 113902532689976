<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UICheckbox } from "@gohighlevel/ghl-ui";
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const store = useAppStore();
const props = defineProps({
  element: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const isChecked = computed(() => {
  return !!store.addressSettings?.children?.find((child) => child.tag === props.element.tag);
});

const updatedChecked = (value) => {
  if (value) {
    store.addressSettings?.children.push(props.element as any);
  } else {
    // remove element from array
    store.addressSettings.children = store.addressSettings?.children.filter(
      (child) => child.tag !== props.element.tag
    );
  }
};
</script>

<template>
  <UICheckbox
    :id="element.tag"
    :checked="isChecked"
    :disabled="isChecked && (store.addressSettings?.children?.length || 0) <= 1"
    @update:checked="updatedChecked"
    >{{ element.label }}</UICheckbox
  >
</template>
