<template>
  <div :class="[store.addedProductList.length > 0 ? 'card-container' : '']">
    <VueDraggableNext
      :model-value="store.addedProductList"
      class="flex flex-col items-start self-stretch"
      handle=".drag-handle"
      @change="onProductSort"
    >
      <div
        v-for="(product, index) in store.addedProductList"
        :key="index"
        class="flex flex-col items-start self-stretch"
      >
        <ProductCard
          :product="product"
          :product-index="index"
          @product:deleted="onProductDelete"
          @product:settings="onProductSettings"
        >
          <template #handle>
            <div class="drag-handle cursor-pointer">
              <Menu05Icon class="h-4 w-4" />
            </div>
          </template>
        </ProductCard>
        <div v-if="index !== store.addedProductList.length - 1" class="divider"></div>
      </div>
    </VueDraggableNext>
  </div>
</template>

<script setup lang="ts">
import { VueDraggableNext } from "vue-draggable-next";
import { Menu05Icon } from "@gohighlevel/ghl-icons/24/outline";
import { useAppStore } from "@/store/app";
import ProductCard from "./ProductCard.vue";

const store = useAppStore();

const emits = defineEmits(["product:settings"]);

const onProductDelete = (index: number) => {
  store.addedProductList.splice(index, 1);
  store.addToHistory();
  store.anyUnsavedChanges = true;
};

const onProductSort = (sorted) => {
  if (sorted.moved) {
    const oldProductMap = store.addedProductList[sorted.moved.oldIndex];
    store.addedProductList[sorted.moved.oldIndex] = store.addedProductList[sorted.moved.newIndex];
    store.addedProductList[sorted.moved.newIndex] = oldProductMap;

    store.addToHistory();
    store.anyUnsavedChanges = true;
  }
};
const onProductSettings = (index) => {
  emits("product:settings", index);
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 0.5px solid #d0d5dd;
}
.divider {
  width: 100%;
  border-bottom: 1px solid #d0d5dd;
}
</style>
