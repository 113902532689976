<template>
  <div class="flex flex-col items-start gap-2 self-stretch">
    <UITextXsMedium>
      {{ t("common.style.labelAlignment.labelAlignment") }}
    </UITextXsMedium>
    <div class="flex gap-2">
      <div
        v-for="labelType in LABEL_ALIGNMENT"
        :key="labelType.key"
        class="label-box"
        :class="[labelType.key === store.fieldStyle.labelAlignment ? 'active ' : '']"
        @click="handleLabelAlignment(labelType.key)"
      >
        <UITooltip trigger="hover" placement="top">
          <template #trigger>
            <component
              :is="labelType.icon"
              class="focus_outline_none h-5 w-5"
              :class="labelType.key === store.fieldStyle.labelAlignment ? 'active-svg ' : ''"
            ></component>
          </template>
          {{ labelType.name }}
        </UITooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { LABEL_ALIGNMENT } from "@/util/constants";
import { UITooltip, UITextXsMedium } from "@gohighlevel/ghl-ui";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useAppStore();
const handleLabelAlignment = (labelType: string) => {
  store.fieldStyle.labelAlignment = labelType;
  store.anyUnsavedChanges = true;
};
</script>

<style scoped>
.label-box {
  display: flex;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.active {
  border: 1px solid #155eef;
  background: #eff4ff;
}

.active-svg {
  fill: #155eef;
  stroke: #155eef;
}

.focus_outline_none:focus {
  outline: none;
}
</style>
